import { ReactComponent as CloseTag } from 'assets/images/Close_Button.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Modal, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { getAffiliationStatus } from 'redux/affiliation/affiliations.action';
import { affiliationSelector } from 'redux/affiliation/affiliations.selector';
import { getProfile } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';
import { setAffiliateModal } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

import { AffiliateAccepted } from './AffiliateAccepted';
import { AffiliateForm } from './AffiliateForm';
import { AffiliateInReview } from './AffiliateInReview';
import { AffiliateRejected } from './AffiliateRejected';
import { Agreements } from './Agreements';
import { ApplicationIntro } from './ApplicationIntro';
import { CredentialsUpload } from './CredentialsUpload';
import { WelcomeAffiliate } from './WelcomeAffiliate';

export const AffiliatesModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { affiliateModal } = useAppSelector(userSelector);
  const affiliationStatus =
    useAppSelector(affiliationSelector).affiliationStatus;
  const { credentialsStatus } = useAppSelector(affiliationSelector);
  const [activeStep, setActiveStep] = useState<number>(0);

  const handleCloseModal = () => {
    dispatch(setAffiliateModal(false));
    navigate(RoutesEnum.USER_HOME);
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getAffiliationStatus());
  }, [dispatch]);

  useEffect(() => {
    if (
      affiliationStatus.statusReview &&
      affiliationStatus.statusAffiliation &&
      !credentialsStatus
    ) {
      setActiveStep(3);
    } else if (
      affiliationStatus.statusReview &&
      !affiliationStatus.statusAffiliation
    ) {
      setActiveStep(4);
    } else if (
      affiliationStatus.statusReview &&
      affiliationStatus.statusAffiliation &&
      credentialsStatus &&
      credentialsStatus.AA &&
      credentialsStatus.NDA &&
      credentialsStatus.firstCredential &&
      credentialsStatus.secondCredential &&
      credentialsStatus.signature
    ) {
      setActiveStep(7);
    }
  }, [affiliationStatus, credentialsStatus]);

  return (
    <Modal
      open={affiliateModal}
      onClose={handleCloseModal}
      aria-labelledby="user-profile-modal-title"
      aria-describedby="user-profile-modal-description">
      <Box sx={isLargeScreen ? style : styleMobile}>
        <Box
          sx={{
            padding: '0',
            margin: '0',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'space-between'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'space-between'
            }}>
            <Typography
              fontFamily={'"Nunito"'}
              fontWeight={500}
              fontSize={'1.25rem'}
              lineHeight={'1.5rem'}
              letterSpacing={'0.15px'}
              sx={{
                padding: '1.25rem',
                paddingBottom: '0',
                color: Colors.secondary.dark
              }}>
              {activeStep === 0 && 'Affiliate application'}
              {activeStep === 1 && 'A bit more about you'}
              {activeStep === 2 && 'Congratulations!'}
              {activeStep === 3 && 'Congratulations!'}
              {activeStep === 4 && 'Thank you for your interest'}
              {activeStep === 5 && 'Confirming you'}
              {activeStep === 6 && 'Agreements'}
              {activeStep === 7 && 'Welcome to the iAffirm Affiliate Program!'}
            </Typography>
            <Button
              onClick={handleCloseModal}
              sx={{
                padding: '1.25rem',
                paddingBottom: '0',
                minWidth: 'auto'
              }}>
              <CloseTag />
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: isLargeScreen ? '100%' : '90%',
            height: isLargeScreen ? '100%' : '90%',
            minHeight: isLargeScreen ? '10rem' : undefined,
            overflow: 'auto',
            marginTop: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}>
          {activeStep === 0 && (
            <ApplicationIntro setActiveStep={setActiveStep} />
          )}
          {activeStep === 1 && <AffiliateForm setActiveStep={setActiveStep} />}
          {activeStep === 2 && (
            <AffiliateInReview setActiveStep={setActiveStep} />
          )}

          {activeStep === 3 && (
            <AffiliateAccepted setActiveStep={setActiveStep} />
          )}
          {activeStep === 4 && <AffiliateRejected />}
          {activeStep === 5 && (
            <CredentialsUpload setActiveStep={setActiveStep} />
          )}
          {activeStep === 6 && <Agreements setActiveStep={setActiveStep} />}
          {activeStep === 7 && <WelcomeAffiliate />}
        </Box>
      </Box>
    </Modal>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  background: Colors.white,
  p: 4,
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  maxHeight: '90vh',
  borderRadius: '0.5rem',
  maxWidth: '50rem',
  minWidth: '35rem'
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: '50rem',
  background: Colors.white,
  boxShadow: 24,
  p: 4,
  borderRadius: '0.5rem',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100vw'
};
