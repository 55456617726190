import { ReactComponent as EditIcon } from 'assets/images/Edit.svg';
import { ReactComponent as Trash } from 'assets/images/Trash.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  addToWantWork,
  removeToWantWork,
  setActualStep,
  setHomeStatus
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { getQualities } from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';

import { isBannedWord } from 'utils/bannedWords';

import BoxSelect from '../BoxSelect';

interface WantWorkProps {
  onAdditionalQualitiesChange: (qualities: string[]) => void;
}

const WantWork = ({ onAdditionalQualitiesChange }: WantWorkProps) => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const [additionalQualities, setAdditionalQualities] = useState<string[]>([]);
  const [editingQualityIndex, setEditingQualityIndex] = useState<number | null>(
    null
  );
  const [confirmedQualities, setConfirmedQualities] = useState<{
    [index: number]: boolean;
  }>({});

  const [temporaryQualities, setTemporaryQualities] = useState<{
    [index: number]: string;
  }>({});
  const [qualityErrors, setQualityErrors] = useState<{
    [index: number]: string;
  }>({});

  const { actualStep, wantWorkOn, home } = useAppSelector(
    newAffirmationUISelector
  );

  const { selectedOutcome, qualities, loading } =
    useAppSelector(affirmationSelector);

  const maxQualities = 3;
  const availableSlots = maxQualities - wantWorkOn.length;
  const handleUpdateQuality = (index: number, temporaryQuality: string) => {
    const errorMessage = isBannedWord(temporaryQuality);
    if (errorMessage) {
      setQualityErrors({
        ...qualityErrors,
        [index]: errorMessage
      });
    } else {
      setQualityErrors({
        ...qualityErrors,
        [index]: ''
      });
      setAdditionalQualities((current) =>
        current.map((quality, i) => (i === index ? temporaryQuality : quality))
      );
      setConfirmedQualities({
        ...confirmedQualities,
        [index]: true
      });
    }
  };

  const handleRemoveQuality = (index: number) => {
    setAdditionalQualities((current) => current.filter((_, i) => i !== index));
    setTemporaryQualities((current) => {
      const updated = { ...current };
      delete updated[index];
      return updated;
    });
    setQualityErrors((current) => {
      const updated = { ...current };
      delete updated[index];
      return updated;
    });
    setConfirmedQualities((current) => {
      const updated = { ...current };
      delete updated[index];
      return updated;
    });
  };

  const handleAddQuality = () => {
    if (additionalQualities.length < availableSlots) {
      setAdditionalQualities((prevQualities) => [...prevQualities, '']);
    }
  };

  const handleEditQuality = (index: number) => {
    setEditingQualityIndex(index);
    setTemporaryQualities({
      ...temporaryQualities,
      [index]: additionalQualities[index]
    });
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  const handleSaveEditedQuality = (index: number) => {
    handleUpdateQuality(index, temporaryQualities[index] ?? '');
    setEditingQualityIndex(null);
  };

  const renderAdditionalQualities = () => {
    const allQualitiesConfirmed =
      Object.keys(confirmedQualities).length === additionalQualities.length &&
      additionalQualities.every((_, index) =>
        confirmedQualities.hasOwnProperty(index)
      );
    return additionalQualities.map((quality, index) => (
      <Grid item xs={12} key={index} sx={{ marginTop: '1.25rem' }}>
        {confirmedQualities[index] && editingQualityIndex !== index ? (
          <Box
            sx={{
              backgroundColor: Colors.secondary.light,
              marginTop: '0.25rem',
              border: `1px solid ${Colors.secondary.main}`,
              padding: '8px',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '5rem'
            }}>
            <Box sx={{ width: '98%' }}>
              <Typography
                fontFamily='"Nunito"'
                fontSize={'1.25rem'}
                lineHeight={'1.5rem'}
                sx={{ color: Colors.secondary.main, padding: '1rem' }}>
                {quality}
              </Typography>
            </Box>
            <IconButton
              color="primary"
              onClick={() =>
                allQualitiesConfirmed ? handleEditQuality(index) : null
              }
              disabled={!allQualitiesConfirmed}>
              <EditIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleRemoveQuality(index)}>
              <Trash />
            </IconButton>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: `1px solid ${Colors.lightGrey}`,
              padding: '8px',
              width: '98%'
            }}>
            <TextField
              fullWidth
              label="Type a quality"
              variant="standard"
              value={temporaryQualities[index] ?? quality}
              onChange={(e) => {
                const newValue = e.target.value.slice(0, 20);
                setTemporaryQualities({
                  ...temporaryQualities,
                  [index]: newValue
                });
              }}
              size="small"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  maxLength: 20
                }
              }}
              sx={{
                fontFamily: '"Nunito"',
                '& label.Mui-focused': {
                  color: `${Colors.secondary.main}`
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: `${Colors.secondary.main}`
                },
                height: 'min-content',
                color: Colors.lightGrey
              }}
              error={!!qualityErrors[index]}
              helperText={qualityErrors[index]}
            />

            <IconButton
              color="secondary"
              onClick={() => handleSaveEditedQuality(index)}
              disabled={!(temporaryQualities[index]?.trim().length >= 3)}>
              <CheckIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleRemoveQuality(index)}>
              <Trash />
            </IconButton>
          </Box>
        )}
      </Grid>
    ));
  };

  useEffect(() => {
    if (additionalQualities.length > availableSlots) {
      setAdditionalQualities(additionalQualities.slice(0, availableSlots));
    }
  }, [wantWorkOn, additionalQualities, availableSlots]);

  useEffect(() => {
    onAdditionalQualitiesChange(additionalQualities);
  }, [additionalQualities, onAdditionalQualitiesChange]);

  useEffect(() => {
    if (selectedOutcome) dispatch(getQualities(selectedOutcome.id));
  }, [dispatch, selectedOutcome]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.5rem'}
            color={Colors.black}>
            I want to work on these qualities.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select at least 1 and up to 3 qualities from the list, including
            custom ones.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {loading
          ? Array(4)
              .fill('')
              .map((_, i) => (
                <Grid item xs={6} md={3} key={i}>
                  <Skeleton height="8.75rem" />
                </Grid>
              ))
          : qualities &&
            qualities.map((quality, i) => (
              <Grid item xs={6} md={4} key={i}>
                <BoxSelect
                  onClick={() => {
                    wantWorkOn.map((ww) => ww.id).includes(quality.id)
                      ? dispatch(removeToWantWork(quality))
                      : dispatch(addToWantWork(quality));

                    !wantWorkOn.map((ww) => ww.id).includes(quality.id) &&
                      handleScrollDown();
                  }}
                  isSelected={wantWorkOn
                    .map((ww) => ww.id)
                    .includes(quality.id)}>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontFamily: '"Nunito"',
                      fontSize: isLargeScreen ? '1.15rem' : '1rem'
                    }}>
                    {quality.quality}
                  </Typography>
                </BoxSelect>
              </Grid>
            ))}
      </Grid>
      {renderAdditionalQualities()}

      {additionalQualities.length < 3 && (
        <Grid item>
          <Button sx={{ marginTop: '0.5rem' }} onClick={handleAddQuality}>
            <Typography
              fontFamily='"Nunito"'
              fontSize={'1.25rem'}
              fontWeight={500}
              sx={{ color: Colors.bgGreen }}>
              + Add other
            </Typography>
          </Button>
        </Grid>
      )}

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              if (home) dispatch(setHomeStatus(false));
              dispatch(setActualStep(actualStep - 1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            disabled={wantWorkOn.length === 0}
            onClick={() => {
              dispatch(setActualStep(actualStep + 1));
            }}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default WantWork;
