import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { CreateVisualization } from 'redux/visualization/types/visualizations';
import { createVisualization } from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';

import RoutesEnum from 'types/routes.enum';

import useTabsContext from '../hooks/useTabsContext';
import { NewVisualization } from '../types/new-visualization.types';
import { newVisualizationFormValidation as validationSchema } from '../validations/newAffirmationForm.validation';
import TabsContext from './TabsContext';
import ContextStep from './steps/CustomSteps/ContextStep';
import FeelStep from './steps/CustomSteps/FeelStep';
import SituationStep from './steps/CustomSteps/SituationStep';

export enum NewVisualizationTabs {
  CONTEXT,
  FEEL,
  SITUATION
}

const NewVisualizationForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hasNavigated = useRef(false);
  const { createdVisualization } = useAppSelector(visualizationSelector);
  const { setTabs, currentTab } = useTabsContext(TabsContext);

  useEffect(() => {
    setTabs([
      { id: NewVisualizationTabs.CONTEXT, status: true },
      { id: NewVisualizationTabs.FEEL, status: false },
      { id: NewVisualizationTabs.SITUATION, status: false }
    ]);
  }, [setTabs]);

  const form = useFormik<NewVisualization>({
    initialValues: {
      quality: '',
      emotion: '',
      situation: ''
    },
    onSubmit: async (values) => {
      const transformedValues: CreateVisualization = {
        quality: {
          quality: values.quality
        },
        emotion: {
          emotion: values.emotion
        },
        situation: {
          situation: values.situation
        }
      };
      await dispatch(createVisualization(transformedValues));
    },
    validationSchema
  });

  useEffect(() => {
    if (createdVisualization && !hasNavigated.current) {
      hasNavigated.current = true;
      navigate(`${RoutesEnum.VISUALIZATION_VIEW}${createdVisualization.id}`);
    }
  }, [createdVisualization, navigate]);

  return (
    <FormikProvider value={form}>
      <Box sx={{ marginTop: 5 }}>
        {currentTab?.id === NewVisualizationTabs.CONTEXT && <ContextStep />}
        {currentTab?.id === NewVisualizationTabs.FEEL && <FeelStep />}
        {currentTab?.id === NewVisualizationTabs.SITUATION && <SituationStep />}
      </Box>
    </FormikProvider>
  );
};

export default NewVisualizationForm;
