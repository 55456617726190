import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  AreaSelected,
  CustomQuality,
  SelectedTraits,
  newAffirmationUIState
} from 'modules/new-affirmation/types/initialState';

import { Quality } from 'redux/affirmation/types/quality.entity';
import { Trait } from 'redux/affirmation/types/trait.entity';

export const initialStateNewAffirmationUiState: newAffirmationUIState = {
  actualStep: 1,
  lastStep: 5,
  home: false,
  wantWorkOn: [],
  selectedTraits: [],
  customFlow: false,
  customAffirmation: {
    outcome: '',
    quality: []
  }
};

const newAffirmationSlice = createSlice({
  name: 'newAffirmationUI',
  initialState: initialStateNewAffirmationUiState,
  reducers: {
    setActualStep: (state, action: PayloadAction<number>) => {
      if (action.payload <= state.lastStep && action.payload >= 1) {
        state.actualStep = action.payload;
      }
    },
    setHomeStatus: (state, action: PayloadAction<boolean>) => {
      state.home = action.payload;
    },
    setLastStep: (state, action: PayloadAction<number>) => {
      state.lastStep = action.payload;
    },
    addToWantWork: (state, action: PayloadAction<Quality>) => {
      if (state.wantWorkOn.length < 3) {
        state.wantWorkOn = [...state.wantWorkOn, action.payload];
        state.selectedTraits = [
          ...state.selectedTraits,
          { quality: action.payload, traits: [] } as SelectedTraits
        ];
        state.lastStep = state.lastStep + 1;
      }
    },
    removeToWantWork: (state, action: PayloadAction<Quality>) => {
      state.wantWorkOn = state.wantWorkOn.filter(
        (item) => item.id !== action.payload.id
      );
      state.selectedTraits = state.selectedTraits.filter(
        (item) => item.quality.id !== action.payload.id
      );
      state.lastStep = state.lastStep - 1;
    },
    addToSelectedTraits: (
      state,
      action: PayloadAction<{ index: number; trait: Trait }>
    ) => {
      if (
        state.selectedTraits[action.payload.index] &&
        state.selectedTraits[action.payload.index].traits.length <= 2
      )
        state.selectedTraits[action.payload.index].traits = [
          ...state.selectedTraits[action.payload.index].traits,
          action.payload.trait
        ];
    },
    removeToSelectedTraits: (
      state,
      action: PayloadAction<{ index: number; trait: Trait }>
    ) => {
      if (state.selectedTraits[action.payload.index])
        state.selectedTraits[action.payload.index].traits =
          state.selectedTraits[action.payload.index].traits.filter(
            (item) => item.id !== action.payload.trait.id
          );
    },
    setAreaSelected: (
      state,
      action: PayloadAction<AreaSelected | undefined>
    ) => {
      state.areaSelected = action.payload;
    },
    resetNewAffirmationUI: (state) => {
      const actualStep = state.actualStep;
      const lastStep = state.lastStep;
      return {
        ...initialStateNewAffirmationUiState,
        actualStep,
        lastStep
      };
    },
    cleanWantWorkOn: (state) => {
      state.wantWorkOn = [];
      state.selectedTraits = [];
      state.lastStep = initialStateNewAffirmationUiState.lastStep;
    },
    setCustomFlow: (state, action: PayloadAction<boolean>) => {
      state.customFlow = action.payload;
    },
    setCustomOutcome: (state, action: PayloadAction<string>) => {
      state.customAffirmation.outcome = action.payload;
    },
    setCustomQualities: (state, action: PayloadAction<CustomQuality[]>) => {
      const newQualities = action.payload.filter(
        (newQuality) =>
          !state.customAffirmation.quality.some(
            (existing) => existing.quality === newQuality.quality
          )
      );

      state.customAffirmation.quality = [
        ...state.customAffirmation.quality,
        ...newQualities
      ];
      state.lastStep += newQualities.length + 1;
    },
    addTraitsToCustomQuality: (
      state,
      action: PayloadAction<{ qualityIndex: number; traits: string[] }>
    ) => {
      const { qualityIndex, traits } = action.payload;
      if (state.customAffirmation.quality[qualityIndex]) {
        const currentTraits =
          state.customAffirmation.quality[qualityIndex].traits;
        traits.forEach((trait) => {
          currentTraits.push(trait);
        });
      }
    },
    cleanCustomFlow: (state) => {
      state.lastStep = initialStateNewAffirmationUiState.lastStep;
      state.customAffirmation =
        initialStateNewAffirmationUiState.customAffirmation;
    }
  }
});

export const {
  setActualStep,
  setLastStep,
  addToWantWork,
  removeToWantWork,
  setAreaSelected,
  addToSelectedTraits,
  removeToSelectedTraits,
  resetNewAffirmationUI,
  cleanWantWorkOn,
  setHomeStatus,
  setCustomFlow,
  setCustomOutcome,
  setCustomQualities,
  addTraitsToCustomQuality,
  cleanCustomFlow
} = newAffirmationSlice.actions;

export default newAffirmationSlice.reducer;
