import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import {
  createVisualization,
  generateVisualization
} from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';

import RoutesEnum from 'types/routes.enum';

import { ReactComponent as CloseTag } from '../../../assets/images/CloseSquare.svg';
import StepsViewVisualization from '../StepsViewVisualization';
import NewVisualizationCustom from '../components/NewVisualizationCustom';
import AudioDurationVisualization from '../components/steps/AudioDuration';
import EmotionVisualization from '../components/steps/Steps/EmotionVisualization';
import ImprovementAreaVisualizations from '../components/steps/Steps/ImprovementAreaVisualizations';
import QualityVisualization from '../components/steps/Steps/QualityVisualization';
import SituationVisualization from '../components/steps/Steps/SituationVisualization';

export const NewVisualizations: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hasNavigated = useRef(false);
  const { createdVisualization } = useAppSelector(visualizationSelector);
  const [loading, setLoading] = useState(false);

  const {
    actualStep,
    customFlow,
    areaSelected,
    selectedOutcome,
    selectedQuality,
    selectedEmotion,
    selectedSituation
  } = useAppSelector(visualizationSelector).newVisualization;

  useEffect(() => {
    if (
      areaSelected &&
      selectedOutcome &&
      selectedQuality &&
      selectedEmotion &&
      selectedSituation
    ) {
      setLoading(true);
      dispatch(
        createVisualization({
          outcome: selectedOutcome,
          quality: selectedQuality,
          emotion: selectedEmotion,
          situation: selectedSituation
        })
      );
    }
  }, [
    areaSelected,
    selectedOutcome,
    selectedQuality,
    selectedEmotion,
    selectedSituation,
    dispatch
  ]);

  useEffect(() => {
    if (createdVisualization && !hasNavigated.current) {
      hasNavigated.current = true;
      dispatch(
        generateVisualization({ visualizationId: createdVisualization.id })
      )
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate(
            `${RoutesEnum.EDIT_VISUALIZATION}${createdVisualization.id}`
          );
        })
        .catch((error) => {
          setLoading(false);
          hasNavigated.current = false;
        });
    }
  }, [createdVisualization, dispatch, navigate]);
  if (loading) {
    return (
      <Container
        sx={{
          maxWidth: isLargeScreen ? '80%' : '100%',
          height: '75vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CircularLogoLoader />
          <Typography
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.25rem',
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: Colors.secondary.dark
            }}>
            {"We're creating your Visualization"}
          </Typography>
        </Box>
      </Container>
    );
  }
  return (
    <Container
      sx={{
        maxWidth: '80%',
        marginTop: '3rem',
        justifyContent: 'center',
        marginBottom: '2rem'
      }}>
      <Grid container>
        <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 28,
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: 'secondary.dark'
            }}>
            {'New Visualization'}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Button
            sx={{ padding: 0, minWidth: 0, backgroundColor: 'none' }}
            onClick={() => {
              navigate(RoutesEnum.USER_HOME);
            }}>
            <Box sx={{ width: 24, height: 24 }}>
              <CloseTag />
            </Box>
          </Button>
        </Grid>
      </Grid>
      <StepsViewVisualization />
      {actualStep === 1 && !customFlow && <AudioDurationVisualization />}
      {actualStep === 2 && !customFlow && <ImprovementAreaVisualizations />}
      {actualStep === 3 && !customFlow && <QualityVisualization />}
      {actualStep === 4 && !customFlow && <EmotionVisualization />}
      {actualStep === 5 && !customFlow && <SituationVisualization />}
      {customFlow && <NewVisualizationCustom />}
    </Container>
  );
};
