import { ReactComponent as LeftLeaf } from 'assets/images/home/desktop/leaf_left.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const RoadmapMobile = () => {
  const isLargeScreen = useIsLargeScreen();
  const isTablet = useMediaQuery('(min-width: 1350px)');
  const isSmallLaptop = useMediaQuery('(min-width: 999px)');

  return (
    <Container
      sx={{
        p: { xs: 0, sm: undefined },
        display: isSmallLaptop ? 'none' : 'flex',
        marginTop: '10rem',
        justifyContent: 'center',
        overflow: isTablet ? undefined : 'hidden',
        borderRadius: '12px',
        position: 'relative',
        zIndex: '0',
        '&::after': {
          content: '""',
          display: 'block',
          paddingBottom: '56.25%'
        }
      }}>
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '5%',
          position: 'absolute',
          top: '-20%',
          left: '55%',
          zIndex: '2'
        }}>
        <LeftLeaf style={{ height: '30rem', transform: 'rotate(-20deg)' }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          zIndex: '1',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: Colors.bgGreen,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'top center'
        }}
      />
      <Grid container spacing={0}>
        <Box
          zIndex={20}
          position="relative"
          display={'flex'}
          sx={{
            width: '100%',
            margin: '0',
            height: '100%',
            padding: '5rem 0',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            zIndex: '1'
          }}>
          <Grid
            container
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}>
            <Grid
              lg={12}
              item
              position="relative"
              textAlign="center"
              fontFamily={evo2FontFamily}
              fontWeight={700}
              color={Colors.white}
              fontSize={isLargeScreen ? '2.5rem' : '1.7rem'}
              zIndex={4}>
              iAffirm’s Roadmap 2024
            </Grid>
          </Grid>
          <Grid
            container
            height={'50%'}
            direction={'row'}
            alignItems={'flex-start'}
            justifyContent={'center'}
            gap={2}>
            <Grid
              lg={6}
              sm={5}
              md={5}
              item
              textAlign="center"
              fontFamily={evo2FontFamily}
              fontWeight={400}
              color={Colors.white}
              fontSize={isTablet ? '1rem' : '0.9rem'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              p="1.5rem 0 0 0">
              <div
                style={{
                  borderRadius: '50%',
                  width: '7rem',
                  height: '7rem',
                  border: '6px solid white',
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1rem 0'
                }}>
                <h1>M1</h1>
              </div>
              <Typography fontStyle="italic">
                MILESTONE 1 <br /> Technical
              </Typography>
              <ul
                style={{
                  fontSize: isTablet ? '0.8rem' : '0.7rem',
                  padding: '0 2rem'
                }}>
                <Typography fontSize={'0.8rem'}>
                  • Introduce basic Affirmations
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Improve the Platform Architecture
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Add AI Music Production
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Include User Profile’s into responses
                </Typography>
              </ul>
            </Grid>
            <Grid
              lg={6}
              md={5}
              sm={5}
              item
              textAlign="center"
              fontFamily={evo2FontFamily}
              fontWeight={400}
              color={Colors.neutralGrey}
              fontSize={isTablet ? '1rem' : '0.9rem'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              p="1.5rem 0 0 0">
              <div
                style={{
                  borderRadius: '50%',
                  width: '7rem',
                  height: '7rem',
                  border: `6px solid ${Colors.neutralGrey}`,
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1rem 0'
                }}>
                <h1>M2</h1>
              </div>
              <Typography fontStyle="italic">
                MILESTONE 2 <br /> Awareness
              </Typography>
              <ul
                style={{
                  fontSize: isTablet ? '0.8rem' : '0.7rem',
                  padding: '0 2rem'
                }}>
                <Typography fontSize={'0.8rem'}>
                  • Create and Grow Social Media Spaces
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Run Campaigns Around Awareness, Our Goals, Crowd Funding,
                  and Value Propositions
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Create Communities Where People Can Connect
                </Typography>
              </ul>
            </Grid>
            <Grid
              lg={6}
              sm={5}
              md={5}
              item
              textAlign="center"
              fontFamily={evo2FontFamily}
              fontWeight={400}
              color={Colors.Geyser}
              fontSize={isTablet ? '1rem' : '0.9rem'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              p="1.5rem 0 0 0">
              <div
                style={{
                  borderRadius: '50%',
                  width: '7rem',
                  height: '7rem',
                  border: `6px solid ${Colors.Geyser}`,
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1rem 0'
                }}>
                <h1>M3</h1>
              </div>
              <Typography fontStyle="italic">
                MILESTONE 3 <br /> Product
              </Typography>
              <ul
                style={{
                  fontSize: isTablet ? '0.8rem' : '0.7rem',
                  padding: '0 2rem'
                }}>
                <Typography fontSize={'0.8rem'}>
                  • Launch Ready-To-Use Visualizations, and Meditations
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Introduce Gamification Features
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Develop Tailored Courses
                </Typography>
              </ul>
            </Grid>
            <Grid
              lg={6}
              sm={5}
              md={5}
              item
              textAlign="center"
              fontFamily={evo2FontFamily}
              fontWeight={400}
              color={Colors.Bazaar}
              fontSize={isTablet ? '1rem' : '0.9rem'}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
              p="1.5rem 0 0 0">
              <div
                style={{
                  borderRadius: '50%',
                  width: '7rem',
                  height: '7rem',
                  border: `6px solid ${Colors.Bazaar}`,
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1rem 0'
                }}>
                <h1>M4</h1>
              </div>
              <Typography fontStyle="italic">
                MILESTONE 4 <br /> Future  Development
              </Typography>
              <ul
                style={{
                  fontSize: isTablet ? '0.8rem' : '0.7rem',
                  padding: '0 2rem'
                }}>
                <Typography fontSize={'0.8rem'}>
                  • Expand into Life Coaching services
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Creating a Bank of Learning Resources
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Add e Commerce Capabilities
                </Typography>
                <Typography fontSize={'0.8rem'}>
                  • Provide Support for Commercial Partners and their People
                </Typography>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default RoadmapMobile;
