import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { AdminAffirmation, TopItemDto } from 'services/affirmation-templates';

import { Box, Button, Grid, Typography } from '@mui/material';

import CardGrid from 'components/CardGrid';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  cleanWantWorkOn,
  setActualStep,
  setAreaSelected,
  setCustomFlow
} from 'modules/new-affirmation/redux/ui/ui.slice';

import {
  fetchPublishedAffirmations,
  getPublishedAffirmationById,
  getTrendingAffirmations
} from 'redux/affirmation-templates/templates.action';
import { affirmationsTemplate } from 'redux/affirmation-templates/templates.selector';
import {
  generateTemplate,
  getOutcomes
} from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';
import {
  setSelectedOutcome,
  setSelectedTemplate
} from 'redux/affirmation/afirmation.slice';
import { Outcome } from 'redux/affirmation/types/outcome.entity';

export const areaEmojis = {
  Interpersonal: '🫂',
  Personal: '🙏',
  Professional: '💸',
  Featured: '⭐️',
  Morning: '🌅',
  Meditation: '🧘'
};

type OutcomesMapping = {
  [area: string]: Outcome[];
};

const ImprovementArea: React.FC = () => {
  const dispatch = useAppDispatch();
  const { actualStep, areaSelected } = useAppSelector(newAffirmationUISelector);
  const { outcomes } = useAppSelector(affirmationSelector);
  const [activeOutcomeId, setActiveOutcomeId] = useState<number | null>(null);
  const [activeTemplateId, setActiveTemplateId] = useState<string | null>(null);
  const [activeTrendingId, setActiveTrendingId] = useState<
    number | string | null
  >(null);

  const { template, trendingAffirmations, selectedTemplateTrending } =
    useAppSelector(affirmationsTemplate);
  const featuredTemplates = template.filter(
    (tmpl) => tmpl.developmentArea === 'Featured'
  );
  const areasDevelopment =
    featuredTemplates.length > 3
      ? ['Featured', 'Interpersonal', 'Personal', 'Professional']
      : ['Interpersonal', 'Personal', 'Professional'];

  const handleOutcomeClick = async (outcome: Outcome, area: string) => {
    if (outcome.id < 0) {
      setActiveOutcomeId(outcome.id);
      setActiveTemplateId(null);
      setActiveTrendingId(null);
      dispatch(setCustomFlow(true));
      dispatch(setAreaSelected({ area: area }));
      dispatch(cleanWantWorkOn());
    } else {
      setActiveOutcomeId(outcome.id);
      setActiveTemplateId(null);
      setActiveTrendingId(null);
      dispatch(setCustomFlow(false));
      dispatch(setAreaSelected({ area: area }));
      dispatch(setSelectedOutcome(outcome));
      dispatch(cleanWantWorkOn());
    }
  };

  const handleTemplateSelect = (_template: AdminAffirmation, area: string) => {
    setActiveTemplateId(_template.id);
    setActiveOutcomeId(null);
    setActiveTrendingId(null);
    dispatch(setCustomFlow(false));
    dispatch(setSelectedTemplate(_template));
    dispatch(setAreaSelected({ area: area }));
    dispatch(generateTemplate({ templateId: _template.id }));
    dispatch(cleanWantWorkOn());
  };

  const handleTrendingClick = async (_template: TopItemDto, area: string) => {
    setActiveTrendingId(_template.id);
    if (_template.type === 'adminAffirmation') {
      dispatch(setCustomFlow(false));
      await dispatch(getPublishedAffirmationById(_template.id));
      if (selectedTemplateTrending) {
        dispatch(setSelectedTemplate(selectedTemplateTrending));
        dispatch(setAreaSelected({ area: area }));
        dispatch(generateTemplate({ templateId: _template.id }));
        dispatch(cleanWantWorkOn());
      }
    } else if (_template.type === 'outcome') {
      dispatch(setCustomFlow(false));
      dispatch(setAreaSelected({ area: area }));
      dispatch(
        setSelectedOutcome({
          id: _template.id as unknown as number,
          outcome: _template.name,
          recommendation: false
        })
      );
      dispatch(cleanWantWorkOn());
    }

    setActiveTemplateId(null);
    setActiveOutcomeId(null);
  };

  const handleNext = () => {
    if (activeTemplateId || activeOutcomeId || activeTrendingId) {
      dispatch(setActualStep(actualStep + 1));
    }
  };
  const typedOutcomes = outcomes as unknown as OutcomesMapping;

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    dispatch(getOutcomes());
    dispatch(getTrendingAffirmations());
    dispatch(fetchPublishedAffirmations());
    dispatch(setSelectedOutcome(undefined));
    dispatch(setAreaSelected(undefined));
    dispatch(cleanWantWorkOn());
  }, [dispatch]);

  return (
    <>
      <Grid container justifyContent="space-between" pb="1.5rem">
        <Grid item>
          <Typography color={Colors.black} fontSize="1.5rem" fontWeight={500}>
            In this area of improvement...
          </Typography>
        </Grid>
        <Grid item>
          <Typography fontSize={'0.875rem'} fontWeight={500} color={'#576361'}>
            Select 1 area to set your positive outcome
          </Typography>
        </Grid>
      </Grid>

      <Box>
        {trendingAffirmations && (
          <CardGrid
            areasDevelopment={areasDevelopment}
            typedOutcomes={typedOutcomes}
            typedTemplates={featuredTemplates}
            trendingAffirmations={trendingAffirmations}
            handleTrendingClick={handleTrendingClick}
            handleOutcomeClick={handleOutcomeClick}
            activeOutcomeId={activeOutcomeId}
            handleTemplateClick={handleTemplateSelect}
            activeTemplateId={activeTemplateId}
            activeTrendingId={activeTrendingId}
            handleScrollDown={handleScrollDown}
          />
        )}
      </Box>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStep(1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!areaSelected}
            variant="contained"
            sx={{ backgroundColor: Colors.bgGreen }}
            onClick={handleNext}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ImprovementArea;
