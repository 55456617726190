import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import { TabsContextProvider } from 'modules/new-visualization/components/TabsContext';

import { setLastStepMeditation } from 'redux/meditations/meditation.slice';

import NewMeditationForm from './NewMeditationForm';

const NewMeditationCustom: React.FC = () => {
  const dispatch = useAppDispatch();

  dispatch(setLastStepMeditation());
  return (
    <TabsContextProvider>
      <NewMeditationForm />
    </TabsContextProvider>
  );
};

export default NewMeditationCustom;
