import { IApiResponse } from 'types/apiResponse.interface';

import { api } from './instance';

export interface SubscribeRequest {
  planId: string;
  currency: string;
}

export interface SubscriptionResponse {
  subscriptionId: string;
  clientSecret: string;
}
export interface AddDefaultMethodRequest {
  paymentMethodId: string;
  customerId: string;
}
export interface Product {
  id: string;
  name: string;
  description: string | null;
  price: string;
  currency: string;
}
export interface GetProductsRequest {
  currency: string;
}

const stripeService = {
  subscribe: async (
    planId: string,
    currency: string
  ): Promise<IApiResponse<SubscriptionResponse>> => {
    const subscribeRequest: SubscribeRequest = { planId, currency };
    const response = await api.post<IApiResponse<SubscriptionResponse>>(
      'payment/subscribe',
      subscribeRequest
    );
    return response.data;
  },
  addDefaultMethod: async ({
    paymentMethodId,
    customerId
  }: AddDefaultMethodRequest): Promise<
    IApiResponse<{ PaymentConfirmation: string }>
  > => {
    const response = await api.post<
      IApiResponse<{ PaymentConfirmation: string }>
    >('payment/add-default-method', { paymentMethodId, customerId });

    return response.data;
  },
  cancelSubscription: async (): Promise<IApiResponse<string>> => {
    const response = await api.post<IApiResponse<string>>(
      'payment/cancel-subscription'
    );
    return response.data;
  },
  getProducts: async (currency: string): Promise<IApiResponse<Product[]>> => {
    const request: GetProductsRequest = { currency };
    const response = await api.post<IApiResponse<Product[]>>(
      'payment/products',
      request
    );
    return response.data;
  }
};

export default stripeService;
