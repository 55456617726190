export enum VoiceSpeed {
  Mellow = 0.75,
  Relaxed = 0.85,
  Moderate = 1.0,
  Lively = 1.15,
  Brisk = 1.3
}

export enum VoiceSelector {
  Mellow = 'Mellow',
  Relaxed = 'Relaxed',
  Moderate = 'Moderate',
  Lively = 'Lively',
  Brisk = 'Brisk'
}
