import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  getAffiliationStatus,
  getCredentialsStatus,
  requestAffiliationProcess,
  signAgreementsStatus,
  uploadPDF
} from './affiliations.action';
import {
  CredentialsStatusDTO,
  IAffiliationState
} from './types/affiliationState.interface';
import { RequestedAffiliation } from './types/affiliations.entity';

const initialState: IAffiliationState = {
  loading: false,
  error: '',
  affiliationStatus: {
    statusCreated: false,
    statusReview: false,
    statusAffiliation: false
  },
  pdfUploadResponse: {
    message: '',
    filePath: ''
  }
};

const affiliationsSlice = createSlice({
  name: 'affiliations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(requestAffiliationProcess.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        requestAffiliationProcess.fulfilled,
        (state, action: PayloadAction<RequestedAffiliation>) => {
          state.affiliationResponse = action.payload;
          state.loading = false;
        }
      )
      .addCase(requestAffiliationProcess.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAffiliationStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAffiliationStatus.fulfilled,
        (
          state,
          action: PayloadAction<{
            statusCreated: boolean;
            statusReview: boolean;
            statusAffiliation: boolean;
          }>
        ) => {
          state.affiliationStatus.statusCreated = action.payload.statusCreated;
          state.affiliationStatus.statusReview = action.payload.statusReview;
          state.affiliationStatus.statusAffiliation =
            action.payload.statusAffiliation;
          state.loading = false;
        }
      )
      .addCase(getAffiliationStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploadPDF.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        uploadPDF.fulfilled,
        (
          state,
          action: PayloadAction<{ message: string; filePath: string }>
        ) => {
          state.pdfUploadResponse = action.payload;
          state.loading = false;
        }
      )
      .addCase(uploadPDF.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCredentialsStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getCredentialsStatus.fulfilled,
        (state, action: PayloadAction<CredentialsStatusDTO>) => {
          state.credentialsStatus = action.payload;
          state.loading = false;
        }
      )
      .addCase(getCredentialsStatus.rejected, (state) => {
        state.loading = false;
      })
      .addCase(signAgreementsStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        signAgreementsStatus.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.lastSignedAgreementUrl = action.payload;
          state.loading = false;
        }
      )
      .addCase(signAgreementsStatus.rejected, (state) => {
        state.loading = false;
      });
  }
});

export default affiliationsSlice.reducer;
