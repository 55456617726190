import { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';

import wait from 'utils/wait';

const WaitScreen = () => {
  const transitionDuration = 500;
  const [isLoading, setIsLoading] = useState(true);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getAWait = async () => {
      await wait(350);
      if (modalRef.current) {
        modalRef.current.style.opacity = '0';
      }
      await wait(transitionDuration);
      setIsLoading(false);
    };
    getAWait();
  }, []);

  return (
    <>
      {isLoading && (
        <Box
          ref={modalRef}
          sx={{
            position: 'fixed',
            height: 'calc(100vh - 5rem)',
            width: '100%',
            bottom: 0,
            backgroundColor: 'whitesmoke',
            zIndex: 9999,
            opacity: 1,
            transition: `opacity ${transitionDuration}ms ease-in-out`
          }}
        />
      )}
    </>
  );
};
export default WaitScreen;
