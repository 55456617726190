import { ReactComponent as CloseTag } from 'assets/images/Close_Button.svg';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import * as Yup from 'yup';

import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { inviteUser } from 'redux/user/user.action';
import { userUiSelector } from 'redux/user/user.selector';

import { CircularLogoLoader } from './CircularLogoLoader';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '26rem',
  minWidth: '17rem',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
};

interface InvitationModalProps {
  open: boolean;
  handleClose: () => void;
}
interface FormValues {
  inviteeName: string;
  email: string;
}

const initialValues: FormValues = {
  inviteeName: '',
  email: ''
};

export const InvitationModal: React.FC<InvitationModalProps> = ({
  open,
  handleClose
}) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(userUiSelector);

  const invitationSchema = Yup.object().shape({
    inviteeName: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required')
  });

  const handleInvitation = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    await dispatch(
      inviteUser({
        inviteeEmail: values.email,
        inviteeName: values.inviteeName
      })
    );
    setSubmitting(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        {loading ? (
          <CircularLogoLoader />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={invitationSchema}
            onSubmit={handleInvitation}>
            {({ isSubmitting }) => (
              <Form>
                <Grid container alignItems="center" spacing={0} rowGap={2}>
                  <Grid item xs={11} sx={{ marginBottom: '0.5rem' }}>
                    <Typography
                      fontSize={'1.15rem'}
                      color={Colors.black}
                      lineHeight={'1.5rem'}>
                      Invite friends
                    </Typography>
                  </Grid>
                  <Grid item xs={1} sx={{ marginBottom: '0.5rem' }}>
                    <Button
                      onClick={handleClose}
                      sx={{
                        padding: 0,
                        minWidth: 'auto'
                      }}>
                      <CloseTag />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={'1.5rem'}
                      color={Colors.black}
                      lineHeight={'1.5rem'}>
                      Gift a great day!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      fontSize={'1rem'}
                      color={Colors.black}
                      lineHeight={'1.5rem'}>
                      Invite your friends to iAffirm and they will get 5 fresh
                      credits to jump-start their goals
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label="Name"
                      name="inviteeName"
                      variant="outlined"
                      fullWidth
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                    />
                    <ErrorMessage name="inviteeName" component="div" />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      as={TextField}
                      label="Email"
                      name="email"
                      variant="outlined"
                      fullWidth
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                    />
                    <ErrorMessage name="email" component="div" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    gap={1}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '1rem'
                    }}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleClose}
                      color="secondary"
                      sx={{ border: 'none' }}
                      disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}>
                      Invite
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
};
