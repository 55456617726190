import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { userSelector } from 'redux/user/user.selector';

import RoutesEnum from 'types/routes.enum';

import NewOnboarding from './NewOnboarding';

const OnboardingHandle = () => {
  const userInfo = useAppSelector(userSelector).ui.userInfo;

  if (userInfo?.onBoarding) {
    return <Navigate to={RoutesEnum.USER_HOME} />;
  }

  return <NewOnboarding />;
};

export default OnboardingHandle;
