import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

export const WelcomeAffiliate = () => {
  return (
    <Box
      sx={{
        padding: '1rem 2rem 2rem 2rem',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.75rem'}
              fontWeight={400}
              fontStyle={'italic'}
              sx={{ margin: '0.5rem 0' }}>
              Congratulations on completing the onboarding process.
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              We are thrilled to have you as part of the iAffirm family.
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Your application has been successfully submitted, and one of our
              representatives will be contacting you shortly to provide further
              details and support
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Thank you for joining us, and we look forward to a successful
              partnership!
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ marginTop: '2rem' }}>
              Best regards,
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'2rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              The iAffirm Team
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
