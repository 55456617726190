import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { userSelector } from 'redux/user/user.selector';

import { UserSubscription } from 'types/roles.enum';

interface CatalogHomeProps {
  onOptionSelect: (option: string) => void;
}
const serviceEnv = process.env.SERVICE ?? 'PRODUCTION';

export const CatalogHome: React.FC<CatalogHomeProps> = ({ onOptionSelect }) => {
  const service = serviceEnv === 'STAGING';
  const isLargeScreen = useIsLargeScreen();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const userInfo = useAppSelector(userSelector).ui.userInfo;

  const handleEvent = (value: string) => {
    setSelectedOption(value);
    onOptionSelect(value);
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  const buttonStyle = (value: string) => {
    const isSelected = selectedOption === value;
    const style: any = {
      height: '7.625rem',
      width: '100%',
      background: isSelected ? Colors.green.light : Colors.white,
      borderRadius: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      color: Colors.green.main,
      border: `1px solid ${isSelected ? Colors.green.main : Colors.lightGrey}`,
      '&:hover': {
        background: Colors.green.light,
        color: Colors.green.dark
      },
      '&:active': {
        boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
        background: Colors.green.light,
        color: Colors.green.dark
      }
    };
    if (!isSelected) {
      style.borderBottom = `5px solid ${Colors.green.light}`;
    }

    return style;
  };
  return (
    <Grid container spacing={2}>
      {['Affirmations', 'Visualizations', 'Guided Meditations'].map(
        (option) => (
          <Grid item xs={12} key={option}>
            <Button
              sx={buttonStyle(option)}
              onClick={() => {
                handleEvent(option);
                handleScrollDown();
              }}
              disabled={
                (option === 'Visualizations' &&
                  (!service ||
                    (userInfo?.subscription === UserSubscription.FREEMIUM &&
                      !(userInfo?.credits && userInfo.credits > 1)))) ||
                (option === 'Guided Meditations' &&
                  (!service ||
                    (userInfo?.subscription === UserSubscription.FREEMIUM &&
                      !(userInfo?.credits && userInfo.credits > 1)))) ||
                (option === 'Affirmations' &&
                  userInfo?.subscription === UserSubscription.FREEMIUM &&
                  !(userInfo?.credits && userInfo.credits > 1))
              }>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  width: '100%',
                  marginLeft: '5rem'
                }}>
                <Typography
                  fontFamily='"Nunito"'
                  fontSize="1rem"
                  fontWeight={600}>
                  {option}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isLargeScreen ? 'row' : 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '95%'
                  }}>
                  <Typography
                    fontFamily='"Nunito"'
                    fontSize="1rem"
                    fontWeight={400}
                    sx={{ marginTop: '0.15rem' }}>
                    {option === 'Affirmations' && 'Empowering inner coach'}
                    {option === 'Visualizations' && 'Harnessing mental imagery'}
                    {option === 'Guided Meditations' &&
                      'Facilitating inner peace'}
                  </Typography>
                  {((option === 'Guided Meditations' && !service) ||
                    (option === 'Visualizations' && !service)) && (
                    <Box
                      sx={{
                        marginTop: isLargeScreen ? '-0.5rem' : '0.5rem',
                        background: Colors.shadowWhite,
                        padding: isLargeScreen ? '0.625rem' : '0.25rem',
                        borderRadius: '0.625rem',
                        minWidth: isLargeScreen ? '10rem' : '8rem',
                        marginRight: isLargeScreen ? '1.5rem' : ''
                      }}>
                      <Typography
                        fontFamily='"Exo 2"'
                        fontWeight={500}
                        lineHeight="1.25rem"
                        sx={{ color: Colors.secondary.main }}>
                        Coming soon
                      </Typography>
                    </Box>
                  )}
                  {(option === 'Affirmations' ||
                    option === 'Visualizations' ||
                    option === 'Guided Meditations') &&
                    userInfo?.subscription === 'FREEMIUM' &&
                    service && (
                      <Box
                        sx={{
                          marginTop: isLargeScreen ? '-0.5rem' : '0.5rem',
                          background: Colors.shadowWhite,
                          padding: isLargeScreen ? '0.625rem' : '0.25rem',
                          borderRadius: '0.625rem',
                          minWidth: isLargeScreen ? '10rem' : '8rem',
                          marginRight: isLargeScreen ? '1.5rem' : ''
                        }}>
                        <Typography
                          fontFamily='"Exo 2"'
                          fontWeight={500}
                          lineHeight="1.25rem"
                          sx={{ color: Colors.secondary.main }}>
                          Join us to access
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </Button>
          </Grid>
        )
      )}
    </Grid>
  );
};
