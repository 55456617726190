import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminAffirmationsSelected } from 'services/affirmation-templates';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  resetNewAffirmationUI,
  setActualStep
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { affirmationsTemplate } from 'redux/affirmation-templates/templates.selector';
import {
  cleanPdfAndSpeech,
  resetErrorRequest,
  setSelectedUserAffirmations
} from 'redux/affirmation/afirmation.slice';
import { audioProfilesSelector } from 'redux/audio-profiles/audio-profiles.selector';
import { getUserCredits } from 'redux/subscription/subscription.action';

import RoutesEnum from 'types/routes.enum';

import ListItemSelectLoader from '../ListItemLoader';
import ListItemSelect from '../ListItemSelect';

export const SelectedAffirmationsTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { lastStep } = useAppSelector(newAffirmationUISelector);
  const {
    selectedAffirmations,
    loading,
    error: requestError
  } = useAppSelector(affirmationsTemplate);

  const [editableAffirmations, setEditableAffirmations] = useState<
    AdminAffirmationsSelected[]
  >([]);
  const [selectedAffirmationsSet, setSelectedAffirmationsSet] = useState<
    Set<string>
  >(new Set());
  const { audioDuration } = useAppSelector(audioProfilesSelector);

  const [affirmationsForSelection, setAffirmationsForSelection] =
    useState<number>(0);

  const [editableAffirmation, setEditableAffirmation] =
    useState<AdminAffirmationsSelected | null>(null);
  const [editText, setEditText] = useState('');

  const toggleSelection = (affirmationId: string) => {
    setSelectedAffirmationsSet((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(affirmationId)) {
        newSelected.delete(affirmationId);
      } else {
        if (newSelected.size < affirmationsForSelection) {
          newSelected.add(affirmationId);
        }
      }
      return newSelected;
    });
  };

  const handleEdit = (affirmation: AdminAffirmationsSelected) => {
    setEditableAffirmation(affirmation);
    setEditText(affirmation.affirmationSentence);
  };

  const saveEdit = (affirmationId: string) => {
    const updatedAffirmations = editableAffirmations.map((aff) =>
      aff.id === affirmationId ? { ...aff, affirmationSentence: editText } : aff
    );
    setEditableAffirmations(updatedAffirmations);
    setEditableAffirmation(null);
  };

  const saveSelectedAffirmations = () => {
    const selectedAndEditedAffirmations = editableAffirmations.filter(
      (affirmation) => selectedAffirmationsSet.has(affirmation.id)
    );

    dispatch(setSelectedUserAffirmations(selectedAndEditedAffirmations));
  };

  useEffect(() => {
    if (selectedAffirmations && selectedAffirmations.length) {
      dispatch(getUserCredits());
      const totalAffirmations = selectedAffirmations.length;
      const seventyFivePercentCount = Math.round(totalAffirmations * 0.65);
      setAffirmationsForSelection(seventyFivePercentCount);
      setEditableAffirmations(selectedAffirmations);

      const selected = selectedAffirmations
        .map((aff) => (Math.random() < 0.75 ? aff.id : null))
        .filter((id): id is string => id !== null)
        .slice(0, Math.round(seventyFivePercentCount * 0.75));

      setSelectedAffirmationsSet(new Set(selected));
    }
  }, [selectedAffirmations, dispatch]);

  useEffect(() => {
    if (selectedAffirmations) dispatch(resetNewAffirmationUI());
  }, [dispatch, selectedAffirmations]);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item display="flex" columnGap="1rem" alignItems="center">
          <Typography>
            {!loading &&
              selectedAffirmations &&
              selectedAffirmations.length > 0 &&
              `We've selected ${
                parseInt(
                  Math.round(affirmationsForSelection * 0.75).toString()
                ) ?? 0
              } affirmations for your goal`}
            {loading && 'Creating your affirmations'}
          </Typography>
          {loading && <CircularLogoLoader />}
        </Grid>
        {!loading && (
          <Grid item>
            <Typography
              fontSize={'0.875rem'}
              fontWeight={500}
              color={Colors.secondary.dark}>
              {selectedAffirmations &&
                selectedAffirmations.length > 0 &&
                `You can select up to ${affirmationsForSelection} affirmations for your ${audioDuration} min audio`}
            </Typography>
          </Grid>
        )}
      </Grid>
      {loading && (
        <>
          {Array(15)
            .fill('')
            .map((_, i) => (
              <ListItemSelectLoader key={i} isLoading={loading} />
            ))}
        </>
      )}

      {!loading && editableAffirmations.length > 0 && (
        <>
          {editableAffirmations.map((affirmation, index) => (
            <div
              key={affirmation.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '0.5rem'
              }}>
              {editableAffirmation?.id === affirmation.id ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                    width: '100%',
                    cursor: 'pointer',
                    height: 'min-content',
                    mb: '0.5rem'
                  }}>
                  <TextField
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                    sx={{
                      flexGrow: 1,
                      color: Colors.bgGreen
                    }}
                  />
                  <IconButton
                    color="primary"
                    onClick={() => saveEdit(affirmation.id)}
                    sx={{ marginLeft: '0.5rem', color: Colors.bgGreen }}>
                    <CheckIcon />
                  </IconButton>
                </Box>
              ) : (
                <ListItemSelect
                  label={affirmation.affirmationSentence}
                  index={index + 1}
                  isSelected={selectedAffirmationsSet.has(affirmation.id)}
                  onSelect={() => toggleSelection(affirmation.id)}
                  onEdit={() => handleEdit(affirmation)}
                  isLoading={loading}
                />
              )}
            </div>
          ))}
        </>
      )}
      {!loading && requestError && (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '1rem'
          }}>
          <Logo />
          <Typography
            fontSize={'1.5rem'}
            fontWeight={500}
            color={Colors.secondary.dark}>
            {requestError}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              dispatch(resetErrorRequest());
              navigate(RoutesEnum.USER_HOME);
            }}>
            Home
          </Button>
        </Box>
      )}
      {!loading && (
        <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
          <Grid item>
            <Button
              color="secondary"
              sx={{ backgroundColor: Colors.bgGreen }}
              variant="contained"
              disabled={selectedAffirmationsSet.size < 5}
              onClick={() => {
                saveSelectedAffirmations();
                dispatch(cleanPdfAndSpeech());
                dispatch(setActualStep(lastStep));
              }}>
              Next
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectedAffirmationsTemplate;
