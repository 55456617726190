import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import * as Yup from 'yup';

import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  setActualStep,
  setCustomQualities
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { isBannedWord } from 'utils/bannedWords';

const contextTooltipText = 'I am working towards ”Entrepreneurial excellence”';

export type CustomQuality = {
  quality: string;
  traits: string[];
};

const validationSchema = Yup.object().shape({
  qualities: Yup.array()
    .of(
      Yup.object().shape({
        quality: Yup.string()
          .required('Required')
          .min(3, 'Minimum 3 characters required')
          .test(
            'isBannedWord',
            'There is a not allowed word.',
            (value) => !isBannedWord(value)
          )
      })
    )
    .min(1, 'At least one quality is required')
    .max(3, 'No more than three qualities are allowed')
});

export const CustomQualities: React.FC = () => {
  const dispatch = useAppDispatch();
  const { actualStep } = useAppSelector(newAffirmationUISelector);

  const handleNext = (values: { qualities: CustomQuality[] }) => {
    const cleanedQualities = values.qualities.map(({ quality, traits }) => ({
      quality,
      traits
    }));

    dispatch(setCustomQualities(cleanedQualities));
    dispatch(setActualStep(actualStep + 1));
  };

  return (
    <Formik
      initialValues={{ qualities: [{ quality: '', traits: [] }] }}
      validationSchema={validationSchema}
      onSubmit={handleNext}>
      {({ values, errors, touched, isValid, dirty }) => (
        <Form>
          <Box
            mb={2}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
            <Typography sx={{ fontSize: 22 }}>
              Add the quality you are working towards
            </Typography>
            <Tooltip title={contextTooltipText} placement="top-start">
              <Box sx={{ display: 'flex', gap: 1, userSelect: 'none' }}>
                <ErrorOutlineIcon color="secondary" />
                <Typography color="secondary">Show an example</Typography>
              </Box>
            </Tooltip>
          </Box>

          <FieldArray name="qualities">
            {({ push, remove }) => (
              <>
                {values.qualities.map((quality, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: '100%',
                      marginBottom: 2,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <Field
                      as={TextField}
                      name={`qualities.${index}.quality`}
                      placeholder={
                        index > 0
                          ? '| Type your next quality'
                          : '| Type your quality'
                      }
                      variant="standard"
                      fullWidth
                      multiline
                      color="secondary"
                      error={
                        touched.qualities &&
                        touched.qualities[index]?.quality &&
                        errors.qualities &&
                        !!(errors.qualities[index] as any)?.quality
                      }
                      helperText={
                        <ErrorMessage name={`qualities.${index}.quality`} /> ||
                        `${
                          150 - quality.quality.length
                        } characters remaining (Minimum 4 characters)`
                      }
                      inputProps={{
                        style: {
                          fontSize: 22,
                          lineHeight: 1,
                          marginBottom: 20,
                          marginTop: 20
                        },
                        maxLength: 150
                      }}
                    />
                    {index > 0 && (
                      <Box>
                        <Button onClick={() => remove(index)}>
                          <DeleteIcon />
                        </Button>
                      </Box>
                    )}
                  </Box>
                ))}
                {values.qualities.length < 3 && (
                  <Button
                    onClick={() => push({ quality: '', traits: [] })}
                    sx={{ marginTop: '0.5rem', marginBottom: 13 }}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      fontWeight={500}
                      sx={{ color: Colors.bgGreen }}>
                      + Add Another Quality
                    </Typography>
                  </Button>
                )}
              </>
            )}
          </FieldArray>

          <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => dispatch(setActualStep(actualStep - 1))}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={!dirty || !isValid}
                sx={{ backgroundColor: Colors.bgGreen }}
                variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomQualities;
