export interface ProfessionalOptions {
  challenge: string;
}

export const ProfessionalChalleges: ProfessionalOptions[] = [
  { challenge: 'Reduced Stress and Anxiety' },
  { challenge: 'Improve Mental Clarity and Focus' },
  { challenge: 'Boost Self-Confidence and Self-Esteem' },
  { challenge: 'Develop a More Positive Mindset' },
  { challenge: 'Cultivate Inner Peace' },
  { challenge: 'Enhance Emotional Well-being' },
  { challenge: 'Achieve Better Work-Life Ballance' },
  { challenge: 'I prefer not to answer this question' }
];
