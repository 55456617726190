import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { signAgreementsStatus } from 'redux/affiliation/affiliations.action';
import { affiliationSelector } from 'redux/affiliation/affiliations.selector';
import { AgreementsEnum } from 'redux/affiliation/types/affiliations.entity';
import { setAffiliateModal } from 'redux/user/user.slice';

import { ApplicationIntroProps } from './ApplicationIntro';

export const Agreements: React.FC<ApplicationIntroProps> = ({
  setActiveStep
}) => {
  const dispatch = useAppDispatch();
  const [ndaChecked, setNdaChecked] = useState<boolean>(false);
  const [aaChecked, setAaChecked] = useState<boolean>(false);
  const [signature, setSignature] = useState<string>('');
  const { loading } = useAppSelector(affiliationSelector);

  const handleContinue = async () => {
    await dispatch(
      signAgreementsStatus({
        agreement: AgreementsEnum.NDA,
        sign: signature
      })
    );
    await dispatch(
      signAgreementsStatus({
        agreement: AgreementsEnum.AA,
        sign: signature
      })
    );

    setActiveStep(7);
  };

  const NDAFile =
    'https://raw.githubusercontent.com/SdlsH/PDFDocs/469d6f7db29799d141e45230db3d722ca0472133/iAffirm-Non-Disclosure-Agreement.pdf';
  const AAFile =
    'https://raw.githubusercontent.com/SdlsH/PDFDocs/469d6f7db29799d141e45230db3d722ca0472133/iAffirm-Affiliate-Agreement.pdf';
  return (
    <>
      {!loading ? (
        <Grid container spacing={2} padding={'1.5rem'} justifyContent="center">
          <Grid item xs={12}>
            <Box>
              <Grid
                container
                spacing={2}
                alignItems="center"
                padding={'0 0.5rem'}>
                <Grid item xs={10}>
                  <Typography
                    fontFamily="'Nunito'"
                    fontSize={'1rem'}
                    lineHeight={'1.75rem'}
                    fontWeight={400}>
                    Click to view our Non Disclosure Agreement
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <Button fullWidth variant="contained" href={NDAFile}>
                    View
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Grid
                container
                spacing={2}
                alignItems="center"
                padding={'0 0.5rem'}>
                <Grid item xs={10}>
                  <Typography
                    fontFamily="'Nunito'"
                    fontSize={'1rem'}
                    lineHeight={'1.75rem'}
                    fontWeight={400}>
                    Click to view our Affiliate Agreement
                  </Typography>
                </Grid>
                <Grid item xs={2} textAlign="center">
                  <Button fullWidth variant="contained" href={AAFile}>
                    View
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              component="fieldset"
              sx={{
                border: `1px solid ${Colors.neutralGrey}`,
                borderRadius: '0.25rem',
                padding: '1rem 1rem 1.25rem 1rem',
                position: 'relative',
                paddingTop: '0rem'
              }}>
              <legend
                style={{
                  padding: '0.5rem',
                  fontSize: '0.75rem',
                  color: Colors.neutralGrey
                }}>
                Content overview
              </legend>
              <Typography
                fontFamily="'Nunito'"
                fontSize={'1rem'}
                lineHeight={'1.75rem'}
                fontWeight={400}
                sx={{ margin: '0.5rem 0' }}>
                Tick here to agree to our NDA:
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ndaChecked}
                    onChange={(e) => setNdaChecked(e.target.checked)}
                  />
                }
                label="I have read and agree to the Non Disclosure Agreement."
              />

              <Typography
                fontFamily="'Nunito'"
                fontSize={'1rem'}
                lineHeight={'1.75rem'}
                fontWeight={400}
                sx={{ margin: '0.5rem 0' }}>
                Tick here to agree to our AA:
              </Typography>
              <FormControlLabel
                sx={{ marginBottom: '1rem' }}
                control={
                  <Checkbox
                    checked={aaChecked}
                    onChange={(e) => setAaChecked(e.target.checked)}
                  />
                }
                label="I have read and agree to the Affiliate Agreement."
              />

              <TextField
                label="Signature"
                variant="outlined"
                fullWidth
                value={signature}
                onChange={(e) => setSignature(e.target.value)}
                placeholder="Sign here and we will use this signature on both agreements"
              />
            </Box>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            paddingY={2}
            mt={1}>
            <Grid item>
              <Button
                color="secondary"
                variant="text"
                onClick={() => dispatch(setAffiliateModal(false))}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!ndaChecked || !aaChecked || !signature}
                onClick={handleContinue}
                variant="contained">
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <CircularLogoLoader />
      )}
    </>
  );
};
