export interface CareerOptions {
  goal: string;
}

export const CareerGoals: CareerOptions[] = [
  { goal: 'Advance in current role' },
  { goal: 'Change career path' },
  { goal: 'Gain leadership position' },
  { goal: 'Achieve work-life balance' },
  { goal: 'Entrepreneurship' },
  { goal: 'Obtain further education' },
  { goal: 'Increase earnings' },
  { goal: 'I prefer not to answer this question.' }
];

export const MotivationsOptions: CareerOptions[] = [
  { goal: 'Achieving Personal Growth and Self-Improvement' },
  { goal: 'Making a Positive Impact on Others' },
  { goal: 'Financial Success and Independence' },
  { goal: 'Building Strong Relationships and Connections' },
  { goal: 'Overcoming Challenges and Adversity' },
  { goal: 'Career Advancement and Recognition' },
  { goal: 'Creativity and Innovation' },
  { goal: 'Fulfilling a Passion or Hoby' },
  { goal: 'Growing Family Bonds and Experiences' },
  { goal: 'I prefer not to answer this question.' }
];
