import { createContext, useEffect, useState } from 'react';

type Tab = { id: number; status: boolean };

export type TabsUIContextProps = {
  currentTab: Tab | undefined;
  tabs: Tab[];
  setTabs: React.Dispatch<React.SetStateAction<Tab[]>>;
  toggleTab: (tabId: number) => void;
  goNextTab: () => void;
  goBackTab: () => void;
  goToTab: (tabId: number) => void;
};

const TabsContext = createContext<TabsUIContextProps | null>(null);

export const TabsContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [tabs, setTabs] = useState<Tab[]>([]);
  const [currentTab, setCurrentTab] = useState<Tab>();

  const toggleTab = (tabId: number) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.id === tabId) return { ...tab, status: !tab.status };

        return tab;
      })
    );
  };

  const goNextTab = () => {
    setTabs((prevTabs) => {
      let isNextTab = false;

      return prevTabs.map((tab) => {
        if (tab.status === false && !isNextTab) {
          isNextTab = true;
          return { ...tab, status: true };
        }

        return tab;
      });
    });
  };

  const goBackTab = () => {
    setTabs((prevTabs) => {
      let prevTabIndex = 0;
      prevTabs.forEach((tab, index) => {
        if (tab.status === false && index !== 0) {
          prevTabIndex = --index;
        } else if (index === prevTabs.length - 1) {
          prevTabIndex = index;
        }
      });

      return prevTabs.map((tab, index) => {
        if (index === prevTabIndex && index !== 0)
          return { ...tab, status: false };

        return tab;
      });
    });
  };

  useEffect(() => {
    for (let i = tabs.length - 1; i >= 0; i--) {
      if (tabs[i].status === true) {
        setCurrentTab(tabs[i]);
        break;
      }
    }
  }, [tabs]);

  const goToTab = (tabId: number) => {
    const newTabs = tabs.map((tab, index) => {
      if (tab.id === tabId) {
        return { ...tab, status: true };
      } else if (index < tabId) {
        // Optionally set previous tabs as completed or true
        return { ...tab, status: true };
      }
      return { ...tab, status: false };
    });
    setTabs(newTabs);
  };
  useEffect(() => {
    for (let i = tabs.length - 1; i >= 0; i--) {
      if (tabs[i].status === true) {
        setCurrentTab(tabs[i]);
        break;
      }
    }
  }, [tabs]);

  return (
    <TabsContext.Provider
      value={{
        currentTab,
        tabs,
        setTabs,
        toggleTab,
        goNextTab,
        goBackTab,
        goToTab
      }}>
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContext;
