import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import React from 'react';

import { Box, CircularProgress } from '@mui/material';

export const CircularLogoLoader: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <CircularProgress size={80} />
      <Box
        sx={{
          position: 'absolute',

          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          height: '40%'
        }}>
        <Logo style={{ width: '100%', height: '100%' }} />
      </Box>
    </Box>
  );
};
