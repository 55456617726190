import { IApiResponse } from 'types/apiResponse.interface';

import { api } from './instance';

export interface AffirmationTemplateDto {
  affirmationID: string;
}

export interface AdminAffirmation {
  id: string;
  title: string;
  message: string;
  developmentArea: string;
  outcome: string;
  qualities: AdminQualities[];
  Published: boolean;
  review: boolean;
}

export interface AdminQualities {
  id: number;
  quality?: string;
  traits: AdminTraits[];
  otherQuality?: string;
}

export interface AdminTraits {
  id: number;
  trait: string;
}

export interface AdminAffirmationsSelected {
  id: string;
  affirmationSentence: string;
}

export interface TopItemDto {
  id: string;
  type: 'outcome' | 'adminAffirmation';
  name: string;
  count: number;
}

export interface Top5AffirmationsDto {
  topItems: TopItemDto[];
}

const _affirmationTemplatesService = {
  getPublishedAffirmations: async (): Promise<
    IApiResponse<AdminAffirmation[]>
  > => {
    const response = await api.get<IApiResponse<AdminAffirmation[]>>(
      'admin-area/affirmations-published'
    );
    return response.data;
  },

  getAffirmationsInTemplate: async (
    affirmationTemplateDto: AffirmationTemplateDto
  ): Promise<IApiResponse<AdminAffirmationsSelected[]>> => {
    const response = await api.post<IApiResponse<AdminAffirmationsSelected[]>>(
      'admin-area/affirmations-template',
      affirmationTemplateDto
    );
    return response.data;
  },

  getTrending: async (): Promise<IApiResponse<Top5AffirmationsDto>> => {
    const response = await api.get<IApiResponse<Top5AffirmationsDto>>(
      'admin-area/affirmations-trending'
    );
    return response.data;
  },

  getPublishedAffirmationById: async (
    affirmationTemplateId: string
  ): Promise<IApiResponse<AdminAffirmation>> => {
    const response = await api.get<IApiResponse<AdminAffirmation>>(
      `admin-area/affirmations-published-trending/${affirmationTemplateId}`
    );
    return response.data;
  }
};

export default _affirmationTemplatesService;
