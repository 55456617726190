import { createAsyncThunk } from '@reduxjs/toolkit';
import audioProfilesService from 'services/audio-profiles';

import {
  AudioLibraryDefault,
  CreateAudioProfileDto,
  CreatedAudioProfile,
  DefaultProfileDTO,
  GetDeafaultUrlParams,
  GetDeafaultUrlResponse,
  UserAudioLibrary
} from './types/audio-profiles.entity';

export const createAudioProfile = createAsyncThunk<
  CreatedAudioProfile,
  CreateAudioProfileDto,
  { rejectValue: string }
>(
  'audioProfiles/create',
  async (data: CreateAudioProfileDto, { rejectWithValue }) => {
    try {
      const response = await audioProfilesService.createAudioProfile(data);
      return response.data.data;
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        'An unexpected error occurred during audio profile creation.';
      return rejectWithValue(errorMessage);
    }
  }
);

export const getDefaultAudioProfiles = createAsyncThunk<AudioLibraryDefault[]>(
  'audioProfiles/default-onboarding',
  async () => {
    return (await audioProfilesService.getDefaultAudioProfiles()).data.data;
  }
);

export const getUserAudioProfiles = createAsyncThunk<
  Partial<UserAudioLibrary>[]
>('audioProfiles/user', async () => {
  return (await audioProfilesService.getAudioProfiles()).data.data;
});

export const deleteUserAudioProfile = createAsyncThunk(
  'audioProfiles/deleteUserAudioProfile',
  async (id: string) => {
    return (await audioProfilesService.deleteUserAudioProfile(id)).data.data;
  }
);

export const testAudio = createAsyncThunk<
  GetDeafaultUrlResponse,
  GetDeafaultUrlParams
>('audioProfiles/testAudio', async (params: GetDeafaultUrlParams) => {
  const response = (await audioProfilesService.testAudio(params)).data;
  return { url: response.data };
});

export const saveDefaultAudioProfileForUser = createAsyncThunk<
  CreatedAudioProfile,
  DefaultProfileDTO
>('audioProfiles/saveDefault', async (data: DefaultProfileDTO) => {
  const response = await audioProfilesService.audioDefaultPreference(data);
  return response.data.data;
});

export const getAdminAudioProfiles = createAsyncThunk<AudioLibraryDefault[]>(
  'audioProfiles/adminAudioProfiles',
  async () => {
    const response = await audioProfilesService.getAdminAudioProfiles();
    return response.data.data;
  }
);
