import * as Yup from 'yup';

const phoneCodeRegExp = /^\+\d{1,3}$/;
const phoneNumberRegExp = /^\d{7,10}$/;

export const affiliateValidationSchema = Yup.object().shape({
  phoneCode: Yup.string()
    .matches(phoneCodeRegExp, 'Phone code is not valid')
    .required('Phone code is required'),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegExp, 'Phone number is not valid')
    .required('Phone number is required'),
  website: Yup.string(),
  socials: Yup.array()
    .of(
      Yup.object().shape({
        url: Yup.string().required('URL is required')
      })
    )
    .required('Socials are required')
    .min(1, 'At least one social is required'),
  contentType: Yup.string().required('Content Type is required'),
  contentDemographics: Yup.string()
    .required('Content Demographics are required')
    .min(7, 'Content Demographics must be at least 7 characters'),
  recentContent: Yup.string()
    .required('Recent Content is required')
    .min(4, 'Recent Content must be at least 4 characters')
});
