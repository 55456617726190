import { createAsyncThunk } from '@reduxjs/toolkit';
import stripeService, {
  AddDefaultMethodRequest,
  Product,
  SubscribeRequest,
  SubscriptionResponse
} from 'services/stripe';

export const stripeSubscription = createAsyncThunk<
  SubscriptionResponse,
  SubscribeRequest
>('stripe/subscribe', async ({ planId, currency }: SubscribeRequest) => {
  return (await stripeService.subscribe(planId, currency)).data;
});

export const addDefaultPaymentMethod = createAsyncThunk<
  { PaymentConfirmation: string },
  AddDefaultMethodRequest
>('stripe/add-default-method', async (requestData: AddDefaultMethodRequest) => {
  return (await stripeService.addDefaultMethod(requestData)).data;
});

export const cancelSubscription = createAsyncThunk(
  'stripe/cancel-subscription',
  async () => {
    return (await stripeService.cancelSubscription()).data;
  }
);

export const getStripeProducts = createAsyncThunk<
  Product[],
  { currency: string }
>('stripe/products', async ({ currency }: { currency: string }) => {
  return (await stripeService.getProducts(currency)).data;
});
