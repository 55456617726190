import { ProfileUserDTO } from 'types/profileUser.interface';

export const QuestionsMapping: { [label: string]: string } = {
  Industry: 'industry',
  Occupation: 'occupation',
  'Career Goals': 'careerGoals',
  'Skill Development': 'skillDevelopment',
  'Career Path': 'careerPath',
  'Personal Development Goals': 'personalDevelopmentGoals',
  'Lifestyle Preferences': 'lifestylePreferences',
  'Hobbies and Interests': 'hobbiesAndInterests',
  'Stress Factors': 'stressFactors',
  'Coping Mechanisms': 'copingMechanisms',
  'Emotional Support': 'emotionalSupport',
  'Physical Health': 'physicalHealth',
  'Fitness Routine': 'fitnessRoutine',
  'Dietary Preferences': 'dietaryPreferences',
  'Inspirational Figures': 'inspirationalFigures',
  Aspirations: 'aspirations',
  Preferences: 'preferences',
  'Feedback on Past Programs': 'feedbackOnPastPrograms',
  'Communication Preferences': 'communicationPreferences',
  'Learning Interests': 'learningInterests',
  'Reading and Media Consumption': 'readingAndMediaConsumption',
  'Personal Growth Activities': 'personalGrowthActivities',
  'Social Circle': 'socialCircle',
  'Community Involvement': 'communityInvolvement',
  'Social Goals': 'socialGoals'
};

interface UserProfileInfo {
  id: string;
  createdAt: string;
  updatedAt: string;
  industry?: string;

  occupation?: string;

  /* Professional Development and Goals */

  careerGoals?: string;

  skillDevelopment?: string;

  careerPath?: string;

  /* Personal Goals and Outlooks */

  personalDevelopmentGoals?: string;

  lifestylePreferences?: string;

  hobbiesAndInterests?: string;

  /* Emotional and Mental Well-being */

  stressFactors?: string;

  copingMechanisms?: string;

  emotionalSupport?: string;

  /* Health and Wellness */

  physicalHealth?: string;

  fitnessRoutine?: string;

  dietaryPreferences?: string;

  /* Motivations and Inspirations */

  inspirationalFigures?: string;

  aspirations?: string;

  preferences?: string;

  /* Feedback and Customization Preferences */

  feedbackOnPastPrograms?: string;

  communicationPreferences?: string;

  /* Learning and Growth */

  learningInterests?: string;

  readingAndMediaConsumption?: string;

  personalGrowthActivities?: string;

  /* Social and Community Engagement */

  socialCircle?: string;

  communityInvolvement?: string;

  socialGoals?: string;
}

interface UserResponses {
  [key: string]: string;
}

export const transformProfileToMap = (
  profileInfo?: UserProfileInfo | ProfileUserDTO
): UserResponses => {
  if (!profileInfo) {
    return {};
  }

  const { id, createdAt, updatedAt, ...userResponses } =
    profileInfo as UserProfileInfo;
  return userResponses as UserResponses;
};
