import { SubscriptionResponse } from 'redux/subscription/types/subscription.interface';

import { IApiResponse } from 'types/apiResponse.interface';

import { api } from './instance';

const _subscriptionService = {
  getUserCredits: async (): Promise<IApiResponse<SubscriptionResponse>> => {
    const response = await api.post<IApiResponse<SubscriptionResponse>>(
      '/subscription'
    );
    return response.data;
  },

  addCredits: async (
    creditsToAdd: string
  ): Promise<IApiResponse<SubscriptionResponse>> => {
    const response = await api.patch<IApiResponse<SubscriptionResponse>>(
      `/subscription/add-credits/${creditsToAdd}`
    );
    return response.data;
  }
};

export default _subscriptionService;
