import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { getCredentialsStatus } from 'redux/affiliation/affiliations.action';

import { ApplicationIntroProps } from './ApplicationIntro';

export const AffiliateAccepted: React.FC<ApplicationIntroProps> = ({
  setActiveStep
}) => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        padding: '1rem 2rem',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            borderRadius: '0.25rem'
          }}>
          <Box>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.75rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              You have met the criteria for the iAffirm affiliate program.
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'2rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Please continue the onboarding process
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={2}>
        <Grid item>
          <Button
            type="submit"
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            onClick={() => {
              setActiveStep(5);
              dispatch(getCredentialsStatus());
            }}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
