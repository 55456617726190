import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';

import { Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  addToSelectedTraits,
  removeToSelectedTraits
} from 'modules/new-affirmation/redux/ui/ui.slice';

import BoxSelect from '../BoxSelect';

interface ITraitsForThreeOptionProps {
  handleNextStep: () => void;
  handlePreviousStep: () => void;
}

const TraitsForThreeOption = ({
  handleNextStep,
  handlePreviousStep
}: ITraitsForThreeOptionProps) => {
  const dispatch = useAppDispatch();
  const { wantWorkOn, selectedTraits } = useAppSelector(
    newAffirmationUISelector
  );

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography>
            Especially in these traits for {wantWorkOn[2].quality}...
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select up to 3 traits from the list
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {wantWorkOn[2].traits.map((trait, i) => (
          <Grid item xs={6} md={4} key={i}>
            <BoxSelect
              onClick={() => {
                selectedTraits[2].traits
                  .map((item) => item.id)
                  .includes(trait.id)
                  ? dispatch(removeToSelectedTraits({ index: 2, trait }))
                  : dispatch(addToSelectedTraits({ index: 2, trait }));
                !selectedTraits[2].traits
                  .map((item) => item.id)
                  .includes(trait.id) && handleScrollDown();
              }}
              isSelected={selectedTraits[2].traits
                .map((item) => item.id)
                .includes(trait.id)}>
              <Typography textAlign="center">{trait.trait}</Typography>
            </BoxSelect>
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            variant="outlined"
            onClick={handlePreviousStep}
            color="secondary">
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{ backgroundColor: Colors.bgGreen }}
            onClick={handleNextStep}
            disabled={selectedTraits[2].traits.length === 0}
            color="secondary">
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default TraitsForThreeOption;
