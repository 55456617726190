import { createAsyncThunk } from '@reduxjs/toolkit';
import _affirmationTemplatesService from 'services/affirmation-templates';

export const fetchPublishedAffirmations = createAsyncThunk(
  'affirmations/fetchPublished',
  async () => {
    const response =
      await _affirmationTemplatesService.getPublishedAffirmations();
    return response.data;
  }
);

export const fetchAffirmationsInTemplate = createAsyncThunk(
  'affirmations/fetchInTemplate',
  async (affirmationID: string) => {
    const response =
      await _affirmationTemplatesService.getAffirmationsInTemplate({
        affirmationID
      });
    return response.data;
  }
);

export const getTrendingAffirmations = createAsyncThunk(
  'affirmations/fetchTrending',
  async () => {
    const response = await _affirmationTemplatesService.getTrending();
    return response.data;
  }
);

export const getPublishedAffirmationById = createAsyncThunk(
  'affirmations/fetchPublishedById',
  async (affirmationTemplateId: string) => {
    const response =
      await _affirmationTemplatesService.getPublishedAffirmationById(
        affirmationTemplateId
      );
    return response.data;
  }
);
