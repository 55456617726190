import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AdminAffirmation } from 'services/affirmation-templates';

import {
  generateAffirmation,
  generateCustomAffirmation,
  generateTemplate,
  getMorningAffirmations,
  getOutcomes,
  getPdf,
  getQualities,
  getSpeech,
  postAffirmation
} from './affirmation.action';
import {
  Affirmation,
  CreatedAffirmation,
  GeneratedAffirmations,
  MorningAffirmation
} from './types/affirmation.entity';
import { IAffirmationState } from './types/affirmationState.interface';
import { Outcome } from './types/outcome.entity';
import { Quality } from './types/quality.entity';

const initialState: IAffirmationState = {
  outcomes: [],
  loading: false,
  speechLoading: false,
  isSpeechProcessing: false,
  selectedUserAffirmations: [],
  customFlow: false
};

const affirmationSlice = createSlice({
  name: 'affirmation',
  initialState,
  reducers: {
    setSelectedOutcome: (state, action: PayloadAction<Outcome | undefined>) => {
      state.selectedOutcome = action.payload;
    },
    setSelectedTemplate: (
      state,
      action: PayloadAction<AdminAffirmation | undefined>
    ) => {
      state.selectedTemplate = action.payload;
    },
    setSelectedUserAffirmations: (
      state,
      action: PayloadAction<Affirmation[]>
    ) => {
      state.selectedUserAffirmations = action.payload;
    },
    cleanPdfAndSpeech: (state) => {
      state.speech = undefined;
      state.pdf = undefined;
    },
    resetAffirmationState: (state) => {
      if (!state.affirmations && state.selectedOutcome)
        return { ...initialState, selectedOutcome: state.selectedOutcome };
      return { ...initialState };
    },
    setIsSpeechProcessing: (state, action: PayloadAction<boolean>) => {
      state.isSpeechProcessing = action.payload;
    },
    resetErrorRequest: (state) => {
      if (state.requestError) {
        state.requestError = undefined;
      }
    },
    setAffirmationCustom: (state, action: PayloadAction<boolean>) => {
      state.customFlow = action.payload;
    },
    getMorningAffirmations: (
      state,
      action: PayloadAction<MorningAffirmation>
    ) => {
      state.morningAffirmation = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getOutcomes.fulfilled,
        (state, action: PayloadAction<Outcome[]>) => {
          state.outcomes = action.payload;
          state.loading = false;
        }
      )
      .addCase(getOutcomes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOutcomes.rejected, (state) => {
        state.outcomes = [];
        state.loading = false;
      })
      .addCase(
        getQualities.fulfilled,
        (state, action: PayloadAction<Quality[]>) => {
          state.qualities = action.payload;
          state.loading = false;
        }
      )
      .addCase(getQualities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQualities.rejected, (state) => {
        state.outcomes = [];
        state.loading = false;
      })
      .addCase(
        postAffirmation.fulfilled,
        (state, action: PayloadAction<GeneratedAffirmations>) => {
          state.affirmations = action.payload;
          state.loading = false;
        }
      )
      .addCase(postAffirmation.pending, (state) => {
        state.loading = true;
      })
      .addCase(postAffirmation.rejected, (state, action) => {
        if (action.error.code === 'ERR_BAD_RESPONSE') {
          state.requestError = 'Ups, something went wrong!';
        }
        state.affirmations = undefined;
        state.loading = false;
      })
      .addCase(
        generateAffirmation.fulfilled,
        (state, action: PayloadAction<CreatedAffirmation>) => {
          state.createdAffirmation = action.payload;
          state.loading = false;
        }
      )
      .addCase(generateAffirmation.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateAffirmation.rejected, (state) => {
        state.affirmations = undefined;
        state.loading = false;
      })
      .addCase(
        generateCustomAffirmation.fulfilled,
        (state, action: PayloadAction<CreatedAffirmation>) => {
          state.createdAffirmation = action.payload;
          state.loading = false;
        }
      )
      .addCase(generateCustomAffirmation.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateCustomAffirmation.rejected, (state) => {
        state.affirmations = undefined;
        state.loading = false;
      })
      .addCase(getPdf.fulfilled, (state, action: PayloadAction<string>) => {
        state.pdf = action.payload;
      })
      .addCase(getPdf.pending, (state) => {})
      .addCase(getPdf.rejected, (state) => {
        state.pdf = undefined;
      })
      .addCase(getSpeech.pending, (state) => {
        state.speechLoading = true;
        state.isSpeechProcessing = true;
      })
      .addCase(getSpeech.fulfilled, (state, action: PayloadAction<string>) => {
        state.speech = action.payload;
        state.speechLoading = false;
        const urlRegex = /^https?:\/\//;
        state.isSpeechProcessing = !urlRegex.test(action.payload);
      })
      .addCase(getSpeech.rejected, (state) => {
        state.speechLoading = false;
        state.speech = undefined;
        state.isSpeechProcessing = false;
      })
      .addCase(
        generateTemplate.fulfilled,
        (state, action: PayloadAction<CreatedAffirmation>) => {
          state.createdAffirmation = action.payload;
          state.affirmations = undefined;
          state.loading = false;
        }
      )
      .addCase(generateTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateTemplate.rejected, (state) => {
        state.affirmations = undefined;
        state.loading = false;
      })
      .addCase(
        getMorningAffirmations.fulfilled,
        (state, action: PayloadAction<MorningAffirmation[]>) => {
          state.morningAffirmation = action.payload[0];
        }
      )
      .addCase(getMorningAffirmations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMorningAffirmations.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const {
  resetErrorRequest,
  cleanPdfAndSpeech,
  setSelectedOutcome,
  setSelectedTemplate,
  setAffirmationCustom,
  setIsSpeechProcessing,
  resetAffirmationState,
  setSelectedUserAffirmations
} = affirmationSlice.actions;

export default affirmationSlice.reducer;
