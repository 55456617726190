import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

export const AffiliateRejected: React.FC = () => {
  return (
    <Box
      sx={{
        padding: '1rem 2rem 2rem 2rem',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.75rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              We appreciate your time and effort in applying to join our
              affiliate program.
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                'After careful review, we regret to inform you that your application has not met the criteria for becoming an iAffirm affiliate at this time.'
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                'We encourage you to continue growing your social media presence and engaging with your audience. You are welcome to reapply in the future (30 days) as you expand your influence.'
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                'Thank you for your understanding, and we wish you the best in your endeavours.'
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ marginTop: '2rem' }}>
              Sincerely,
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'2rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              The iAffirm Team
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
