import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';

import { Button, Grid, Skeleton, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import BoxSelect from 'modules/new-affirmation/components/BoxSelect';

import { getAttributeByGoals } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import {
  addAttributeMeditation,
  setActualStepMeditation
} from 'redux/meditations/meditation.slice';
import { Attribute } from 'redux/meditations/types/meditation.entity';

const AttributeMeditation = () => {
  const dispatch = useAppDispatch();
  const [customAttribute, setCustomAttribute] = useState<string | undefined>(
    undefined
  );
  const [activeAttributeId, setActiveAttributeId] = useState<number | null>(
    null
  );
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const [selectedAttribute, setSelectedAttribute] = useState<any>(null);

  const { actualStep, selectedGoal } =
    useAppSelector(medidationSelector)?.newMeditation;

  const { loading, attributeByGoals } = useAppSelector(medidationSelector);

  useEffect(() => {
    if (selectedGoal && selectedGoal.id) {
      dispatch(getAttributeByGoals(selectedGoal.id));
    }
  }, [dispatch, selectedGoal]);

  const handleBoxSelectClick = (attribute: Attribute) => {
    setActiveAttributeId(attribute.id ? attribute.id : 0);
    setCustomAttribute(undefined);
    setShowTextField(false);
    setSelectedAttribute(attribute);
  };

  const handleNextClick = () => {
    if (customAttribute) {
      dispatch(addAttributeMeditation({ attribute: customAttribute }));
    } else if (selectedAttribute) {
      dispatch(addAttributeMeditation(selectedAttribute));
    }

    dispatch(setActualStepMeditation(actualStep + 1));
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.5rem'}
            color={Colors.black}>
            Choose a Attribute
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select one Attribute from the list or add a custom one.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {loading
          ? Array(4)
              .fill('')
              .map((_, i) => (
                <Grid item xs={6} md={3} key={i}>
                  <Skeleton height="8.75rem" />
                </Grid>
              ))
          : attributeByGoals &&
            attributeByGoals.map((attribute, i) => (
              <Grid item xs={6} md={4} key={i}>
                <BoxSelect
                  onClick={() => {
                    handleBoxSelectClick(attribute);
                    handleScrollDown();
                  }}
                  isSelected={activeAttributeId === attribute.id}>
                  <Typography sx={{ textAlign: 'center' }}>
                    {attribute.attribute}
                  </Typography>
                </BoxSelect>
              </Grid>
            ))}
      </Grid>

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStepMeditation(actualStep - 1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            disabled={!selectedAttribute && !customAttribute}
            onClick={handleNextClick}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AttributeMeditation;
