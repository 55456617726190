import { Quality } from './quality.entity';

export type Outcome = {
  id: number;
  outcome: string;
  recommendation?: boolean;
  qualities?: Quality[];
};

export type IDevelopmentArea = {
  [developmentArea: string]: {
    id: number;
    outcome: string;
    recommendation: boolean;
  }[];
};
interface CustomOutcomeMap {
  [key: string]: {
    id: number;
    outcome: string;
    recommendation: boolean;
    qualities: Quality[];
  };
}

export const customOutcomes: CustomOutcomeMap = {
  Interpersonal: {
    id: -1,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Personal: {
    id: -2,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Professional: {
    id: -3,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  }
};
