import React from 'react';

import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import RoutesEnum from 'types/routes.enum';

interface ConditionsProps {
  onTermsChange: (checked: boolean) => void;
  onDataPolicyChange: (checked: boolean) => void;
  termsChecked: boolean;
  dataPolicyChecked: boolean;
}
export const Conditions: React.FC<ConditionsProps> = ({
  onTermsChange,
  onDataPolicyChange,
  termsChecked,
  dataPolicyChecked
}) => {
  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            component="fieldset"
            sx={{
              border: `1px solid ${Colors.neutralGrey}`,
              borderRadius: '0.25rem',
              padding: '1rem',
              position: 'relative',
              paddingTop: '0rem',
              paddingBottom: '0rem',
              '& legend': {
                padding: '0.5rem',
                fontSize: '0.75rem',
                color: Colors.neutralGrey
              }
            }}>
            <legend>Content overview</legend>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.75rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Tick here to agree to our terms and conditions:
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0' }}>
              <Checkbox
                checked={termsChecked}
                onChange={(e) => onTermsChange(e.target.checked)}
                value="remember"
                color="secondary"
                size="small"
              />
              I have read and agree to the Terms and Conditions.
            </Typography>
            <Button
              onClick={() => window.open(RoutesEnum.TERMS_CONDITIONS, '_blank')}
              sx={{ marginLeft: '0.5rem' }}>
              {' Click to read our T’s and C’s'}
            </Button>

            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Tick here to agree to our data policy:
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{}}>
              <Checkbox
                checked={dataPolicyChecked}
                onChange={(e) => onDataPolicyChange(e.target.checked)}
                value="remember"
                color="secondary"
                size="small"
              />
              I have read and agree to the Data Policy.
            </Typography>
            <Button
              onClick={() => window.open(RoutesEnum.TERMS_CONDITIONS, '_blank')}
              sx={{ marginLeft: '0.5rem' }}>
              {' '}
              Click to read our Data Policy
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
