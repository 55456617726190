import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UploaderState } from './types/uploaderState';
import { uploaderFileUrl } from './uploader.action';

const initialState: UploaderState = {
  loading: false
};

const uploaderSlice = createSlice({
  name: 'uploader',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        uploaderFileUrl.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.audioLink = action.payload;
          state.loading = false;
        }
      )
      .addCase(uploaderFileUrl.rejected, (state) => {
        state.loading = false;
      })
      .addCase(uploaderFileUrl.pending, (state) => {
        state.loading = true;
      });
  }
});
export default uploaderSlice.reducer;
