import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Grid, Typography } from '@mui/material';

import AbstractAudioPlayer from 'components/AudioPlayer/AbstractAudioPlayer';

import { Colors } from 'design/theme';

import { AudioLibraryDefault } from '../../redux/audio-profiles/types/audio-profiles.entity';

interface AudioSelectorProps {
  defaultAudioProfiles: AudioLibraryDefault[];
  onAudioProfileSelected: (selectedProfileName: string) => void;
}

export const AudioSelector: React.FC<AudioSelectorProps> = ({
  defaultAudioProfiles,
  onAudioProfileSelected
}) => {
  const isLargeScreen = useIsLargeScreen();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [playingUrl, setPlayingUrl] = useState<string | null>(null);

  const handleEvent = (audioID: string) => {
    setSelectedOption(audioID === selectedOption ? '' : audioID);
    onAudioProfileSelected(audioID === selectedOption ? '' : audioID);
  };

  const togglePlayPause = (url: string) => {
    setPlayingUrl(playingUrl === url ? null : url);
  };

  const buttonStyle = (value: string) => {
    const isSelected = selectedOption === value;
    const style: any = {
      height: isLargeScreen ? '7.625rem' : '9rem',
      width: '100%',
      background: isSelected ? Colors.green.light : Colors.white,
      borderRadius: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      color: Colors.green.main,
      border: `1px solid ${isSelected ? Colors.greenBlue : Colors.lightGrey}`,
      borderBottom: `0.5rem solid ${
        isSelected ? Colors.greenBlue : Colors.green.light
      }`,
      '&:hover': {
        background: Colors.green.light,
        color: Colors.greenBlue
      },
      '&:active': {
        boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
        background: Colors.green.main,
        color: Colors.green.dark,
        borderBottom: `0.5rem solid ${Colors.greenBlue}`
      }
    };
    return style;
  };

  return (
    <Grid container spacing={2}>
      {defaultAudioProfiles.map((audio) => (
        <Grid item xs={12} key={audio.id}>
          <Button
            sx={buttonStyle(audio.id)}
            onClick={() => handleEvent(audio.id)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: isLargeScreen ? 'column' : 'column',
                alignItems: isLargeScreen ? 'flex-start' : 'center',
                width: '100%',
                paddingLeft: isLargeScreen ? '5rem' : undefined
              }}>
              <Typography
                fontFamily='"Nunito"'
                fontSize="1rem"
                fontWeight={600}>
                {audio.profileName}
              </Typography>
              {isLargeScreen && (
                <Typography
                  fontFamily='"Nunito"'
                  fontSize="1rem"
                  fontWeight={400}
                  sx={{ marginTop: '0.15rem' }}>
                  {audio.profileDescription}
                </Typography>
              )}
              {isLargeScreen ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '3rem',
                    right: '5rem',
                    height: '1.5rem',
                    width: '1.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <AbstractAudioPlayer
                    url={audio.audioURL}
                    isPlaying={playingUrl === audio.audioURL}
                    onTogglePlay={() => togglePlayPause(audio.audioURL)}
                  />
                </Box>
              ) : (
                <Box>
                  <AbstractAudioPlayer
                    url={audio.audioURL}
                    isPlaying={playingUrl === audio.audioURL}
                    onTogglePlay={() => togglePlayPause(audio.audioURL)}
                  />
                </Box>
              )}
            </Box>
            {selectedOption === audio.id && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '0.75rem',
                  right: '0.75rem',
                  borderRadius: 9999,
                  backgroundColor: Colors.greenBlue,
                  height: '1.5rem',
                  width: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <CheckIcon sx={{ color: Colors.white, fontSize: '1rem' }} />
              </Box>
            )}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
