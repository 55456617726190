import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useRef, useState } from 'react';

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Box, IconButton, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import {
  Outcome,
  customOutcomes
} from 'redux/visualization/types/visualizations';

import OutcomeButtonVisualization from './OutcomeButtonVisualization';

interface CardGridProps {
  areasDevelopment: string[];
  typedOutcomes: OutcomesMapping;
  handleOutcomeClick: (outcome: Outcome, area: string) => void;
  activeOutcomeId: number | null;
  handleScrollDown: () => void;
}

interface OutcomesMapping {
  [area: string]: Outcome[];
}

export const CardGridVisualization: React.FC<CardGridProps> = ({
  areasDevelopment,
  typedOutcomes,
  handleOutcomeClick,
  activeOutcomeId,
  handleScrollDown
}) => {
  const isLargeScreen = useIsLargeScreen();
  const scrollRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [arrowLeftDisable, setArrowLeftDisable] = useState<{
    [key: string]: boolean;
  }>({});
  const [hasRightArrowClicked, setHasRightArrowClicked] = useState<{
    [key: string]: boolean;
  }>({});
  const [scrollPosition, setScrollPosition] = useState<{
    [key: string]: number;
  }>({});
  const [showRightArrow, setShowRightArrow] = useState<{
    [key: string]: boolean;
  }>({});

  const modifiedOutcomes = areasDevelopment.reduce((acc, area) => {
    const outcome = customOutcomes[area];
    if (outcome) {
      acc[area] = [outcome, ...(typedOutcomes[area] || [])];
    } else {
      acc[area] = typedOutcomes[area] || [];
    }
    return acc;
  }, {} as OutcomesMapping);

  const updateScrollPosition = (area: string) => {
    const element = scrollRefs.current[area];
    if (element) {
      setScrollPosition((prev) => ({ ...prev, [area]: element.scrollLeft }));
      setShowRightArrow((prev) => ({
        ...prev,
        [area]: element.scrollWidth > element.clientWidth
      }));
    }
  };

  const handleHorizontalScroll = (
    area: string,
    speed: number,
    distance: number,
    step: number
  ) => {
    const element = scrollRefs.current[area];
    if (!element) return;
    let scrollAmount = 0;
    const scrollInitialDistance = element.scrollLeft;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        element.scrollLeft =
          step > 0
            ? scrollInitialDistance + distance
            : scrollInitialDistance - distance;
        clearInterval(slideTimer);
      }
      setArrowLeftDisable((prev) => ({
        ...prev,
        [area]: element.scrollLeft === 0
      }));
    }, speed);
    if (element) {
      updateScrollPosition(area);
    }
    if (step > 0) {
      setHasRightArrowClicked((prev) => ({ ...prev, [area]: true }));
    }
  };

  useEffect(() => {
    areasDevelopment.forEach((area) => {
      const element = scrollRefs.current[area];
      if (!element) {
        setArrowLeftDisable((prev) => ({ ...prev, [area]: true }));
        return;
      }
      setArrowLeftDisable((prev) => ({
        ...prev,
        [area]: element.scrollWidth <= element.clientWidth
      }));
      setShowRightArrow((prev) => ({
        ...prev,
        [area]: element.scrollWidth > element.clientWidth
      }));
    });
  }, [areasDevelopment, typedOutcomes]);

  return (
    <Box sx={{ width: '100%' }}>
      {areasDevelopment.map((area, index) => {
        const areaOutcomes = modifiedOutcomes[area] || [];

        return (
          <Box
            key={area + index}
            sx={{
              marginBottom: '1.5rem',
              position: 'relative'
            }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '1.15rem',
                color: Colors.black
              }}>
              {area}
            </Typography>

            {!arrowLeftDisable[area] && hasRightArrowClicked[area] && (
              <Box>
                {isLargeScreen && (
                  <IconButton
                    onClick={() => handleHorizontalScroll(area, 1, 264, -6)}
                    sx={{
                      width: 'min-content',
                      height: 'min-content',
                      position: 'absolute',
                      zIndex: 2,
                      left: 0,
                      top: 80,
                      bottom: 0
                    }}>
                    <ArrowLeftIcon fontSize="large" />
                  </IconButton>
                )}
              </Box>
            )}

            <Box
              ref={(ref: HTMLDivElement | null) =>
                (scrollRefs.current[area] = ref)
              }
              onScroll={() => updateScrollPosition(area)}
              sx={{
                background: `linear-gradient(to right, ${Colors.shadowWhite}, rgba(255,255,255,0))`,
                display: 'flex',
                overflowX: 'auto',
                '::-webkit-scrollbar': {
                  display: 'none'
                },
                marginTop: '1rem',
                paddingLeft: '0rem'
              }}>
              {areaOutcomes.map((outcome) => (
                <OutcomeButtonVisualization
                  key={outcome.id}
                  outcome={outcome}
                  area={area}
                  activeOutcomeId={activeOutcomeId}
                  handleOutcomeClick={handleOutcomeClick}
                  scrollPosition={scrollPosition[area] || 0}
                  handleScrollDown={handleScrollDown}
                />
              ))}
            </Box>

            {isLargeScreen && showRightArrow[area] && (
              <IconButton
                onClick={() => handleHorizontalScroll(area, 1, 264, 6)}
                sx={{
                  width: 'min-content',
                  height: 'min-content',
                  position: 'absolute',
                  zIndex: 2,
                  right: 0,
                  top: 80,
                  bottom: 0
                }}>
                <ArrowRightIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default CardGridVisualization;
