import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { stripeSelector } from 'redux/stipeSubscription/stripe.selector';

import { mapStripeProductsToPlanOptions } from 'types/planMapping';

import BoxOption from './BoxOption';

interface PlaneChooseProps {
  onOptionChange: (option: string) => void;
  selectedOption: string;
}
export const PlansChoose = ({ onOptionChange }: PlaneChooseProps) => {
  const isLargeScreen = useIsLargeScreen();
  const { stripeProducts } = useAppSelector(stripeSelector);

  const [selectedOption, setSelectedOption] = useState<string>(
    'price_1P21vg03fQfQu0KrS4LcFBvw'
  );

  const planOptions = mapStripeProductsToPlanOptions(stripeProducts || []);

  useEffect(() => {
    onOptionChange(selectedOption);
  }, [onOptionChange, selectedOption]);
  return (
    <Box>
      <Typography
        id="modal-modal-description"
        sx={{ mt: '1.5rem' }}
        color={Colors.secondary.dark}
        fontFamily={'"Nunito"'}
        fontSize={isLargeScreen ? '1.25rem' : '1rem'}
        fontWeight={500}
        lineHeight="2rem"
        letterSpacing={'0.5px'}
        mb="1rem">
        Create personalized affirmations and visualizations based on your goals
        and intentions.
      </Typography>
      <Grid container spacing={{ xs: 1, sm: 2 }}>
        {planOptions.map((option) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={option.planId}
            onClick={() => {
              setSelectedOption(option.planId);
              onOptionChange(option.planId);
            }}>
            <BoxOption
              model={option.recurrency}
              label={option.label}
              price={option.price}
              currency={option.currency}
              isSelected={option.planId === selectedOption}
            />
          </Grid>
        ))}
      </Grid>

      <Typography
        mt="1rem"
        color="#576361"
        fontSize="0.875rem"
        fontWeight={500}>
        Your subscription will automatically renew. You have the freedom to
        cancel at any time.
      </Typography>
    </Box>
  );
};

export default PlansChoose;
