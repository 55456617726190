import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { ReactComponent as LogoText } from 'assets/images/iAffirm-text.svg';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Visibility } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { userSignUp } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';

import RoutesEnum from 'types/routes.enum';
import signupRequestType from 'types/signupRequest.type';

import { countries } from 'utils/countries';

const SignupPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { ui } = useSelector(userSelector);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const statusReview = false;
  const [invitationToken, setInvitationToken] = useState<string | undefined>(
    undefined
  );

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Name is requiered'),
    lastName: Yup.string().required('Lastname is requiered'),
    age: Yup.number().required('Age its requird'),
    country: Yup.string().required('Country its requird'),
    email: Yup.string().required('Email is required').min(7, 'Incorrect email'),
    password: Yup.string().required('Passowrd is required').min(4, '4'),
    termsAndConditions: Yup.boolean()
      .required()
      .oneOf([true], 'Please accept the terms and conditions')
  });

  const handleSubmit = async (values: signupRequestType) => {
    const signupData: signupRequestType = { ...values };

    if (invitationToken && invitationToken.trim() !== '') {
      signupData.invitationToken = invitationToken;
    }

    const actionResult = await dispatch(userSignUp(signupData));
    if (userSignUp.fulfilled.match(actionResult)) {
      navigate(RoutesEnum.ONBOARDING);
    }
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setInvitationToken(token);
    }
  }, []);

  const handleOpenTerms = () => {
    window.open(RoutesEnum.TERMS_CONDITIONS, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '35.375rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: '3.6875rem',
          borderRadius: '1.5rem'
        }}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            age: '',
            country: '',
            email: '',
            password: '',
            termsAndConditions: false
          }}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}>
          {(formik) => (
            <Form>
              <Box
                sx={{
                  height: '85vh',
                  maxHeight: '46.9375rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <Link to={RoutesEnum.HOME}>
                    <Box
                      display="flex"
                      columnGap={'1rem'}
                      minWidth={'min-content'}
                      alignItems="center"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => navigate(RoutesEnum.HOME)}>
                      <Logo />
                      <LogoText />
                    </Box>
                  </Link>
                  <Typography
                    mt="4.125rem"
                    fontSize="2rem"
                    fontWeight={500}
                    color={Colors.secondary.dark}>
                    Create your account
                  </Typography>
                  <Grid container spacing={2} mt="1rem">
                    <Grid item xs={6} mb="1rem">
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        id="firstName"
                        name="firstName"
                        placeholder="First name *"
                        autoComplete="firstName"
                        value={formik.values.firstName}
                        onChange={(e) =>
                          formik.setFieldValue('firstName', e.target.value)
                        }
                        error={!!formik.errors.firstName}
                        sx={{
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontWeight: '500',
                          color: Colors.brown.main
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} mb="1rem">
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        id="lastName"
                        name="lastName"
                        placeholder="Last name *"
                        autoComplete="lastName"
                        value={formik.values.lastName}
                        onChange={(e) =>
                          formik.setFieldValue('lastName', e.target.value)
                        }
                        error={!!formik.errors.lastName}
                        sx={{
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontWeight: '500',
                          color: Colors.brown.main
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} mb="1rem">
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        id="age"
                        name="Age"
                        placeholder="Age*"
                        autoComplete="Age"
                        value={formik.values.age}
                        onChange={(e) =>
                          formik.setFieldValue('age', e.target.value)
                        }
                        error={!!formik.errors.age}
                        sx={{
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontWeight: '500',
                          color: Colors.brown.main
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} mb="1rem">
                      <FormControl fullWidth>
                        <InputLabel id="country-selector-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-selector-label"
                          id="country"
                          name="country"
                          label="country"
                          value={formik.values.country}
                          onChange={(e) =>
                            formik.setFieldValue('country', e.target.value)
                          }
                          error={!!formik.errors.country}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}>
                          {countries.map((country) => (
                            <MenuItem key={country.label} value={country.label}>
                              {country.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} mb="1rem">
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Email *"
                        autoComplete="email"
                        type="email"
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue('email', e.target.value)
                        }
                        error={!!formik.errors.email}
                        sx={{
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontWeight: '500',
                          color: Colors.brown.main
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} mb="1rem">
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        placeholder="Password *"
                        name="password"
                        type={!showPassword ? 'password' : ''}
                        id="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={(e) =>
                          formik.setFieldValue('password', e.target.value)
                        }
                        error={!!formik.errors.password}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onMouseEnter={() => setShowPassword(true)}
                              onMouseLeave={() => setShowPassword(false)}>
                              <Visibility />
                            </IconButton>
                          )
                        }}
                        sx={{
                          fontFamily: '"Nunito"',
                          fontSize: '1rem',
                          lineHeight: '1.5rem',
                          fontWeight: '500',
                          color: Colors.brown.main
                        }}
                      />
                    </Grid>
                    <Box ml={2}>
                      {!!formik.errors.email ||
                        (!!formik.errors.password && (
                          <Typography
                            color="error"
                            variant="body1"
                            fontFamily='"Nunito"'
                            fontWeight={500}
                            fontSize={'0.75rem'}
                            lineHeight={'1rem'}>
                            Email or password incorrect
                          </Typography>
                        ))}
                      {ui.signupError && (
                        <Typography
                          color="error"
                          variant="body1"
                          fontWeight={500}
                          fontSize={'0.875rem'}
                          lineHeight={'0.625rem'}>
                          {ui.signupError}
                        </Typography>
                      )}
                      {formik.errors.termsAndConditions && (
                        <Typography
                          color="error"
                          variant="body1"
                          fontWeight={500}
                          fontSize={'0.875rem'}
                          lineHeight={'0.625rem'}>
                          {formik.errors.termsAndConditions}
                        </Typography>
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="remember"
                            color="secondary"
                            size="small"
                          />
                        }
                        value={formik.values.termsAndConditions}
                        onChange={(_, value) =>
                          formik.setFieldValue('termsAndConditions', value)
                        }
                        label={
                          <Button
                            onClick={() => {
                              handleOpenTerms();
                            }}>
                            <Typography
                              color="secondary"
                              sx={{
                                fontFamily: '"Nunito"',
                                fontSize: '0.875rem',
                                fontWeight: '400'
                              }}>
                              I agree to terms and conditions
                            </Typography>
                          </Button>
                        }
                      />
                    </Box>
                  </Grid>
                </Box>

                <Box>
                  <Button
                    color="secondary"
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: '1rem',
                      minHeight: '3.25rem',
                      textTransform: 'none',
                      fontFamily: '"Nunito"',
                      lineHeight: '1.25rem',
                      letterSpacing: '0.005rem',
                      fontWeight: '500',
                      color: Colors.white
                    }}>
                    {statusReview ? <CircularLogoLoader /> : 'Sign up'}
                  </Button>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={3}
                    mt={1}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      color={Colors.secondary.dark}>
                      I already have and account.
                    </Typography>
                    <Link
                      to={'/login'}
                      style={{ textDecoration: 'none', marginLeft: '-1rem' }}>
                      <Typography
                        color="secondary"
                        fontFamily='"Nunito"'
                        fontSize={'1rem'}>
                        Login
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default SignupPage;
