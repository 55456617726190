import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { ModalUserProfile } from 'components/ModalUserProfile';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  cleanCustomFlow,
  initialStateNewAffirmationUiState,
  setActualStep,
  setCustomFlow,
  setHomeStatus,
  setLastStep
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { getAffiliationStatus } from 'redux/affiliation/affiliations.action';
import { getMorningAffirmations } from 'redux/affirmation/affirmation.action';
import { resetAffirmationState } from 'redux/affirmation/afirmation.slice';
import {
  cleanMeditation,
  setActualStepMeditation
} from 'redux/meditations/meditation.slice';
import { subscriptionSelector } from 'redux/subscription/subscription.selector';
import { userHome } from 'redux/user/user.action';
import { resetUserHome, setUserProfileModal } from 'redux/user/user.slice';
import { getOutcomesVisualization } from 'redux/visualization/visualization.action';
import { cleanVisualization } from 'redux/visualization/visualization.slice';

import RoutesEnum from 'types/routes.enum';

import { AffiliatesModal } from '../../affiliates-program/AffiliatesModal';
import { CatalogHome } from './CatalogHome';
import UserHome from './UserHome';

export const UserHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get('defaultTab') as 'home' | 'createdByMe';
  const [offset, setOffset] = useState<number>(0);
  const { wantWorkOn } = useAppSelector(newAffirmationUISelector);
  const userCredits = useAppSelector(subscriptionSelector);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [activeTab, setActiveTab] = useState<'home' | 'createdByMe'>(
    defaultTab || 'home'
  );

  const handleHomeClick = () => {
    setActiveTab('home');
  };

  const handleCreatedByMeClick = () => {
    dispatch(resetUserHome());
    setOffset(0);
    dispatch(getMorningAffirmations());
    dispatch(userHome(0));
    setActiveTab('createdByMe');
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    if (
      (userCredits.credits && userCredits.credits > 0) ||
      userCredits.subscriptionType?.toLocaleLowerCase() === 'premium'
    ) {
      switch (selectedOption) {
        case 'Affirmations':
          handleClose();
          dispatch(resetAffirmationState());
          dispatch(
            setLastStep(
              initialStateNewAffirmationUiState.lastStep + wantWorkOn.length
            )
          );
          dispatch(setCustomFlow(false));
          dispatch(setHomeStatus(false));
          dispatch(cleanCustomFlow());
          dispatch(setActualStep(1));
          navigate(RoutesEnum.NEW_AFFIRMATION);
          break;
        case 'Visualizations':
          handleClose();
          dispatch(cleanVisualization());
          dispatch(getOutcomesVisualization());
          navigate(RoutesEnum.VISUALIZATION);
          break;
        case 'Guided Meditations':
          handleClose();
          dispatch(cleanMeditation());
          dispatch(setActualStepMeditation(1));
          navigate(RoutesEnum.NEW_MEDITATION);
      }
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(userHome(offset));
    dispatch(setUserProfileModal(false));
    dispatch(getAffiliationStatus());
  }, [dispatch, offset]);

  return (
    <Container
      sx={{
        margin: 'auto',
        marginTop: '3rem',
        justifyContent: 'center',
        pb: '2rem'
      }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="2rem"
        mb="2rem">
        <Typography
          fontWeight={500}
          fontFamily='"Nunito"'
          fontSize="1.75rem"
          lineHeight={'normal'}
          color={Colors.bgGreen}>
          My hub
        </Typography>
      </Box>
      <Grid
        container
        spacing={1}
        sx={{ borderBottom: `1px solid ${Colors.grey}` }}>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === 'createdByMe'
                  ? `2px solid ${Colors.black}`
                  : undefined,
              borderRadius: '0',
              color:
                activeTab === 'createdByMe' ? Colors.black : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={handleCreatedByMeClick}>
            Created by me
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.15rem',
              borderBottom:
                activeTab === 'home' ? `2px solid ${Colors.black}` : undefined,
              borderRadius: '0',
              color: activeTab === 'home' ? Colors.black : Colors.neutral,
              fontWeight: '500'
            }}
            onClick={handleHomeClick}>
            Hub
          </Button>
        </Grid>
      </Grid>
      <Container
        sx={{
          marginTop: '1.5rem'
        }}>
        {activeTab === 'home' && (
          <CatalogHome onOptionSelect={setSelectedOption} />
        )}
        {activeTab === 'createdByMe' && (
          <UserHome offset={offset} setOffset={setOffset} />
        )}
      </Container>
      {activeTab === 'home' && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem'
          }}>
          <Button
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            disabled={!selectedOption}>
            <Add fontSize="small" /> Create new
          </Button>
        </Box>
      )}
      <ModalUserProfile />
      <AffiliatesModal />
    </Container>
  );
};

export default UserHub;
