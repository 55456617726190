import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import {
  createMeditation,
  generateMeditation
} from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';

import RoutesEnum from 'types/routes.enum';

import { ReactComponent as CloseTag } from '../../../assets/images/CloseSquare.svg';
import NewMeditationCustom from '../NewMeditationCustom';
import StepsView from '../StepsView';
import AudioDuration from '../components/1_AudioDuration';
import ImprovementAreaMeditation from '../components/2_SelectOutcome';
import SelectQuality from '../components/3_SelectQuality';
import GoalMeditation from '../components/4_SelectGoal';
import AttributeMeditation from '../components/5_SelectAttribute';

export const NewMeditation: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const hasNavigated = useRef(false);
  const { createdMeditation } = useAppSelector(medidationSelector);
  const [loading, setLoading] = useState(false);

  const {
    customFlow,
    actualStep,
    areaSelected,
    selectedOutcome,
    selectedGoal,
    selectedQuality,
    selectedAttribute
  } = useAppSelector(medidationSelector).newMeditation;

  useEffect(() => {
    if (
      areaSelected &&
      selectedOutcome &&
      selectedQuality &&
      selectedAttribute &&
      selectedGoal
    ) {
      setLoading(true);
      dispatch(
        createMeditation({
          outcome: selectedOutcome,
          quality: selectedQuality,
          goal: selectedGoal,
          attribute: selectedAttribute
        })
      )
        .unwrap()
        .catch((error) => {
          console.error('Error creating meditation:', error);
        });
    }
  }, [
    areaSelected,
    selectedOutcome,
    selectedQuality,
    selectedAttribute,
    selectedGoal,
    dispatch
  ]);

  useEffect(() => {
    if (createdMeditation && !hasNavigated.current) {
      hasNavigated.current = true;
      dispatch(generateMeditation({ meditationId: createdMeditation.id }))
        .unwrap()
        .then(() => {
          setLoading(false);
          navigate(`${RoutesEnum.EDIT_MEDITATION}${createdMeditation.id}`);
        })
        .catch((error) => {
          setLoading(false);
          hasNavigated.current = false;
        });
    }
  }, [createdMeditation, dispatch, navigate]);

  if (loading) {
    return (
      <Container
        sx={{
          maxWidth: isLargeScreen ? '80%' : '100%',
          height: '75vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CircularLogoLoader />
          <Typography
            sx={{
              fontFamily: '"Nunito"',
              fontSize: '1.25rem',
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: Colors.secondary.dark
            }}>
            {"We're creating your Mediation"}
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container
      sx={{ maxWidth: '80%', marginTop: '3rem', justifyContent: 'center' }}>
      <Grid container>
        <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 28,
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: 'secondary.dark'
            }}>
            {'New Meditation'}
          </Typography>
        </Grid>
        <Grid item xs={0.5}>
          <Button
            sx={{ padding: 0, minWidth: 0, backgroundColor: 'none' }}
            onClick={() => {
              navigate(RoutesEnum.USER_HOME);
            }}>
            <Box sx={{ width: 24, height: 24 }}>
              <CloseTag />
            </Box>
          </Button>
        </Grid>
      </Grid>
      <StepsView />
      {actualStep === 1 && !customFlow && <AudioDuration />}
      {actualStep === 2 && !customFlow && <ImprovementAreaMeditation />}
      {actualStep === 3 && !customFlow && <SelectQuality />}
      {actualStep === 4 && !customFlow && <GoalMeditation />}
      {actualStep === 5 && !customFlow && <AttributeMeditation />}
      {customFlow && <NewMeditationCustom />}
    </Container>
  );
};

export default NewMeditation;
