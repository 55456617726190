import { SpeechParameters } from 'redux/affirmation/types/affirmation.entity';
import {
  CreateMeditation,
  GetMeditation,
  GetPDFMeditation
} from 'redux/meditations/types/meditation.entity';

import { api, baseURL } from './instance';

const _meditationService = {
  meditationOutcomes: async () => {
    const response = await api.get(`${baseURL}/guided-meditation/outcomes`);
    return response.data;
  },
  qualitiesByOutcome: async (id: number) => {
    const response = await api.get(
      `${baseURL}/guided-meditation/outcome-qualities/${id}`
    );
    return response.data;
  },
  getGoalAttributes: async (id: number) => {
    const response = await api.get(
      `${baseURL}/guided-meditation/goal-attribute/${id}`
    );

    return response.data;
  },
  createMeditation: async (data: CreateMeditation) => {
    const response = await api.post(`${baseURL}/guided-meditation/`, data);

    return response.data;
  },
  generateMeditation: async (data: GetMeditation) => {
    const response = await api.post(
      `${baseURL}/guided-meditation/generate-meditation`,
      data
    );

    return response.data;
  },
  generatePDFMeditation: async (data: GetPDFMeditation) => {
    const response = await api.post(
      `${baseURL}/guided-meditation/generate-pdf-meditation`,
      data
    );

    return response.data;
  },
  deleteMeditation: async (id: string) => {
    const response = await api.delete(
      `${baseURL}/guided-meditation/delete-meditation/${id}`
    );

    return response;
  },
  generateSpeechMeditation: async (data: SpeechParameters) => {
    const response = await api.post(
      `${baseURL}/guided-meditation/speech-meditation`,
      data
    );
    return response.data;
  }
};

export default _meditationService;
