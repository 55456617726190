import HoldingImage from 'assets/images/home/Holding_background_image.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';

import { WaitingListModal } from 'components/WaitingListModal';

import { Colors } from 'design/theme';

export const Holding: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const isTablet = useMediaQuery('(min-width: 600px)');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        minWidth: '100%',
        minHeight: 'calc(100vh - 4rem) ',
        backgroundImage: `url(${HoldingImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        flexDirection: 'column',
        padding: '0 4rem ',
        gap: '3rem'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(269.73deg, ${Colors.bgGreen} 0%, ${Colors.bgGreen} 65%, ${Colors.HoldingGreen} 100%)`,
          opacity: 0.22,
          zIndex: 1
        }}
      />
      <Typography
        fontFamily='"Exo 2"'
        fontWeight={700}
        alignSelf={'flex-start'}
        lineHeight={isLargeScreen ? '4.5rem' : '3rem'}
        maxWidth={isLargeScreen ? '40%' : '100%'}
        sx={{
          zIndex: 2,
          color: Colors.white,
          fontSize: isTablet ? '4.5rem' : '3rem',
          textAlign: isLargeScreen ? 'left' : 'center',
          textShadow: isLargeScreen
            ? '1px 3px 3px rgba(0, 0, 0, 0.33)'
            : '1px 3px 3px rgba(0, 0, 0, 0.73)'
        }}>
        Awaken Your Inner You
      </Typography>
      <Box
        sx={{
          bottom: 0,
          right: 0,
          zIndex: 2,
          borderRadius: '8px',
          padding: '0 0 6rem 0 ',
          display: 'flex',
          flexDirection: isLargeScreen ? 'row' : 'column',
          width: '100%',
          justifyContent: 'space-between',
          gap: '1rem'
        }}>
        <Grid container>
          <Grid item xl={6} xs={12}>
            <Typography
              fontFamily='"Exo 2"'
              fontSize={isLargeScreen ? '1.8rem' : '1.3rem'}
              fontWeight={500}
              mb={4}
              sx={{
                color: Colors.white,
                textAlign: isLargeScreen ? undefined : 'center',
                marginBottom: 0,
                textTransform: 'uppercase',
                textShadow: '1px 3px 3px rgba(0, 0, 0, 0.83)'
              }}>
              SOMETHING INSPIRATIONAL IS COMING...
            </Typography>
            <Typography
              fontFamily='"Exo 2"'
              fontSize={isLargeScreen ? '1.8rem' : '1.3rem'}
              fontWeight={500}
              sx={{
                color: Colors.white,
                textAlign: isLargeScreen ? undefined : 'center',
                marginBottom: isLargeScreen ? undefined : '1rem',
                textTransform: 'uppercase',
                textShadow: '1px 3px 3px rgba(0, 0, 0, 0.83)'
              }}>
              YOUR SPACE FOR OPTIMISING YOUR LIFE&apos;S JOURNEY IS NEAR
            </Typography>
          </Grid>
          <Grid
            item
            xl={6}
            xs={12}
            alignItems={'center'}
            sx={{
              marginTop: '1rem',
              display: 'flex',
              justifyContent: isLargeScreen ? 'flex-end' : 'center',
              width: '100%'
            }}>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  minHeight: '3.4rem',
                  fontWeight: '300',
                  fontFamily: '"Exo 2"',
                  fontSize: isLargeScreen ? '1.3rem' : '1rem',
                  marginTop: '1rem',
                  gap: '0.5rem',
                  padding: '0.2rem 2rem',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem'
                }}
                onClick={toggleModal}>
                Join the early access list
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Waiting List */}

      <WaitingListModal open={isModalOpen} handleClose={toggleModal} />
    </Box>
  );
};
