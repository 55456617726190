import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

interface TrackerProps {
  title: string;
  index: number;
  completeSection: boolean;
}
const AboutMeProfileSectionsTitle: React.FC<TrackerProps> = ({
  title,
  index,
  completeSection
}) => {
  return (
    <Grid item sx={{ marginBottom: '0.5rem' }} xs={12}>
      <Typography
        variant="h5"
        fontSize={'0.8rem'}
        fontFamily={'"Nunito"'}
        fontWeight={400}
        lineHeight={'1rem'}
        letterSpacing={'0.25px'}
        sx={{
          color: !completeSection
            ? `${Colors.neutralGrey}`
            : `${Colors.secondary.main}`,
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '0.8rem',
          margin: '0.5rem 0'
        }}>
        {completeSection ? (
          <DoneIcon fontSize={'small'} />
        ) : (
          <DoneIcon fontSize={'small'} sx={{ color: Colors.white }} />
        )}
        {`${index + 1}. ${title}`}
      </Typography>
    </Grid>
  );
};

export default AboutMeProfileSectionsTitle;
