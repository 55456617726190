import { ReactComponent as CloseTag } from 'assets/images/Close_Button.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Modal, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { getProfile } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';
import { setUserProfileModal } from 'redux/user/user.slice';

import { UserSubscription } from 'types/roles.enum';
import RoutesEnum from 'types/routes.enum';

import { AboutMeProfile } from './AboutMeProfile';
import { SubscriptionHandler } from './SubscriptionHandler';
import { UserProfile } from './UserProfile';

enum defaultTabs {
  ABOUT_ME = 'About me',
  ACCOUNT = 'Account',
  SUBSCRIPTION = 'Subscription'
}
export const ModalUserProfile: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { userProfileModal } = useAppSelector(userSelector);
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const queryParams = new URLSearchParams(location.search);
  const defaultTab = queryParams.get('defaultTab') as
    | defaultTabs.ABOUT_ME
    | defaultTabs.ACCOUNT
    | defaultTabs.SUBSCRIPTION;
  const [activeTab, setActiveTab] = useState<
    defaultTabs.ABOUT_ME | defaultTabs.ACCOUNT | defaultTabs.SUBSCRIPTION
  >(defaultTab || defaultTabs.ACCOUNT);

  const handleCloseModal = () => {
    dispatch(setUserProfileModal(false));
    navigate(RoutesEnum.USER_HOME);
  };

  const handleTabClick =
    (tab: defaultTabs) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setActiveTab(tab);
    };

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);
  return (
    <Modal
      open={userProfileModal}
      onClose={handleCloseModal}
      aria-labelledby="user-profile-modal-title"
      aria-describedby="user-profile-modal-description">
      <Box sx={isLargeScreen ? style : styleMobile}>
        <Box
          sx={{
            padding: '0',
            margin: '0',
            backgroundColor: Colors.grey,
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignContent: 'space-between'
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'space-between'
            }}>
            <Typography
              fontFamily={'"Nunito"'}
              fontWeight={500}
              fontSize={'1.15rem'}
              lineHeight={'1.5rem'}
              letterSpacing={'0.15px'}
              sx={{ padding: '1.25rem', color: Colors.secondary.dark }}>
              Profile
            </Typography>
            <Button
              onClick={handleCloseModal}
              sx={{
                padding: '1.25rem',
                minWidth: 'auto'
              }}>
              <CloseTag />
            </Button>
          </Box>
          <Grid
            container
            gap={1}
            sx={{
              width: '100%',
              borderBottom: `1px solid ${Colors.grey}`,
              paddingLeft: '0.85rem'
            }}>
            <Grid item>
              <Button
                sx={{
                  fontFamily: '"Nunito"',
                  fontSize: '1.1rem',
                  borderBottom:
                    activeTab === 'About me'
                      ? `1.5px solid ${Colors.black}`
                      : undefined,
                  borderRadius: '0',
                  color:
                    activeTab === 'About me' ? Colors.black : Colors.neutral,
                  fontWeight: '500',
                  lineHeight: '2rem'
                }}
                onClick={handleTabClick(defaultTabs.ABOUT_ME)}>
                About me
              </Button>
            </Grid>
            <Grid item>
              <Button
                sx={{
                  fontFamily: '"Nunito"',
                  fontSize: '1.1rem',
                  borderBottom:
                    activeTab === 'Account'
                      ? `1.5px solid ${Colors.black}`
                      : undefined,
                  borderRadius: '0',
                  color:
                    activeTab === 'Account' ? Colors.black : Colors.neutral,
                  fontWeight: '500'
                }}
                onClick={handleTabClick(defaultTabs.ACCOUNT)}>
                Account
              </Button>
            </Grid>
            {userInfo?.subscription === UserSubscription.PREMIUM && (
              <Grid item>
                <Button
                  sx={{
                    fontFamily: '"Nunito"',
                    fontSize: '1.1rem',
                    borderBottom:
                      activeTab === defaultTabs.SUBSCRIPTION
                        ? `1.5px solid ${Colors.black}`
                        : undefined,
                    borderRadius: '0',
                    color:
                      activeTab === defaultTabs.SUBSCRIPTION
                        ? Colors.black
                        : Colors.neutral,
                    fontWeight: '500'
                  }}
                  onClick={handleTabClick(defaultTabs.SUBSCRIPTION)}>
                  Subscription
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box
          sx={{
            width: isLargeScreen ? '100%' : '90%',
            height: isLargeScreen ? '100%' : '90%',
            minHeight: isLargeScreen ? '35rem' : undefined,
            overflow: 'auto',
            marginTop: '0.5rem'
          }}>
          {activeTab === 'About me' && <AboutMeProfile />}
          {activeTab === 'Account' && <UserProfile />}
          {activeTab === 'Subscription' && userInfo && <SubscriptionHandler />}
        </Box>
      </Box>
    </Modal>
  );
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  background: Colors.white,
  p: 4,
  padding: '0',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  minHeight: '20rem',
  maxHeight: '90vh'
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxHeight: '50rem',
  background: Colors.white,
  boxShadow: 24,
  p: 4,
  borderRadius: '0.5rem',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100vw'
};
