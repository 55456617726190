import {
  AudioLibraryDefault,
  CreateAudioProfileDto,
  CreatedAudioProfile,
  DefaultProfileDTO,
  GetDeafaultUrlParams,
  UserAudioLibrary
} from 'redux/audio-profiles/types/audio-profiles.entity';

import { IApiResponse } from 'types/apiResponse.interface';

import { api, baseURL } from './instance';

const audioProfilesService = {
  createAudioProfile: (createAudioProfileDto: CreateAudioProfileDto) => {
    return api.post<IApiResponse<CreatedAudioProfile>>(
      `${baseURL}/audio-profiles`,
      createAudioProfileDto
    );
  },
  getDefaultAudioProfiles: () => {
    return api.get<IApiResponse<AudioLibraryDefault[]>>(
      `${baseURL}/audio-profiles/audio-profile-onboarding`
    );
  },
  getAudioProfiles: () => {
    return api.get<IApiResponse<Partial<UserAudioLibrary>[]>>(
      `${baseURL}/audio-profiles/user-profiles`
    );
  },
  deleteUserAudioProfile: (id: string) => {
    return api.delete(`${baseURL}/audio-profiles/delete-user-profile`, {
      data: { id }
    });
  },
  audioDefaultPreference: (createAudioProfileDto: DefaultProfileDTO) => {
    return api.post(
      `${baseURL}/audio-profiles/audio-preferences`,
      createAudioProfileDto
    );
  },
  testAudio: (params: GetDeafaultUrlParams) => {
    return api.post<IApiResponse<string>>(
      `${baseURL}/audio-profiles/test-audios`,
      params
    );
  },
  getAdminAudioProfiles: () => {
    return api.get<IApiResponse<AudioLibraryDefault[]>>(
      `${baseURL}/admin-area/published-audio-profiles`
    );
  }
};

export default audioProfilesService;
