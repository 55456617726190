import React from 'react';
import { TopItemDto } from 'services/affirmation-templates';

import { Button, Typography } from '@mui/material';

import { Colors } from 'design/theme';

interface TrendingButtonProps {
  template: TopItemDto;
  area: string;
  activeTrendingId: number | string | null;
  handleTemplateClick: (template: TopItemDto, area: string) => void;
  scrollPosition: number;
  handleScrollDown: () => void;
}

export const TrendingButton: React.FC<TrendingButtonProps> = ({
  template,
  area,
  activeTrendingId,
  handleTemplateClick,
  scrollPosition,
  handleScrollDown
}) => {
  return (
    <Button
      key={template.id}
      variant="outlined"
      sx={{
        position: 'relative',
        marginRight: '1rem',
        flexShrink: 0,
        minWidth: '16.5rem',
        maxWidth: '16.5rem',
        minHeight: '7rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: '"Nunito"',
        fontSize: '1rem',
        color: Colors.black,
        border:
          activeTrendingId === template.id
            ? `2px solid ${Colors.green.main}`
            : `1px solid ${Colors.lightGrey}`,
        background:
          activeTrendingId === template.id
            ? Colors.green.light
            : `linear-gradient(222deg, ${Colors.white} 50.81%, rgba(255, 255, 255, 0) 98.65%)`,
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
          border: `2px solid ${Colors.green.main}`,
          background: Colors.green.light,
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0)'
        },
        '&:active': {
          border: `2px solid ${Colors.green.main}`,
          background: Colors.green.light,
          boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)'
        },
        '&::before, &::after': {
          background: `linear-gradient(to right, ${Colors.shadowWhite}, rgba(255,255,255,0))`,
          display: scrollPosition > 0 ? 'block' : 'none'
        }
      }}
      onClick={() => {
        handleTemplateClick(template, area);
        handleScrollDown();
      }}>
      <Typography
        sx={{
          textAlign: 'center',
          zIndex: 1
        }}>
        {template.name}
      </Typography>
    </Button>
  );
};
