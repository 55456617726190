import { ReactComponent as Logo } from 'assets/images/iAffirm-logo.svg';
import { useNavigate } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Container, IconButton, Typography } from '@mui/material';

import RoutesEnum from 'types/routes.enum';

const TermsAndConditions = () => {
  const navigate = useNavigate();

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '0.5rem 0 0.5rem 1rem',
          justifyContent: 'flex-start',
          gap: '1rem',
          boxShadow: '0 10px 30px rgba(0,0,0,0.2)'
        }}>
        <IconButton onClick={() => navigate(RoutesEnum.HOME)}>
          <ArrowBackIosNewIcon color="secondary" />
        </IconButton>

        <Box>
          <Logo />
        </Box>
        <Typography
          color="secondary"
          sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
          Terms and Conditions
        </Typography>
      </Box>
      <Container sx={{ marginTop: '1rem', marginBottom: '3rem' }}>
        <Box>
          <Typography variant="h4" color="secondary">
            General Terms of Usage Policy of the iAffirm Website
          </Typography>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">1. Introduction</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                1.1 Purpose
              </Typography>
              <Typography variant="body1" ml={4}>
                General Terms of Usage (&quot;Terms&quot;) govern your access to
                and use of the iAffirm website (&quot;Website&quot;) and its
                services (&quot;Services&quot;). By accessing or using the
                Website, you agree to be bound by these Terms. If you do not
                agree to these Terms, you must not use the Website.
              </Typography>
              <Typography variant="body1" ml={2}>
                1.2 Scope
              </Typography>
              <Typography variant="body1" ml={4}>
                These Terms apply to all visitors, users, and others who access
                or use the Website.
              </Typography>
              <Typography variant="body1" ml={2}>
                1.3 Definitions
              </Typography>
              <Typography variant="body1" ml={4}>
                &quot;iAffirm&quot; refers to iAffirm Ltd., a company registered
                in England and Wales. &quot;User&quot; refers to any individual
                or entity accessing or using the Website. &quot;Content&quot;
                refers to any text, graphics, images, software, audio, video,
                information, or other materials available on the Website.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">2. Use of the Website</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                2.1 Eligibility
              </Typography>
              <Typography variant="body1" ml={4}>
                To use the Website, you must be at least 18 years old. By using
                the Website, you represent and warrant that you meet this age
                requirement.
              </Typography>
              <Typography variant="body1" ml={2}>
                2.2 Account Registration
              </Typography>
              <Typography variant="body1" ml={4}>
                Some features of the Website may require you to create an
                account. You agree to provide accurate, current, and complete
                information during the registration process and to update such
                information to keep it accurate, current, and complete. You are
                responsible for maintaining the confidentiality of your account
                login information and for all activities that occur under your
                account.
              </Typography>
              <Typography variant="body1" ml={2}>
                2.3 User Conduct
              </Typography>
              <Typography variant="body1" ml={4}>
                You agree not to use the Website to:
                <ul>
                  <li>Engage in any unlawful activities.</li>
                  <li>
                    Post or share any content that is illegal, harmful,
                    threatening, abusive, harassing, defamatory, vulgar,
                    obscene, or otherwise objectionable.
                  </li>
                  <li>
                    Infringe upon the intellectual property or other rights of
                    any third party.
                  </li>
                  <li>Distribute spam or other unsolicited messages.</li>
                  <li>
                    Upload or transmit any viruses, malware, or other harmful
                    code.
                  </li>
                </ul>
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">3. Content</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                3.1 Ownership
              </Typography>
              <Typography variant="body1" ml={4}>
                All Content provided on the Website is owned by iAffirm or its
                licensors. You may not copy, modify, distribute, sell, or lease
                any part of the Content without iAffirm&apos;s prior written
                consent.
              </Typography>
              <Typography variant="body1" ml={2}>
                3.2 User-Generated Content
              </Typography>
              <Typography variant="body1" ml={4}>
                If you submit or post any content on the Website (&quot;User
                Content&quot;), you grant iAffirm a worldwide, non-exclusive,
                royalty-free, sublicensable, and transferable license to use,
                reproduce, distribute, prepare derivative works of, display, and
                perform the User Content in connection with the Website and
                iAffirm&apos;s business.
              </Typography>
              <Typography variant="body1" ml={2}>
                3.3 Prohibited Content
              </Typography>
              <Typography variant="body1" ml={4}>
                User Content must not:
                <ul>
                  <li>Violate any applicable laws or regulations.</li>
                  <li>
                    Infringe on any third-party rights, including intellectual
                    property rights.
                  </li>
                  <li>
                    Contain any false, misleading, or deceptive statements.
                  </li>
                  <li>
                    Be defamatory, obscene, offensive, or otherwise
                    objectionable.
                  </li>
                </ul>
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">4. Privacy and Data Protection</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                4.1 Privacy Policy
              </Typography>
              <Typography variant="body1" ml={4}>
                Your use of the Website is also governed by our Privacy Policy,
                which is incorporated into these Terms by reference. Please
                review our Privacy Policy to understand our practices regarding
                the collection, use, and disclosure of your personal data.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              5. Intellectual Property Rights
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                5.1 Trademarks
              </Typography>
              <Typography variant="body1" ml={4}>
                The iAffirm name, logo, and all related names, logos, product
                and service names, designs, and slogans are trademarks of
                iAffirm or its affiliates or licensors. You must not use such
                marks without the prior written permission of iAffirm.
              </Typography>
              <Typography variant="body1" ml={2}>
                5.2 Copyright
              </Typography>
              <Typography variant="body1" ml={4}>
                All Content on the Website is protected by copyright laws.
                Unauthorized use of the Content may violate copyright,
                trademark, and other laws.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              6. Disclaimers and Limitation of Liability
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                6.1 Disclaimer of Warranties
              </Typography>
              <Typography variant="body1" ml={4}>
                The Website and Content are provided &quot;as is&quot; and
                &quot;as available&quot; without any warranties of any kind,
                either express or implied. iAffirm disclaims all warranties,
                including but not limited to, implied warranties of
                merchantability, fitness for a particular purpose, title, and
                non-infringement.
              </Typography>
              <Typography variant="body1" ml={2}>
                6.2 Limination of Liability
              </Typography>
              <Typography variant="body1" ml={4}>
                To the maximum extent permitted by law, iAffirm shall not be
                liable for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of profits or revenues, whether
                incurred directly or indirectly, or any loss of data, use,
                goodwill, or other intangible losses, resulting from (i) your
                use or inability to use the Website; (ii) any unauthorized
                access to or use of our servers and/or any personal information
                stored therein; (iii) any interruption or cessation of
                transmission to or from the Website; (iv) any bugs, viruses,
                trojan horses, or the like that may be transmitted to or through
                the Website by any third party; (v) any errors or omissions in
                any content or for any loss or damage of any kind incurred as a
                result of your use of any content posted, emailed, transmitted,
                or otherwise made available via the Website, whether based on
                warranty, contract, tort (including negligence), or any other
                legal theory, whether or not iAffirm has been informed of the
                possibility of such damage.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">7. Indemnification</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                You agree to indemnify, defend, and hold harmless iAffirm, its
                affiliates, officers, directors, employees, and agents from and
                against any claims, liabilities, damages, losses, and expenses,
                including without limitation reasonable legal and accounting
                fees, arising out of or in any way connected with your access to
                or use of the Website or your violation of these Terms.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">8. Termination</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                8.1 Termination by User
              </Typography>
              <Typography variant="body1" ml={4}>
                You may terminate your account at any time by following the
                instructions provided on the Website. Upon termination, you must
                cease all use of the Website.
              </Typography>
              <Typography variant="body1" ml={2}>
                8.2 Termination by iAffirm
              </Typography>
              <Typography variant="body1" ml={4}>
                iAffirm may terminate or suspend your access to the Website at
                any time, with or without cause, with or without notice,
                effective immediately.
              </Typography>
              <Typography variant="body1" ml={2}>
                8.3 Effects of Termination
              </Typography>
              <Typography variant="body1" ml={4}>
                Upon termination, your right to use the Website will immediately
                cease. All provisions of these Terms which by their nature
                should survive termination shall survive termination, including,
                without limitation, ownership provisions, warranty disclaimers,
                indemnity, and limitations of liability.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              9. Governing Law and Dispute Resolution
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                9.1 Governing Law
              </Typography>
              <Typography variant="body1" ml={4}>
                These Terms shall be governed by and construed in accordance
                with the laws of England and Wales, without regard to its
                conflict of law principles.
              </Typography>
              <Typography variant="body1" ml={2}>
                9.2 Dispute Resolution
              </Typography>
              <Typography variant="body1" ml={4}>
                Any disputes arising out of or in connection with these Terms
                shall be resolved through good faith negotiations between the
                parties. If the dispute cannot be resolved through negotiations,
                it shall be submitted to mediation or arbitration, as mutually
                agreed by the parties.
              </Typography>
              <Typography variant="body1" ml={2}>
                9.3 Jurisdiction
              </Typography>
              <Typography variant="body1" ml={4}>
                The parties agree that the courts of England and Wales shall
                have exclusive jurisdiction to hear and determine any disputes
                arising out of or in connection with these Terms.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">10. Changes to the Terms</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                10.1 Amendment of Terms
              </Typography>
              <Typography variant="body1" ml={4}>
                iAffirm reserves the right to amend these Terms at any time. Any
                changes will be posted on the Website and will take effect
                immediately upon posting.
              </Typography>
              <Typography variant="body1" ml={2}>
                10.2 Notification of Changes
              </Typography>
              <Typography variant="body1" ml={4}>
                iAffirm will make reasonable efforts to notify users of any
                significant changes to these Terms. However, it is your
                responsibility to review these Terms periodically to stay
                informed of any updates.
              </Typography>
              <Typography variant="body1" ml={2}>
                10.3 Continued Use Constitutes Acceptance
              </Typography>
              <Typography variant="body1" ml={4}>
                Your continued use of the Website after any changes to these
                Terms constitutes your acceptance of the revised terms.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">11. Miscellaneous</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                11.1 Entire Agreement
              </Typography>
              <Typography variant="body1" ml={4}>
                These Terms constitute the entire agreement between the parties
                with respect to the subject matter hereof and supersede all
                prior and contemporaneous agreements, understandings, and
                communications, whether written or oral, relating to such
                subject matter.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.2 Severability
              </Typography>
              <Typography variant="body1" ml={4}>
                If any provision of these Terms is held to be invalid, illegal,
                or unenforceable, the remaining provisions of these Terms shall
                remain in full force and effect, and the invalid, illegal, or
                unenforceable provision shall be reformed to the extent
                necessary to make it enforceable and reflect the original intent
                of the parties.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.3 Waiver
              </Typography>
              <Typography variant="body1" ml={4}>
                The failure of either party to enforce any right or provision of
                these Terms shall not constitute a waiver of that right or
                provision. A waiver of any right or provision of these Terms
                must be in writing and signed by the party granting the waiver.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.4 Assignment
              </Typography>
              <Typography variant="body1" ml={4}>
                Neither party may assign or transfer any of its rights or
                obligations under these Terms without the prior written consent
                of the other party, except that iAffirm may assign these Terms
                to an affiliate or in connection with a merger, acquisition, or
                sale of all or substantially all of its assets.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.5 Force Majeure
              </Typography>
              <Typography variant="body1" ml={4}>
                Neither party shall be liable for any failure or delay in the
                performance of its obligations under these Terms (except for
                payment obligations) if such failure or delay is due to causes
                beyond its reasonable control, including but not limited to acts
                of God, war, terrorism, strikes, labour disputes, government
                regulations, natural disasters, or any other force majeure
                event.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.6 Relationship of the Parties
              </Typography>
              <Typography variant="body1" ml={4}>
                The parties are independent contractors and nothing in these
                Terms shall create any partnership, joint venture, agency,
                franchise, sales representative, or employment relationship
                between the parties. Neither party has the authority to make or
                accept any offers or representations on behalf of the other
                party.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.7 Headings
              </Typography>
              <Typography variant="body1" ml={4}>
                The headings in these Terms are for convenience only and shall
                not affect the interpretation of these Terms.
              </Typography>
              <Typography variant="body1" ml={2}>
                11.8 Third-Party Beneficiaries
              </Typography>
              <Typography variant="body1" ml={4}>
                Nothing in these Terms, express or implied, is intended to or
                shall confer upon any person other than the parties hereto any
                right, benefit, or remedy of any nature whatsoever under or by
                reason of these Terms.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">12. Contact Information</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                12.1 Customer Support Contact
              </Typography>
              <Typography variant="body1" ml={4}>
                If you have any questions or concerns about these Terms or the
                iAffirm Website and services, please contact our customer
                support team at hello@iaffirm.ai.
              </Typography>
              <Typography variant="body1" ml={2}>
                12.2 Address for Notices
              </Typography>
              <Typography variant="body1" ml={4}>
                Notices to iAffirm should be sent to the following address:
                <ul>
                  <li>iAffirm Ltd.</li>
                  <li>Wishing Well Barn </li>
                  <li>Hardwick</li>
                  <li>Lincoln</li>
                  <li>Lincolnshire</li>
                  <li>LN1 2PW</li>
                  <li>United Kingdom</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Box>
        <hr style={{ margin: '1rem 0 1rem 0' }} color="#427e83" />
        <Box>
          <Typography variant="h4" color="secondary">
            Privacy Policy for iAffirm Website
          </Typography>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">1. Introduction</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                1.1 Purpose
              </Typography>
              <Typography variant="body1" ml={4}>
                This Privacy Policy explains how iAffirm Ltd.
                (&quot;iAffirm&quot;, &quot;we&quot;, &quot;our&quot;,
                &quot;us&quot;) collects, uses, discloses, and protects your
                personal data when you visit and use our website
                (&quot;Website&quot;) and related services
                (&quot;Services&quot;).
              </Typography>
              <Typography variant="body1" ml={2}>
                1.2 Scope
              </Typography>
              <Typography variant="body1" ml={4}>
                This policy applies to all visitors, users, and others who
                access the Website. By using the Website, you agree to the
                collection and use of information in accordance with this
                Privacy Policy.
              </Typography>
              <Typography variant="body1" ml={2}>
                1.3 Definitions
              </Typography>
              <Typography variant="body1" ml={4}>
                Personal Data: Any information that relates to an identified or
                identifiable individual. Processing: Any operation performed on
                personal data, such as collection, use, storage, and sharing.
                <br />
                User: Any individual who uses the Website and Services.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">2. Data Collection</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                2.1 Types of Data Collected
              </Typography>
              <Typography variant="body1" ml={4}>
                Personal Information: Includes name, email address, phone
                number, postal address, and payment information.
                <br />
                Usage Data: Information about how you interact with the Website,
                including IP addresses, browser type, and activity logs.
                <br />
                Technical Data: Device information, operating system, and
                internet service provider details.
              </Typography>
              <Typography variant="body1" ml={2}>
                2.2 Methods of Data Collection
              </Typography>
              <Typography variant="body1" ml={4}>
                Directly from Users: Through account registration, profile
                updates, and customer support interactions.
                <br />
                Automatically: Via cookies, web beacons, and similar tracking
                technologies.
                <br />
                From Third Parties: Such as social media platforms and other
                partners.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">3. Data Usage</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                3.1 Purpose of Data Collection:
              </Typography>
              <Typography variant="body1" ml={4}>
                <ul>
                  <li>To provide and improve the Website and Services.</li>
                  <li>To process transactions and manage accounts.</li>
                  <li>
                    To communicate with users, including sending promotional
                    materials and updates
                  </li>
                  <li>
                    To analyse user behaviour and usage patterns for better
                    service delivery.
                  </li>
                  <li>
                    To comply with legal obligations and resolve disputes.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" ml={2}>
                3.2 How Data is Used
              </Typography>
              <Typography variant="body1" ml={4}>
                Service Delivery: Ensuring the Website functions correctly and
                efficiently.
                <br />
                Personalization: Customizing user experience based on
                preferences and usage history.
                <br />
                Marketing and Advertising: Sending targeted advertisements and
                promotional offers.
                <br />
                Analytics: Conducting research and analysis to enhance our
                services.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">4. Data Sharing and Disclosure</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                4.1 Third-Party Service Providers
              </Typography>
              <Typography variant="body1" ml={4}>
                We may share data with third-party service providers who assist
                us in operating the Website, such as payment processors, hosting
                services, and marketing agencies. These providers are bound by
                confidentiality agreements
              </Typography>
              <Typography variant="body1" ml={2}>
                4.2 Legal Requirements
              </Typography>
              <Typography variant="body1" ml={4}>
                We may disclose personal data if required to do so by law or in
                response to valid requests by public authorities.
              </Typography>
              <Typography variant="body1" ml={2}>
                4.3 Business Transfers
              </Typography>
              <Typography variant="body1" ml={4}>
                In the event of a merger, acquisition, or sale of assets, user
                data may be transferred to the new owner.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">5. Data Storage and Security</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                5.1 Storage Practices
              </Typography>
              <Typography variant="body1" ml={4}>
                Personal data is stored on secure servers and cloud services
                managed by reputable providers.
              </Typography>
              <Typography variant="body1" ml={2}>
                5.2 Security Measures
              </Typography>
              <Typography variant="body1" ml={4}>
                We employ appropriate security measures to protect personal
                data, including encryption, access controls, and regular
                security audits.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">6. User Rights</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                6.1 Access to Data
              </Typography>
              <Typography variant="body1" ml={4}>
                You have the right to request access to your personal data held
                by iAffirm.
              </Typography>
              <Typography variant="body1" ml={2}>
                6.2 Correction of Data
              </Typography>
              <Typography variant="body1" ml={4}>
                You can request corrections to any inaccurate or incomplete
                personal data.
              </Typography>
              <Typography variant="body1" ml={2}>
                6.3 Deletion of Data
              </Typography>
              <Typography variant="body1" ml={4}>
                You can request the deletion of your personal data, subject to
                certain exceptions (e.g., legal requirements).
              </Typography>
              <Typography variant="body1" ml={2}>
                6.4 Data Portability
              </Typography>
              <Typography variant="body1" ml={4}>
                You have the right to receive your personal data in a
                structured, commonly used, and machine-readable format.
              </Typography>
              <Typography variant="body1" ml={2}>
                6.5 Objection to Processing
              </Typography>
              <Typography variant="body1" ml={4}>
                You can object to the processing of your personal data in
                certain circumstances.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              7. Cookies and Tracking Technologies
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                7.1 Types of Cookies Used
              </Typography>
              <Typography variant="body1" ml={4}>
                <ul>
                  <li>
                    Essential Cookies: Necessary for the Website to function
                    properly.
                  </li>
                  <li>
                    Performance Cookies: Collect information about how users
                    interact with the Website.
                  </li>
                  <li>
                    Functional Cookies: Remember user preferences and settings.
                  </li>
                  <li>
                    Targeting Cookies: Used for marketing and advertising
                    purposes.
                  </li>
                </ul>
              </Typography>
              <Typography variant="body1" ml={2}>
                7.2 Purpose of Cookies
              </Typography>
              <Typography variant="body1" ml={4}>
                Cookies help us understand user behaviour, improve
                functionality, and deliver personalized content.
              </Typography>
              <Typography variant="body1" ml={2}>
                7.3 Managing Cookie Preferences
              </Typography>
              <Typography variant="body1" ml={4}>
                You can manage your cookie preferences through your browser
                settings or by using cookie management tools provided on the
                Website.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">8. Children’s Privacy</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                8.1 Age Restrictions
              </Typography>
              <Typography variant="body1" ml={4}>
                The Website is not intended for children under the age of 16. We
                do not knowingly collect personal data from children under 16.
              </Typography>
              <Typography variant="body1" ml={2}>
                8.2 Data Collection from Minors
              </Typography>
              <Typography variant="body1" ml={4}>
                If we become aware that we have collected personal data from a
                child under 16, we will take steps to delete such data promptly.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              9. International Data Transfers
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                9.1 Transfer of Data Abroad
              </Typography>
              <Typography variant="body1" ml={4}>
                Personal data may be transferred to and processed in countries
                outside of your home country.
              </Typography>
              <Typography variant="body1" ml={2}>
                9.2 Safeguards for International Transfers
              </Typography>
              <Typography variant="body1" ml={4}>
                We implement appropriate safeguards to ensure that personal data
                is protected during international transfers, such as Standard
                Contractual Clauses.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">
              10. Changes to the Privacy Policy
            </Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                10.1 Notification of Changes
              </Typography>
              <Typography variant="body1" ml={4}>
                We may update this Privacy Policy from time to time. We will
                notify users of any significant changes by posting the new
                policy on our Website and updating the effective date.
              </Typography>
              <Typography variant="body1" ml={2}>
                10.2 Effective Date of Changes
              </Typography>
              <Typography variant="body1" ml={4}>
                The updated Privacy Policy will become effective as of the date
                of posting.
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Typography variant="h6">11. Contact Information</Typography>
            <Box
              sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
              <Typography variant="body1" ml={2}>
                11.1 How to Contact Us
              </Typography>
              <Typography variant="body1" ml={4}>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at:
                <br />
                Email: hello@iaffirm.ai
                <br />
                Address: iAffirm Ltd., Wishing Well Barn, Hardwick, Lincoln, LN1
                2PW
              </Typography>
              <Typography variant="body1" ml={2}>
                11.2 Data Protection Officer
              </Typography>
              <Typography variant="body1" ml={4}>
                For privacy-related inquiries, you may contact our Data
                Protection Officer at:
                <br />
                Email: hello@iaffirm.ai
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => handleScrollTop()}>
            <ExpandLessIcon color="secondary" fontSize="large" />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
