import BackgroundHomeDesktop from 'assets/images/home/desktop/hero-section-image.png';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

import RoutesEnum from 'types/routes.enum';

const HomeHeaderMobile = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column' as 'column',
        backgroundImage: `url(${BackgroundHomeDesktop})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}>
      <Container>
        <Box mt="20vh" />
        <div
          style={{
            gap: '1rem',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center'
          }}>
          <Typography
            color={Colors.white}
            fontFamily={evo2FontFamily}
            fontSize={'1rem'}
            fontWeight={200}>
            Tap into the potential of your subconscious mind <br /> with iAffirm
          </Typography>
          <Typography
            color={Colors.white}
            lineHeight={0.9}
            sx={{ textShadow: '1px 3px 3px rgba(0, 0, 0, 0.33)' }}
            fontFamily={evo2FontFamily}
            fontSize={'2rem'}
            fontWeight={700}>
            Awaken Your Inner You
          </Typography>
          <Typography
            color={Colors.white}
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontSize={'1rem'}
            fontWeight={200}>
            Let AI curate personalised affirmations, visualisations,
            <br /> and guided meditations just for you.
          </Typography>
        </div>
        <Grid container justifyContent="center" mt="3.25rem">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => navigate(RoutesEnum.NEW_AFFIRMATION)}>
            Create your first affirmations
          </Button>
        </Grid>
      </Container>

      <Box mt="4rem" />
    </Box>
  );
};
export default HomeHeaderMobile;
