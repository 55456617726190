import { createAsyncThunk } from '@reduxjs/toolkit';
import _affirmationService from 'services/affirmation';

import { SelectedTraits } from 'modules/new-affirmation/types/initialState';

import {
  Affirmation,
  CreateAffirmationDto,
  PostAffirmationArgs,
  SpeechParameters
} from './types/affirmation.entity';
import { Outcome } from './types/outcome.entity';

export const getOutcomes = createAsyncThunk(
  'affirmation/get-outcomes',
  async () => {
    return (await _affirmationService.get()).data.data;
  }
);

export const getQualities = createAsyncThunk(
  'affirmation/get-qualities',
  async (outcomeId: number) => {
    return (await _affirmationService.getQualities(outcomeId)).data.data;
  }
);

export const generateAffirmation = createAsyncThunk(
  'affirmation/generate',
  async (params: {
    selectedTraits: SelectedTraits[];
    outcome: Outcome;
    additionalQualities: string[];
  }) => {
    return (
      await _affirmationService.create({
        selectedTraits: params.selectedTraits,
        outcome: params.outcome,
        additionalQualities: params.additionalQualities
      })
    ).data.data;
  }
);

export const generateCustomAffirmation = createAsyncThunk(
  'affirmation/generate-custom',
  async (createCustom: CreateAffirmationDto) => {
    return (await _affirmationService.createCustom(createCustom)).data.data;
  }
);

export const postAffirmation = createAsyncThunk(
  'affirmation/post',
  async ({
    affirmationId,
    duration,
    customAffirmation
  }: PostAffirmationArgs) => {
    const response = await _affirmationService.generateAffirmations(
      affirmationId,
      duration,
      customAffirmation
    );
    return response.data.data;
  }
);

export const getPdf = createAsyncThunk(
  'affirmation/get-pdf',
  async (params: {
    affirmationId: string;
    affirmations: Affirmation[];
    templateId?: string;
    template?: boolean;
    customFlow?: boolean;
  }) => {
    return (await _affirmationService.getPdf(params)).data.data;
  }
);

export const getSpeech = createAsyncThunk(
  'affirmation/get-speech',
  async (speechParameters: SpeechParameters) => {
    return (await _affirmationService.getSpeech(speechParameters)).data.data;
  }
);

export const generateTemplate = createAsyncThunk(
  'affirmation/generate-template',
  async (params: { templateId: string }) => {
    const createAffirmation = await _affirmationService.createTemplate(params);
    return createAffirmation.data.data;
  }
);

export const deleteAffirmation = createAsyncThunk(
  'affirmation/delete-affirmation',
  async (affirmationId: string) => {
    return await _affirmationService.deleteAffirmation(affirmationId);
  }
);

export const getMorningAffirmations = createAsyncThunk(
  'admin-area/get-morning-affirmations',
  async () => {
    return await _affirmationService.getMorningAffirmations();
  }
);
