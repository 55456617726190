import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import { setLastStepVisualization } from 'redux/visualization/visualization.slice';

import NewVisualizationForm from './NewVisualizationForm';
import { TabsContextProvider } from './TabsContext';

const NewVisualizationCustom: React.FC = () => {
  const dispatch = useAppDispatch();

  dispatch(setLastStepVisualization());
  return (
    <TabsContextProvider>
      <NewVisualizationForm />
    </TabsContextProvider>
  );
};

export default NewVisualizationCustom;
