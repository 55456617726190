import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';
import * as Yup from 'yup';

import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { addTraitsToCustomQuality } from 'modules/new-affirmation/redux/ui/ui.slice';

import { isBannedWord } from 'utils/bannedWords';

import { CustomTraitsOptionProps } from './3_CustomTraitFirstOption';

const contextTooltipText = 'To be an Excellent Entrepreneur, I am a “Hustler”';

const validationSchema = Yup.object().shape({
  traits: Yup.array()
    .of(
      Yup.string()
        .required('Required')
        .min(4, 'Minimum 4 characters required')
        .test(
          'isBannedWord',
          'There is a not allowed word.',
          (value) => !isBannedWord(value)
        )
    )
    .min(1, 'At least one trait is required')
    .max(3, 'No more than three traits are allowed')
});

const CustomTraitThirdOption: React.FC<CustomTraitsOptionProps> = ({
  handleNextStep,
  handlePreviousStep
}) => {
  const dispatch = useAppDispatch();

  const handleNext = async (values: { traits: string[] }) => {
    const traits = values.traits;
    await dispatch(addTraitsToCustomQuality({ qualityIndex: 2, traits }));
    handleNextStep();
  };

  return (
    <Formik
      initialValues={{ traits: [''] }}
      validationSchema={validationSchema}
      onSubmit={handleNext}>
      {({ values, errors, touched, isValid, dirty, handleChange }) => (
        <Form>
          <Grid
            container
            justifyContent="space-between"
            mb="1.5rem"
            alignItems="center">
            <Typography
              fontSize="0.875rem"
              fontWeight={500}
              color={Colors.brown.light}>
              Enter up to 3 traits for Quality 3
            </Typography>
          </Grid>
          <Tooltip title={contextTooltipText} placement="top-start">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ErrorOutlineIcon color="secondary" />
              <Typography color="secondary">Show an example</Typography>
            </Box>
          </Tooltip>
          <FieldArray name="traits">
            {({ push, remove }) => (
              <>
                {values.traits.map((trait, index) => (
                  <Box
                    key={index}
                    sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Field
                      as={TextField}
                      name={`traits.${index}`}
                      placeholder="| Type your trait"
                      variant="standard"
                      fullWidth
                      multiline
                      color="secondary"
                      value={trait}
                      onChange={handleChange}
                      error={touched.traits && !!errors.traits?.[index]}
                      helperText={
                        <ErrorMessage name={`traits.${index}`} /> ||
                        `${
                          150 - trait.length
                        } characters remaining (Minimum 4 characters)`
                      }
                      sx={{ flexGrow: 1, mr: 2 }}
                      inputProps={{
                        style: {
                          fontSize: 22,
                          lineHeight: 1,
                          marginBottom: 20,
                          marginTop: 20
                        },
                        maxLength: 150
                      }}
                    />
                    {index > 0 && (
                      <Button onClick={() => remove(index)}>
                        <DeleteIcon />
                      </Button>
                    )}
                  </Box>
                ))}
                {values.traits.length < 3 && (
                  <Button
                    onClick={() => push('')}
                    sx={{ mt: 2, marginBottom: 9.5 }}>
                    + Add Another Trait
                  </Button>
                )}
              </>
            )}
          </FieldArray>
          <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handlePreviousStep}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={!dirty || !isValid}
                sx={{ backgroundColor: Colors.bgGreen }}
                variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomTraitThirdOption;
