import axios, { AxiosResponse } from 'axios';

import {
  PromotionApplication,
  PromotionData,
  UserOnboardingData
} from 'redux/user/types/userState.interface';

import { IApiResponse } from 'types/apiResponse.interface';
import loginRequestType from 'types/loginRequest.type';
import { ProfileUserDTO } from 'types/profileUser.interface';
import signupRequestType from 'types/signupRequest.type';
import { User } from 'types/user.entity';
import { HomeData } from 'types/user.home.interface';

import { setLocalStorage } from 'utils/localstorage';

import { api, baseURL } from './instance';

const _userService = {
  login: async (data: loginRequestType) => {
    const response = await axios.post<AxiosResponse<{ token: string }>>(
      `${baseURL}/user/login`,
      data
    );

    const token = response.data.data.token;
    setLocalStorage('token', token);

    return response;
  },
  signup: async (data: signupRequestType) => {
    const response = await axios.post<IApiResponse<{ token: string }>>(
      `${baseURL}/user`,
      data
    );
    const token = response.data.data.token;
    setLocalStorage('token', token);
    return response;
  },
  getUserHome: async (offset: number) => {
    return await api.get<IApiResponse<HomeData>>(
      `/user/home?limit=3&offset=${offset}`
    );
  },
  getUserInfo: async () => {
    return await api.get<IApiResponse<Partial<User>>>('/user/info');
  },
  updateUser: async (data: Partial<User>) => {
    return await api.patch<IApiResponse<User>>('/user/update', data);
  },
  updatePassword: async (data: { password: string; newPassword: string }) => {
    return await api.patch<IApiResponse<boolean>>('/user/password', data);
  },
  changePassword: async (data: { email: string }) => {
    return await axios.post<IApiResponse<boolean>>(
      `${baseURL}/user/request-password`,
      data
    );
  },
  invite: async (data: { inviteeEmail: string; inviteeName: string }) => {
    return await api.post<IApiResponse<boolean>>(
      `${baseURL}/user/invite`,
      data
    );
  },
  restorePassword: async (data: { password: string; token: string }) => {
    return await axios.post<IApiResponse<boolean>>(
      `${baseURL}/user/restore-password`,
      data,
      { headers: { Authorization: `Bearer ${data.token}` } }
    );
  },
  waitingList: async (data: {
    email: string;
    name: string;
    previousExperience: string;
    goals: string;
    value: string;
  }) => {
    return await api.post<IApiResponse<boolean>>(
      `${baseURL}/user/waiting-list`,
      data
    );
  },
  addOnboardingData: async (data: UserOnboardingData) => {
    return await api.post<IApiResponse<UserOnboardingData>>(
      `${baseURL}/user/onboarding`,
      data
    );
  },
  getProfile: async () => {
    return await api.get<IApiResponse<ProfileUserDTO>>('/user/profile-user');
  },

  postProfileUser: async (data: ProfileUserDTO) => {
    return await api.post<IApiResponse<ProfileUserDTO>>(
      '/user/profile-user',
      data
    );
  },
  applyPromotionCode: async (data: PromotionData) => {
    return await api.post<IApiResponse<PromotionApplication>>(
      `${baseURL}/admin-area/apply-promotion-code`,
      data
    );
  }
};
export default _userService;
