import { useFormikContext } from 'formik';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import { NewVisualization } from '../../../types/new-visualization.types';
import StepsMenu from '../../StepsMenu';

const qualityTooltipText =
  'Now add the location that you are in “I want to visualize myself closing a deal with an important client while feeling confident ... in my normal business environment”';

const SituationStep = () => {
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setTouched,
    validateForm,
    submitForm
  } = useFormikContext<NewVisualization>();

  const handleNextStep = async () => {
    await setTouched({ situation: true });
    const formErrors = await validateForm();
    if (!formErrors.situation) {
      await submitForm();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: 24, marginBottom: 4, lineHeight: '3rem' }}>
          And in a ...
        </Typography>
        <Tooltip title={qualityTooltipText} placement="top-start">
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              userSelect: 'none'
            }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography color="secondary">Show an example</Typography>
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ width: '100%', marginBottom: 20 }}>
        <TextField
          placeholder="| Describe where you are in that situation"
          name="situation"
          variant="standard"
          value={values.situation}
          id={values.situation}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.situation && Boolean(errors.situation)}
          helperText={touched.situation && errors.situation}
          fullWidth
          multiline
          color="secondary"
          inputProps={{
            style: { fontSize: 36, lineHeight: 1, marginBottom: 40 },
            maxLength: 30
          }}
        />

        <Typography sx={{ marginTop: 3 }}>
          {30 - values.situation.length} characters remaining (Minimum 5
          characters)
        </Typography>
      </Box>

      <StepsMenu nextAction={handleNextStep} />
    </Box>
  );
};

export default SituationStep;
