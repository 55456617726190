import { Outlet } from 'react-router-dom';

import { Box } from '@mui/material';

import { Colors } from 'design/theme';

import SideImage from '../../assets/images/SideImage.png';

const HalfFullScreenCentered = () => {
  return (
    <Box display="flex" width="100%">
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          background: Colors.primary.light
        }}>
        <Outlet />
      </Box>
      <Box
        sx={{
          width: '100%',
          backgroundImage: `url(${SideImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: '#004737',
          display: { xs: 'none', md: 'block' }
        }}
      />
    </Box>
  );
};

export default HalfFullScreenCentered;
