export enum Background {
  Calming = 'Calming',
  Divine = 'Divine',
  India = 'India',
  Jump = 'Jump',
  Natures = 'Natures',
  NewAge = 'NewAge',
  Northern = 'Northern',
  Pale = 'Pale',
  Spacey = 'Spacey',
  Binaural = 'Binaural'
}
