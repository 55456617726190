import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React from 'react';
import * as Yup from 'yup';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import {
  setActualStep,
  setCustomOutcome,
  setHomeStatus
} from 'modules/new-affirmation/redux/ui/ui.slice';

import { isBannedWord } from 'utils/bannedWords';

const contextTooltipText = 'I am working towards ”Entrepreneurial excellence”';

const validationSchema = Yup.object().shape({
  outcome: Yup.string()
    .required('Required')
    .min(3, 'Minimum 3 characters required')
    .test(
      'isBannedWord',
      'There is a not allowed word.',
      (value) => !isBannedWord(value)
    )
});

const CustomOutcome = () => {
  const dispatch = useAppDispatch();
  const { actualStep, home } = useAppSelector(newAffirmationUISelector);

  const handleNext = (values: { outcome: string }) => {
    dispatch(setCustomOutcome(values.outcome));
    dispatch(setActualStep(actualStep + 1));
  };

  return (
    <Formik
      initialValues={{ outcome: '' }}
      validationSchema={validationSchema}
      onSubmit={handleNext}>
      {({ values, errors, touched, isValid, dirty, handleChange }) => (
        <Form>
          <Box>
            <Box
              mb={2}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}>
              <Typography sx={{ fontSize: 22 }}>
                Select your own outcome
              </Typography>

              <Tooltip title={contextTooltipText} placement="top-start">
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    userSelect: 'none'
                  }}>
                  <ErrorOutlineIcon color="secondary" />
                  <Typography color="secondary">Show an example</Typography>
                </Box>
              </Tooltip>
            </Box>

            <Box sx={{ width: '100%', marginBottom: 20 }}>
              <Field
                as={TextField}
                placeholder="| Type your outcome"
                name="outcome"
                variant="standard"
                fullWidth
                multiline
                color="secondary"
                value={values.outcome}
                onChange={handleChange}
                error={touched.outcome && !!errors.outcome}
                helperText={<ErrorMessage name="outcome" />}
                inputProps={{
                  style: { fontSize: 22, lineHeight: 1, marginBottom: 20 },
                  maxLength: 150
                }}
              />

              <Typography sx={{ marginTop: 3 }}>
                {150 - values.outcome.length} characters remaining (Minimum 4
                characters)
              </Typography>
            </Box>
          </Box>

          <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  if (home) dispatch(setHomeStatus(false));
                  dispatch(setActualStep(1));
                }}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={!dirty || !isValid}
                sx={{ backgroundColor: Colors.bgGreen }}
                variant="contained">
                Next
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CustomOutcome;
