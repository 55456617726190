import { useFormikContext } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import StepsMenu from 'modules/new-visualization/components/StepsMenu';

import { createMeditation } from 'redux/meditations/meditation.action';
import { NewMeditationFormik } from 'redux/meditations/types/meditation.entity';

const qualityTooltipText =
  'Now add the attribute you want to address, for example “I want to become less distracted';
const AttributeStep = () => {
  const dispatch = useAppDispatch();
  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setTouched,
    validateForm,
    submitForm
  } = useFormikContext<NewMeditationFormik>();

  const handleNextStep = async () => {
    await setTouched({ attribute: true });
    const formErrors = await validateForm();
    if (!formErrors.attribute) {
      await submitForm();
    }
    dispatch(
      createMeditation({
        outcome: { outcome: values.outcome },
        quality: { quality: values.quality },
        goal: { goal: values.goal },
        attribute: { attribute: values.attribute }
      })
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: 24, marginBottom: 4 }}>
          And addressing any negative issues (optional) ...
        </Typography>

        <Tooltip title={qualityTooltipText} placement="top-start">
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              userSelect: 'none'
            }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography color="secondary">Show an example</Typography>
          </Box>
        </Tooltip>
      </Box>

      <Box sx={{ width: '100%', marginBottom: 20 }}>
        <TextField
          placeholder="| Describe where you are in this situation"
          name="attribute"
          variant="standard"
          value={values.attribute}
          id={values.attribute}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.attribute && Boolean(errors.attribute)}
          helperText={touched.attribute && errors.attribute}
          fullWidth
          multiline
          color="secondary"
          inputProps={{
            style: { fontSize: 36, lineHeight: 1, marginBottom: 40 },
            maxLength: 30
          }}
        />

        <Typography sx={{ marginTop: 3 }}>
          {30 - values.attribute.length} characters remaining (Minimum 5
          characters)
        </Typography>
      </Box>

      <StepsMenu nextAction={handleNextStep} />
    </Box>
  );
};

export default AttributeStep;
