import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { getUserInfo, updateUser } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';
import { setUserProfileModal } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

import { countries } from 'utils/countries';

import { CircularLogoLoader } from './CircularLogoLoader';

interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  age: string;
  country: string;
  [key: string]: string;
}
export const UserProfile: React.FC = () => {
  const statusReview = false;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const originalUserInfo = useAppSelector(userSelector).ui.userInfo;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: originalUserInfo?.firstName || '',
    lastName: originalUserInfo?.lastName || '',
    email: originalUserInfo?.email || '',
    age: originalUserInfo?.age || '',
    country: originalUserInfo?.country || ''
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setUserInfo({ ...userInfo, [name]: value });
  };

  const hasChanges =
    JSON.stringify({
      firstName: originalUserInfo?.firstName,
      lastName: originalUserInfo?.lastName,
      email: originalUserInfo?.email,
      age: originalUserInfo?.age,
      country: originalUserInfo?.country
    }) !== JSON.stringify(userInfo);

  const saveChanges = () => {
    setErrorMessage('');
    const fieldsToValidate = [
      'firstName',
      'lastName',
      'age',
      'country',
      'email'
    ];
    const formatFieldName = (fieldName: string): string => {
      const words = fieldName.replace(/([A-Z])/g, ' $1').trim();
      return words.charAt(0).toUpperCase() + words.slice(1);
    };

    const emptyField = fieldsToValidate.find(
      (field) => !userInfo[field as keyof UserInfo]?.trim()
    );

    if (emptyField) {
      setErrorMessage(`${formatFieldName(emptyField)} should not be empty.`);
      return;
    }
    if (hasChanges) {
      dispatch(updateUser(userInfo))
        .then(() => {
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
            handleClose();
          }, 3000);
          dispatch(getUserInfo());
        })
        .catch((error) => {
          setErrorMessage('Failed to update profile.');
        });
    }
  };

  useEffect(() => {
    if (!originalUserInfo) {
      dispatch(getUserInfo());
    }
  }, [dispatch, originalUserInfo]);
  const handleClose = (): void => {
    dispatch(setUserProfileModal(false));
    navigate(RoutesEnum.USER_HOME);
  };

  useEffect(() => {
    setUserInfo({
      firstName: originalUserInfo?.firstName || '',
      lastName: originalUserInfo?.lastName || '',
      email: originalUserInfo?.email || '',
      age: originalUserInfo?.age || '',
      country: originalUserInfo?.country || ''
    });
  }, [originalUserInfo]);

  return (
    <>
      <Box
        sx={{
          margin: isLargeScreen ? '1.5rem' : '0.25rem',
          marginTop: '0.75rem',
          justifyContent: 'center'
        }}>
        <Grid container>
          <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
            <Typography
              variant="h1"
              fontSize={'1rem'}
              fontFamily={'"Nunito"'}
              fontWeight={600}
              lineHeight={'1.75rem'}
              sx={{
                color: Colors.secondary.dark
              }}>
              Review your account details
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ maxWidth: '30rem' }}>
          <Box mb="1rem" mt="0.75rem">
            <TextField
              color="secondary"
              fullWidth
              value={userInfo.firstName}
              onChange={handleChange}
              name="firstName"
              id="firstName"
              label="First name"
              placeholder="First name *"
              autoComplete="firstName"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '500',
                color: Colors.brown.main
              }}
            />
          </Box>
          <Box mb="1rem" mt="0.75rem">
            <TextField
              color="secondary"
              fullWidth
              value={userInfo.lastName}
              onChange={handleChange}
              name="lastName"
              id="lastName"
              label="Last name"
              placeholder="Last name *"
              autoComplete="lastName"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '400',
                color: Colors.brown.main
              }}
            />
          </Box>
          <Box mb="1rem" mt="0.75rem">
            <TextField
              color="secondary"
              value={userInfo.email}
              onChange={handleChange}
              name="email"
              fullWidth
              id="email"
              label="Email"
              placeholder="Email *"
              autoComplete="email"
              type="email"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '400',
                color: Colors.brown.main
              }}
            />
          </Box>
          <Box mb="1rem" mt="0.75rem">
            <TextField
              color="secondary"
              value={userInfo.age}
              onChange={handleChange}
              name="age"
              fullWidth
              id="age"
              label="Age"
              placeholder="Age *"
              autoComplete="age"
              type="age"
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '400',
                color: Colors.brown.main
              }}
            />
          </Box>
          <Box mb="1rem" mt="0.75rem">
            <InputLabel id="country-selector-label">Country</InputLabel>
            <Select
              labelId="country-selector-label"
              id="country"
              name="country"
              value={userInfo.country}
              onChange={handleSelectChange}
              displayEmpty
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
              sx={{
                fontFamily: '"Nunito"',
                fontWeight: '400',
                color: Colors.brown.main
              }}>
              {countries.map((country) => (
                <MenuItem key={country.label} value={country.label}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="text"
            sx={{
              textTransform: 'none',
              fontFamily: '"Nunito"',
              fontSize: '0.875rem',
              lineHeight: '1.5rem',
              letterSpacing: '0.1px',
              fontWeight: '500',
              marginLeft: '-0.25rem'
            }}
            color="secondary"
            onClick={() => {
              navigate(RoutesEnum.CHANGE_PASSWORD);
            }}>
            Restore password
          </Button>

          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
        </Box>
        <Box
          sx={{
            margin: '1rem 0',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            sx={{
              marginRight: '0.5rem',
              fontSize: '0.875rem',
              textTransform: 'none',
              fontFamily: '"Nunito"',
              lineHeight: '1.15rem',
              letterSpacing: '0.1px',
              fontWeight: '500',
              border: 'none'
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!hasChanges}
            onClick={saveChanges}
            sx={{
              fontSize: '0.875rem',
              textTransform: 'none',
              fontFamily: '"Nunito"',
              lineHeight: '1.15rem',
              letterSpacing: '0.1px',
              fontWeight: '500',
              color: Colors.white
            }}>
            {statusReview ? <CircularLogoLoader /> : 'Save'}
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSuccessMessage}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMessage(false)}>
        <Alert
          onClose={() => setShowSuccessMessage(false)}
          severity="success"
          sx={{ width: '100%' }}>
          Profile updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};
