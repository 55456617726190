import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect } from 'react';

import { Box, Container, Grid } from '@mui/material';

import AffirmationHeaderMobile from 'components/AffirmationHeaderMobile';
import PDFViewerComponent from 'components/PDFrender/PDFViewer';
import PDFViewerMobile from 'components/PDFrender/PDFViewerMobile';

import { setCustomFlow } from 'modules/new-affirmation/redux/ui/ui.slice';

import { affirmationsTemplate } from 'redux/affirmation-templates/templates.selector';
import { getPdf } from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';

export const AffirmationsReviewMobile: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useAppDispatch();

  const {
    affirmations,
    createdAffirmation,
    selectedUserAffirmations,
    pdf,
    selectedTemplate,
    customFlow
  } = useAppSelector(affirmationSelector);

  const { selectedAffirmations } = useAppSelector(affirmationsTemplate);

  useEffect(() => {
    if (createdAffirmation && !selectedTemplate && !customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          affirmations: selectedUserAffirmations,
          template: false,
          customFlow: false
        })
      );
    }
    if (selectedTemplate && createdAffirmation && !customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          templateId: selectedTemplate.id,
          affirmations: selectedUserAffirmations,
          template: true,
          customFlow: false
        })
      );
    }

    if (createdAffirmation && customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          affirmations: selectedUserAffirmations,
          template: false,
          customFlow: true
        })
      );

      dispatch(setCustomFlow(false));
    }
  }, [
    selectedUserAffirmations,
    createdAffirmation,
    dispatch,
    selectedTemplate,
    customFlow
  ]);

  return (
    <Box
      sx={{
        pb: '2rem',
        minHeight: '100%'
      }}>
      <AffirmationHeaderMobile
        title={
          affirmations?.affirmations
            ? selectedUserAffirmations[0].affirmationSentence
            : selectedTemplate?.title
        }
        developmentArea={createdAffirmation?.developmentArea}
        createdOn={dayjs().toDate()}
        pdfUrl={pdf}
      />

      {(affirmations || selectedAffirmations) && (
        <Container>
          <Grid container flexWrap="wrap-reverse" mt="0.75rem" spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  borderRadius: '0.25rem'
                }}>
                {isLargeScreen
                  ? pdf && <PDFViewerComponent pdfURL={pdf} />
                  : pdf && <PDFViewerMobile pdfURL={pdf} />}
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};
export default AffirmationsReviewMobile;
