export const getRemainingDays = (endDate?: string): number | undefined => {
  if (!endDate) {
    return undefined;
  }
  const today = new Date();
  const end = new Date(endDate);
  const difference = end.getTime() - today.getTime();
  return Math.ceil(difference / (1000 * 60 * 60 * 24));
};
export default getRemainingDays;
