import { SelectedTraits } from 'modules/new-affirmation/types/initialState';

import {
  Affirmation,
  CreateAffirmationDto,
  CreatedAffirmation,
  GeneratedAffirmations,
  MorningAffirmation,
  SpeechParameters
} from 'redux/affirmation/types/affirmation.entity';
import { Outcome } from 'redux/affirmation/types/outcome.entity';
import { Quality } from 'redux/affirmation/types/quality.entity';

import { IApiResponse } from 'types/apiResponse.interface';
import { AudioDurations } from 'types/durations.enum';

import { api, baseURL } from './instance';

const _affirmationService = {
  get: () => {
    return api.get<IApiResponse<Outcome[]>>(`${baseURL}/affirmations/outcomes`);
  },
  getQualities: (outcomeId: number) => {
    return api.get<IApiResponse<Quality[]>>(
      `${baseURL}/affirmations/outcomes/${outcomeId}/qualities`
    );
  },
  create: async (params: {
    selectedTraits: SelectedTraits[];
    outcome: Outcome;
    additionalQualities: string[];
  }) => {
    const predefinedQualities = params.selectedTraits.map((selectedTrait) => ({
      qualityId: selectedTrait.quality.id,
      traits: selectedTrait.traits.map((trait) => ({
        traitId: Number(trait.id)
      }))
    }));

    const customQualities = params.additionalQualities.map((quality) => ({
      otherQuality: quality,
      traits: []
    }));

    const data = {
      outcomeId: params.outcome.id,
      qualities: [...predefinedQualities, ...customQualities]
    };

    const createAffirmation = await api.post<IApiResponse<CreatedAffirmation>>(
      `${baseURL}/affirmations/create`,
      data
    );

    return createAffirmation;
  },
  createCustom: async (createCustom: CreateAffirmationDto) => {
    const createAffirmation = await api.post<IApiResponse<CreatedAffirmation>>(
      `${baseURL}/affirmations/create`,
      createCustom
    );

    return createAffirmation;
  },
  generateAffirmations: async (
    affirmationId: string,
    duration: AudioDurations,
    customAffirmation: boolean
  ) => {
    const affirmations = await api.post<IApiResponse<GeneratedAffirmations>>(
      `${baseURL}/affirmations/generate-affirmations`,
      {
        affirmationId,
        duration,
        customAffirmation
      }
    );
    return affirmations;
  },
  getSpeech: async (speechParameters: SpeechParameters) => {
    const resp = await api.post<IApiResponse<string>>(
      `${baseURL}/affirmations/speech-affirmations`,
      speechParameters
    );
    return resp;
  },
  getPdf: async (params: {
    affirmationId: string;
    affirmations: Affirmation[];
    templateId?: string;
    template?: boolean;
    customFlow?: boolean;
  }) => {
    const resp = await api.post<IApiResponse<string>>(
      `${baseURL}/affirmations/pdf-affirmations`,
      { ...params }
    );
    return resp;
  },
  createTemplate: async (params: { templateId: string }) => {
    const createAffirmation = await api.post<IApiResponse<CreatedAffirmation>>(
      `${baseURL}/affirmations/create`,
      { templateId: params.templateId }
    );
    return createAffirmation;
  },
  deleteAffirmation: async (affirmationId: string) => {
    const deleteAffirmation = await api.delete(
      `${baseURL}/affirmations/delete/${affirmationId}`
    );
    return deleteAffirmation;
  },
  getMorningAffirmations: async () => {
    const morningAffirmaton = await api.get<IApiResponse<MorningAffirmation[]>>(
      `${baseURL}/admin-area/get-morning-affirmations`
    );

    return morningAffirmaton.data.data;
  }
};
export default _affirmationService;
