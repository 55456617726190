import {
  Box,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  styled
} from '@mui/material';

import { Colors } from 'design/theme';

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: '#FEF9F6',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '0.25rem',
  '&.MuiSvgIcon-root': { color: theme.palette.secondary.main },
  '& .MuiInputBase-input': {
    padding: 0,
    paddingRight: '0 !important',
    fontSize: 0,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  }
}));

export type ICardSelectOption = {
  label: string;
  value: string;
};

export interface ICardSelectProps {
  title: string;
  options: ICardSelectOption[];
  selected?: ICardSelectOption;
  onClick?: (option: ICardSelectOption) => void;
}

const CardSelect = ({
  title,
  selected,
  options,
  onClick
}: ICardSelectProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: selected ? Colors.secondary.light : Colors.white,
        minHeight: '7.875rem',
        padding: '1.5rem',
        borderRadius: '0.25rem',
        border: `1px solid ${
          selected ? Colors.secondary.main : Colors.neutral
        }`,
        boxShadow: `0px 1px 0px 0px ${Colors.neutral}`
      }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography color={Colors.secondary.main} fontWeight={500}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Select
            input={<BootstrapInput />}
            value={selected ? selected.value : undefined}
            variant="standard"
            sx={{
              backgroundColor: selected
                ? Colors.secondary.main
                : Colors.secondary.light,
              '& .MuiSvgIcon-root': {
                color: selected ? Colors.white : Colors.secondary.main
              }
            }}>
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                onClick={() => onClick && onClick(option)}
                selected={
                  selected ? selected.value === option.value : undefined
                }
                sx={{
                  backgroundColor:
                    selected && selected.value === option.value
                      ? `${Colors.secondary.light} !important`
                      : undefined,
                  color: Colors.secondary.dark
                }}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Typography
        color={Colors.secondary.main}
        fontWeight={500}
        fontSize="1.25rem"
        mt={'.5rem'}>
        {selected ? selected.label : ''}
      </Typography>
    </Box>
  );
};
export default CardSelect;
