import { CredentialsStatusDTO } from 'redux/affiliation/types/affiliationState.interface';
import {
  CreateAffiliationDto,
  PdfData,
  RequestedAffiliation
} from 'redux/affiliation/types/affiliations.entity';

import { IApiResponse } from 'types/apiResponse.interface';

import { api, baseURL } from './instance';

const affiliationsService = {
  requestAffiliation: (createAffiliationDto: CreateAffiliationDto) => {
    return api.post<IApiResponse<RequestedAffiliation>>(
      `${baseURL}/affiliation`,
      createAffiliationDto
    );
  },
  affiliationStatus: () => {
    return api.get<
      IApiResponse<{
        statusCreated: boolean;
        statusReview: boolean;
        statusAffiliation: boolean;
      }>
    >(`${baseURL}/affiliation/status`);
  },
  uploadPDF: (file: File, credentialCode: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('credentialCode', credentialCode);

    return api.post<IApiResponse<{ message: string; filePath: string }>>(
      `${baseURL}/affiliation/upload-pdf`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
  },
  getCredentialsStatus: () => {
    return api.get<IApiResponse<CredentialsStatusDTO>>(
      `${baseURL}/affiliation/credentials-status`
    );
  },
  signAgreementsStatus: (pdfData: PdfData) => {
    return api.post<IApiResponse<string>>(
      `${baseURL}/affiliation/agreements`,
      pdfData
    );
  }
};

export default affiliationsService;
