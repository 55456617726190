import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';

import { Colors } from 'design/theme';

const BoxSelect = ({
  children,
  isSelected,
  onClick
}: {
  children: JSX.Element;
  isSelected?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Box
      sx={{
        borderRadius: '0.25rem',
        border: isSelected
          ? `1px solid ${Colors.bgGreen}`
          : `1px solid ${Colors.neutral}`,
        background: isSelected ? Colors.secondary.light : Colors.white,
        width: '100%',
        minHeight: '8.5rem',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: onClick ? 'pointer' : 'default',
        transition: 'all 200ms ease'
      }}
      onClick={onClick}>
      {isSelected && (
        <Box
          sx={{
            position: 'absolute',
            top: '0.5rem',
            right: '0.5rem',
            borderRadius: 9999,
            backgroundColor: Colors.bgGreen,
            height: '1.5rem',
            width: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CheckIcon sx={{ color: Colors.white, fontSize: '1rem' }} />
        </Box>
      )}
      {children}
    </Box>
  );
};
export default BoxSelect;
