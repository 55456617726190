import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { saveDefaultAudioProfileForUser } from 'redux/audio-profiles/audio-profiles.action';
import { submitOnboardingData } from 'redux/user/user.action';
import { newUserOnboarding, userOnboarding } from 'redux/user/user.selector';

import RoutesEnum from 'types/routes.enum';

import AimsiAffirm from '../components/AimsiAffirm';
import AudioInitialPreference from '../components/AudioInitialPreference';
import Motivations from '../components/Motivations';
import ShortTermGoals from '../components/ShortTermGoals';
import StartDay from '../components/StartDay';
import StepsView from '../components/StepsView';

const NewOnboarding: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { actualStep, lastStep } = useAppSelector(newUserOnboarding);

  const { audioPreference, shortLongTermGoals, motivations, aims } =
    useAppSelector(userOnboarding);
  const isLastStep = actualStep === lastStep;

  useEffect(() => {
    if (
      isLastStep &&
      audioPreference &&
      shortLongTermGoals &&
      motivations &&
      aims
    ) {
      dispatch(saveDefaultAudioProfileForUser({ id: audioPreference }));
      const onboardingData = {
        shortLongTermGoals,
        motivations,
        aims
      };

      dispatch(submitOnboardingData(onboardingData));
    }
  }, [
    dispatch,
    isLastStep,
    audioPreference,
    shortLongTermGoals,
    motivations,
    aims
  ]);
  useEffect(() => {
    if (actualStep === 6) {
      navigate(RoutesEnum.USER_HOME);
    }
  }, [actualStep, navigate]);

  return (
    <Container
      sx={{
        maxWidth: '80%',
        marginTop: '3rem',
        justifyContent: 'center'
      }}>
      <Grid container>
        <Grid item sx={{ marginBottom: '0.5rem' }} xs={11.5}>
          <Typography
            variant="h1"
            sx={{
              fontSize: 28,
              fontWeight: '500',
              marginBottom: '1.5rem',
              color: Colors.secondary.dark
            }}>
            Welcome to iAffirm
          </Typography>
          <Typography
            variant="h1"
            sx={{
              fontSize: '1rem',
              fontWeight: '400',
              marginBottom: '1.5rem',
              color: Colors.secondary.dark
            }}>
            Let&apos;s review how we can help you achieve your goals
          </Typography>
        </Grid>
      </Grid>

      <StepsView />
      {actualStep === 1 && <StartDay />}
      {actualStep === 2 && <AudioInitialPreference />}
      {actualStep === 3 && <ShortTermGoals />}
      {actualStep === 4 && <Motivations />}
      {actualStep === 5 && <AimsiAffirm />}
    </Container>
  );
};

export default NewOnboarding;
