import useIsLargeScreen from 'hooks/useIsLargeScreen';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Grid, MobileStepper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Colors, evo2FontFamily } from 'design/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export type ContentType = {
  title: string;
  description: string;
  image: string;
  phrase: string;
};

interface ICarrouselProps {
  content: ContentType[];
}

const Carrousel = ({ content }: ICarrouselProps) => {
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = content.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={7000}
        enableMouseEvents>
        {content.map((step, index) => (
          <>
            <Grid
              container
              width={'min-content'}
              height={'100%'}
              spacing={{ sm: 2, xs: 0 }}
              bgcolor={Colors.white}
              justifyContent="space-between"
              borderRadius="0.25rem"
              key={index}
              sx={{
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: {
                  xs: 'column-reverse',
                  sm: 'row'
                },
                padding: { xs: '0', sm: '4rem 3rem' }
              }}>
              <>
                <Grid
                  item
                  sm={7}
                  lg={6}
                  pl={{ xs: 0, sm: 2 }}
                  p={{ xs: '0 1rem', sm: 0 }}>
                  <Typography
                    color={Colors.secondary.main}
                    fontSize="1.5rem"
                    fontWeight={500}
                    fontFamily={evo2FontFamily}
                    my="1rem"
                    sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                    {step.title}
                  </Typography>
                  <Typography
                    color={Colors.black}
                    fontWeight={500}
                    fontFamily={evo2FontFamily}
                    minHeight={!isLargeScreen ? '7rem' : undefined}
                    sx={{
                      textAlign: { xs: 'center', sm: 'start' },
                      paddingBottom: { xs: '2rem', sm: '1rem' }
                    }}>
                    {step.description}
                  </Typography>
                  <Typography
                    color={Colors.black}
                    fontWeight={500}
                    fontFamily={evo2FontFamily}
                    minHeight={!isLargeScreen ? '7rem' : undefined}
                    sx={{
                      textAlign: { xs: 'center', sm: 'start' },
                      paddingBottom: { xs: '2rem', sm: 0 }
                    }}>
                    {`"${step.phrase}"`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={5}
                  lg={6}
                  display="flex"
                  alignItems="center"
                  justifyContent={isLargeScreen ? 'flex-end' : 'center'}>
                  <Box
                    component="img"
                    sx={{
                      borderRadius: isLargeScreen ? '0.5rem' : 0,
                      minHeight: isLargeScreen ? '17rem' : '14rem',
                      height: '100%',
                      maxHeight: isLargeScreen ? '20rem' : '14rem',
                      display: 'block',
                      overflow: 'hidden',
                      width: isLargeScreen ? '26rem' : '20rem',
                      maxWidth: isLargeScreen ? '26rem' : '20rem',
                      objectFit: 'cover'
                    }}
                    loading="eager"
                    src={step.image}
                    alt={step.title}
                  />
                </Grid>
              </>
            </Grid>
          </>
        ))}
      </AutoPlaySwipeableViews>
      {isLargeScreen ? (
        <>
          <Box mt="3.75rem" />
          <Grid container columnSpacing={2}>
            {content.map((step, index) => (
              <Grid
                item
                key={index}
                xs={12 / content.length}
                sx={{ cursor: 'pointer' }}
                onClick={() => setActiveStep(index)}>
                <Typography
                  sx={{
                    borderTop: `.5rem solid ${
                      index <= activeStep
                        ? Colors.greenBlue
                        : Colors.secondary.light
                    }`,
                    transition: 'all 200ms ease-in-out',
                    padding: '1rem .5rem'
                  }}
                  color={
                    index <= activeStep ? Colors.secondary.main : Colors.neutral
                  }
                  fontFamily={evo2FontFamily}
                  fontWeight={500}>
                  {step.title.replace(/:/g, '')}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Box mt="2.25rem" />
          <MobileStepper
            LinearProgressProps={{
              color: 'secondary',
              sx: { width: '100%', borderRadius: '0.25rem' }
            }}
            variant="progress"
            sx={{ backgroundColor: 'transparent' }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<></>}
            backButton={<></>}
          />
        </>
      )}
    </>
  );
};

export default Carrousel;
