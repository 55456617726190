export const mapStripeProductsToPlanOptions = (stripeProducts: any[]) => {
  return stripeProducts.map((product) => {
    let recurrency: string;
    if (product.name.includes('Monthly')) {
      recurrency = 'Monthly';
    } else if (product.name.includes('Quarterly')) {
      recurrency = 'Quarterly';
    } else if (product.name.includes('Annual')) {
      recurrency = 'Yearly';
    } else {
      recurrency = 'Unknown';
    }

    return {
      planId: product.id,
      recurrency,
      price: (product.price / 100) as unknown as string,
      currency: product.currency,
      label:
        recurrency === 'Quarterly'
          ? 'Better value'
          : recurrency === 'Yearly'
          ? 'Best value'
          : ''
    };
  });
};
