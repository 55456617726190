import ChooseYourQualities from 'assets/images/home/HowDoesItWork/Slider/ChooseYourQualities.png';
import CreateYourResources from 'assets/images/home/HowDoesItWork/Slider/CreateYourResources.png';
import CustomizeYourContent from 'assets/images/home/HowDoesItWork/Slider/CustomizeYourContent.png';
import DefineYourFocusImage from 'assets/images/home/HowDoesItWork/Slider/DefineYourFocus.png';
import HarmonizeWithMusic from 'assets/images/home/HowDoesItWork/Slider/HarmonizeWithMusic.png';
import SelectYourTraits from 'assets/images/home/HowDoesItWork/Slider/SelectYourTraits.png';
import SetYourGoals from 'assets/images/home/HowDoesItWork/Slider/SetYoutGoals.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Typography } from '@mui/material';

import CarrouselHowWorks, { ContentType } from 'components/CarrouselHowWorks';

import { Colors, evo2FontFamily } from 'design/theme';

const content: ContentType[] = [
  {
    title: 'Define Your Focus:',
    description:
      'Start by selecting your area of transformation: inner growth, general self-improvement, or professional development.',
    image: DefineYourFocusImage
  },
  {
    title: 'Set Your Goals:',
    description:
      "We'll suggest a range of outcomes aligned with your chosen focus. Pick the ones that resonate with you.",
    image: SetYourGoals
  },
  {
    title: 'Choose Your Qualities:',
    description:
      'Discover the essential qualities needed to achieve your selected goals. Handpick the traits that resonate with your vision.',
    image: ChooseYourQualities
  },
  {
    title: 'Select Your Traits:',
    description:
      'Dive deeper into your personal development by selecting traits that align with your goals.',
    image: SelectYourTraits
  },
  {
    title: 'Harmonize with Music:',
    description:
      'Select the perfect musical backdrop from our recommendations to enhance your journey.',
    image: HarmonizeWithMusic
  },
  {
    title: 'Customize Your Content:',
    description:
      "We'll generate the text, but you have the power to confirm or make edits, ensuring it's uniquely yours.",
    image: CustomizeYourContent
  },
  {
    title: 'Create Your Resources:',
    description:
      "With your input, we'll craft a PDF of your final text, along with a voice recording and a version infused with binaural beats to elevate your transformative experience.",
    image: CreateYourResources
  }
];

const HowDoesItWork = () => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box position="relative">
      <Box
        zIndex={20}
        position="relative"
        sx={{ width: { xs: '100%', sm: '100%' }, margin: 'auto' }}>
        <Container>
          <Typography
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontStyle="italic"
            fontWeight={500}
            color={Colors.secondary.main}
            fontSize={isLargeScreen ? '0.9rem' : '0.8rem'}>
            Get the most out of iAffirm
          </Typography>
          <Typography
            textAlign="center"
            fontFamily={evo2FontFamily}
            fontWeight={700}
            color={Colors.bgGreen}
            fontSize={isLargeScreen ? '2.625rem' : '1.7rem'}
            mb="4.875rem">
            How does it work?
          </Typography>
          <CarrouselHowWorks content={content} />
        </Container>
      </Box>
    </Box>
  );
};
export default HowDoesItWork;
