import BackgroundHomeDesktop from 'assets/images/home/desktop/hero-section-image.png';

import { Box } from '@mui/material';

import HeaderContent from './HeaderContent';

const HomeHeader = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column' as 'column',
        backgroundImage: `url(${BackgroundHomeDesktop})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: 'calc(100vh)'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          backgroundColor: 'rgba(53, 101, 105, 0.33)'
        }}
      />
      <HeaderContent />
    </Box>
  );
};

export default HomeHeader;
