import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect } from 'react';

import { Box, Container, Grid } from '@mui/material';

import AffirmationHeader from 'components/AffirmationHeader';
import { CircularLogoLoader } from 'components/CircularLogoLoader';
import PDFViewerComponent from 'components/PDFrender/PDFViewer';

import { newAffirmationUISelector } from 'modules/new-affirmation/redux/ui/ui.selector';
import { setCustomFlow } from 'modules/new-affirmation/redux/ui/ui.slice';

import { affirmationsTemplate } from 'redux/affirmation-templates/templates.selector';
import { getPdf } from 'redux/affirmation/affirmation.action';
import { affirmationSelector } from 'redux/affirmation/affirmation.selector';
import { getUserAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';

import { AudioProfileAffirmation } from '../AudioProfile_Affirmation';

export type EnumDictionary = { [id: string]: string | number };

const AffirmationReview: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    affirmations,
    createdAffirmation,
    selectedUserAffirmations,
    pdf,
    selectedTemplate,
    customFlow
  } = useAppSelector(affirmationSelector);
  const { customAffirmation } = useAppSelector(newAffirmationUISelector);

  const { selectedAffirmations } = useAppSelector(affirmationsTemplate);

  useEffect(() => {
    if (createdAffirmation && !selectedTemplate && !customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          affirmations: selectedUserAffirmations,
          template: false,
          customFlow: false
        })
      );
    }
    if (selectedTemplate && createdAffirmation && !customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          templateId: selectedTemplate.id,
          affirmations: selectedUserAffirmations,
          template: true,
          customFlow: false
        })
      );
    }

    if (createdAffirmation && customFlow) {
      dispatch(
        getPdf({
          affirmationId: createdAffirmation.affirmationId,
          affirmations: selectedUserAffirmations,
          template: false,
          customFlow: true
        })
      );

      dispatch(setCustomFlow(false));
    }
  }, [
    selectedUserAffirmations,
    createdAffirmation,
    dispatch,
    selectedTemplate,
    customAffirmation,
    customFlow
  ]);

  useEffect(() => {
    dispatch(getUserAudioProfiles());
  }, [dispatch]);

  return (
    <Box
      sx={{
        pb: '2rem',
        minHeight: '100%'
      }}>
      {affirmations && selectedAffirmations.length === 0 && (
        <AffirmationHeader
          title={selectedUserAffirmations[0].affirmationSentence}
          developmentArea={createdAffirmation?.developmentArea}
          createdOn={dayjs().toDate()}
          pdfUrl={pdf}
        />
      )}
      {selectedAffirmations && !affirmations && (
        <AffirmationHeader
          title={selectedTemplate?.title}
          developmentArea={createdAffirmation?.developmentArea}
          createdOn={dayjs().toDate()}
          pdfUrl={pdf}
        />
      )}

      {(affirmations || selectedAffirmations) && (
        <Container>
          <Grid container flexWrap="wrap-reverse" mt="0.75rem" spacing={2}>
            <Grid item xs={12} md={8}>
              <Box
                sx={{
                  borderRadius: '0.25rem'
                }}>
                {pdf ? (
                  <PDFViewerComponent pdfURL={pdf} />
                ) : (
                  <Box
                    mt={2}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'center'
                    }}>
                    <CircularLogoLoader />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <AudioProfileAffirmation />
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default AffirmationReview;
