import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AreaSelected } from 'modules/new-affirmation/types/initialState';

import {
  CreatedVisualization,
  EditMeditation,
  EmotionsBrief,
  Outcome,
  QualityBrief,
  SituationsBrief,
  UserVisualization,
  VisualizationState
} from './types/visualizations';
import {
  createVisualization,
  generatePDFVisualization,
  generateVisualization,
  getEmotionSituationsVisualization,
  getOutcomeQualitiesVisualization,
  getOutcomesVisualization,
  speechVisualization
} from './visualization.action';

const initialState: VisualizationState = {
  createdVisualization: null,
  userVisualization: {},
  loading: false,
  speechLoading: false,
  isSpeechProcessing: false,
  newVisualization: {
    actualStep: 1,
    lastStep: 6,
    home: false,
    customFlow: false
  }
};

const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    cleanVisualization: () => initialState,
    setIsSpeechProcessing: (state, action: PayloadAction<boolean>) => {
      state.isSpeechProcessing = action.payload;
    },
    setCustomFlowVisualization: (state, action: PayloadAction<boolean>) => {
      state.newVisualization.customFlow = action.payload;
    },
    setLastStepVisualization: (state) => {
      state.newVisualization.lastStep = 4;
    },
    setActualStepVisualization: (state, action: PayloadAction<number>) => {
      if (
        action.payload <= state.newVisualization.lastStep &&
        action.payload >= 1
      ) {
        state.newVisualization.actualStep = action.payload;
      }
    },
    setSelectedOutcomeVisualization: (
      state,
      action: PayloadAction<Outcome | undefined>
    ) => {
      state.newVisualization.selectedOutcome = action.payload;
    },
    setAreaSelectedVisualization: (
      state,
      action: PayloadAction<AreaSelected | undefined>
    ) => {
      state.newVisualization.areaSelected = action.payload;
    },
    cleanVisualizationWantWorkOn: (state) => {
      state.newVisualization.selectedQuality = undefined;
      state.newVisualization.selectedEmotion = undefined;
      state.newVisualization.selectedSituation = undefined;
      state.newVisualization.lastStep = initialState.newVisualization.lastStep;
    },
    addQualityVisualization: (state, action: PayloadAction<QualityBrief>) => {
      state.newVisualization.selectedQuality = action.payload;
    },
    addEmotionVisualization: (state, action: PayloadAction<EmotionsBrief>) => {
      state.newVisualization.selectedEmotion = action.payload;
    },
    addSituationVisualization: (
      state,
      action: PayloadAction<SituationsBrief>
    ) => {
      state.newVisualization.selectedSituation = action.payload;
    },
    removeQualityVisualization: (state) => {
      state.newVisualization.selectedQuality = undefined;
    },
    removeEmotionVisualization: (state) => {
      state.newVisualization.selectedEmotion = undefined;
    },
    removeSituationVisualization: (state) => {
      state.newVisualization.selectedSituation = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createVisualization.fulfilled,
        (state, action: PayloadAction<CreatedVisualization>) => {
          state.createdVisualization = action.payload;
          state.loading = false;
        }
      )
      .addCase(createVisualization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(generateVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        generateVisualization.fulfilled,
        (state, action: PayloadAction<EditMeditation>) => {
          state.userVisualization.editMeditation = action.payload;
          state.loading = false;
        }
      )
      .addCase(generateVisualization.rejected, (state) => {
        state.loading = false;
      })
      .addCase(speechVisualization.pending, (state) => {
        state.speechLoading = true;
        state.isSpeechProcessing = true;
      })
      .addCase(
        speechVisualization.fulfilled,
        (state, action: PayloadAction<UserVisualization>) => {
          state.speech = action.payload.visualizationURL;
          state.speechLoading = false;
          const urlRegex = /^https?:\/\//;
          state.isSpeechProcessing = !urlRegex.test(
            action.payload.visualizationURL
          );
        }
      )
      .addCase(speechVisualization.rejected, (state) => {
        state.speechLoading = false;
        state.speech = undefined;
        state.isSpeechProcessing = false;
      })
      .addCase(
        getOutcomesVisualization.fulfilled,
        (state, action: PayloadAction<Outcome[]>) => {
          state.visualizationOutomes = action.payload;

          state.loading = false;
        }
      )
      .addCase(getOutcomesVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOutcomesVisualization.rejected, (state) => {
        state.visualizationOutomes = [];
        state.loading = false;
      })
      .addCase(
        getOutcomeQualitiesVisualization.fulfilled,
        (state, action: PayloadAction<QualityBrief[]>) => {
          state.visualizationQualities = action.payload;
          state.loading = false;
        }
      )
      .addCase(getOutcomeQualitiesVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOutcomeQualitiesVisualization.rejected, (state) => {
        state.visualizationQualities = [];
        state.loading = false;
      })
      .addCase(
        getEmotionSituationsVisualization.fulfilled,
        (state, action: PayloadAction<SituationsBrief[]>) => {
          state.visualizationSituations = action.payload;
          state.loading = false;
        }
      )
      .addCase(getEmotionSituationsVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmotionSituationsVisualization.rejected, (state) => {
        state.visualizationSituations = [];
        state.loading = false;
      })
      .addCase(generatePDFVisualization.fulfilled, (state, action) => {
        state.userVisualization.userVisualization = action.payload;
        state.loading = false;
      })
      .addCase(generatePDFVisualization.pending, (state) => {
        state.loading = true;
      })
      .addCase(generatePDFVisualization.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const {
  cleanVisualization,
  setIsSpeechProcessing,
  setActualStepVisualization,
  setSelectedOutcomeVisualization,
  setCustomFlowVisualization,
  cleanVisualizationWantWorkOn,
  setAreaSelectedVisualization,
  removeQualityVisualization,
  addEmotionVisualization,
  removeEmotionVisualization,
  addQualityVisualization,
  addSituationVisualization,
  removeSituationVisualization,
  setLastStepVisualization
} = visualizationSlice.actions;

export default visualizationSlice.reducer;
