export const countryNameToCode: { [key: string]: string } = {
  Afghanistan: 'AF',
  Algeria: 'DZ',
  Angola: 'AO',
  Australia: 'AU',
  Bangladesh: 'BD',
  Brazil: 'BR',
  Cameroon: 'CM',
  Canada: 'CA',
  China: 'CN',
  Colombia: 'CO',
  'DR Congo': 'CD',
  Egypt: 'EG',
  Ethiopia: 'ET',
  France: 'FR',
  Germany: 'DE',
  Ghana: 'GH',
  India: 'IN',
  Indonesia: 'ID',
  Iran: 'IR',
  Iraq: 'IQ',
  Italy: 'IT',
  Japan: 'JP',
  Kenya: 'KE',
  Madagascar: 'MG',
  Malaysia: 'MY',
  Mexico: 'MX',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Nepal: 'NP',
  Nigeria: 'NG',
  'North Korea': 'KP',
  Pakistan: 'PK',
  Peru: 'PE',
  Philippines: 'PH',
  Poland: 'PL',
  Russia: 'RU',
  'Saudi Arabia': 'SA',
  'South Africa': 'ZA',
  'South Korea': 'KR',
  Spain: 'ES',
  Sudan: 'SD',
  Tanzania: 'TZ',
  Thailand: 'TH',
  Turkey: 'TR',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Kingdom': 'GB',
  'United States': 'US',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Venezuela: 'VE',
  Vietnam: 'VN',
  Yemen: 'YE'
};

export const europeanCountries = [
  'AL',
  'AD',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'XK',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'MD',
  'MC',
  'ME',
  'NL',
  'MK',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SM',
  'RS',
  'SK',
  'SI',
  'ES',
  'SE',
  'CH',
  'UA',
  'VA'
];
