import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import _userService from 'services/user';
import * as Yup from 'yup';

import { Box, Button, TextField, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { setLoginError } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

const RestorePasswordRequest: React.FC = () => {
  const statusReview = false;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorRestore, setErrorRestore] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Usuario es requerido')
      .min(7, 'Usuario incorrecto')
  });

  const handleSubmit = async (values: { email: string }) => {
    try {
      await _userService.changePassword({
        email: values.email
      });
      navigate(RoutesEnum.LOGIN);
    } catch (error) {
      setErrorRestore(true);
    }
  };

  useEffect(() => {
    dispatch(setLoginError(undefined));
  }, [dispatch]);

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '35.375rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: '3.6875rem',
          borderRadius: '1.5rem'
        }}>
        <Formik
          initialValues={{
            email: '',
            password: ''
          }}
          onSubmit={(values) => handleSubmit(values)}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}>
          {(formik) => (
            <Form>
              <Box
                sx={{
                  height: '85vh',
                  maxHeight: '46.9375rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <Typography
                    fontFamily='"Nunito"'
                    fontSize="2rem"
                    fontWeight={500}
                    mb={'1rem'}
                    color={Colors.brown}>
                    Restore password
                  </Typography>
                  <Typography
                    fontFamily='"Nunito"'
                    width={'90%'}
                    fontSize="1.25rem"
                    fontWeight={300}
                    mb="1.5rem"
                    color={Colors.brown}>
                    We&apos;ll send you an email with instructions to restore it
                  </Typography>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      placeholder="Email *"
                      autoComplete="email"
                      color="secondary"
                      sx={{
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.5rem'
                      }}
                      value={formik.values.email}
                      onChange={(e) =>
                        formik.setFieldValue('email', e.target.value)
                      }
                      error={!!formik.errors.email}
                    />
                  </Box>
                  <Box minHeight={'0.625rem'} mt={'0.125rem'}>
                    {!!formik.errors.email && (
                      <Typography
                        color="error"
                        variant="body1"
                        fontFamily='"Nunito"'
                        fontWeight={500}
                        fontSize={'0.875rem'}
                        lineHeight={'0.625rem'}>
                        Invalid email address
                      </Typography>
                    )}
                    {errorRestore && (
                      <Typography
                        color="error"
                        variant="body1"
                        fontWeight={500}
                        fontSize={'0.875rem'}
                        lineHeight={'0.625rem'}>
                        {'User not found'}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    fullWidth
                    color="secondary"
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 1,
                      fontSize: '1rem',
                      minHeight: '3.25rem',
                      textTransform: 'none',
                      fontFamily: '"Nunito"',
                      lineHeight: '1.25rem',
                      letterSpacing: '0.05rem',
                      fontWeight: '500',
                      color: Colors.white
                    }}>
                    {statusReview ? (
                      <CircularLogoLoader />
                    ) : (
                      'Send link to email'
                    )}
                  </Button>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={3}
                    mt={1}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      color={Colors.brown}>
                      {'Did you remember your password?'}
                    </Typography>
                    <Link
                      to={'/login'}
                      style={{
                        textDecoration: 'none',
                        fontFamily: '"Nunito"',
                        fontSize: '1rem',
                        lineHeight: '1.25rem',
                        letterSpacing: '0.006rem'
                      }}>
                      <Typography color="secondary">Login</Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
export default RestorePasswordRequest;
