export interface CountryOption {
  label: string;
}

export const countries: CountryOption[] = [
  { label: 'Afghanistan' },
  { label: 'Algeria' },
  { label: 'Angola' },
  { label: 'Australia' },
  { label: 'Bangladesh' },
  { label: 'Brazil' },
  { label: 'Cameroon' },
  { label: 'Canada' },
  { label: 'China' },
  { label: 'Colombia' },
  { label: 'DR Congo' },
  { label: 'Egypt' },
  { label: 'Ethiopia' },
  { label: 'France' },
  { label: 'Germany' },
  { label: 'Ghana' },
  { label: 'India' },
  { label: 'Indonesia' },
  { label: 'Iran' },
  { label: 'Iraq' },
  { label: 'Italy' },
  { label: 'Japan' },
  { label: 'Kenya' },
  { label: 'Madagascar' },
  { label: 'Malaysia' },
  { label: 'Mexico' },
  { label: 'Morocco' },
  { label: 'Mozambique' },
  { label: 'Myanmar' },
  { label: 'Nepal' },
  { label: 'Nigeria' },
  { label: 'North Korea' },
  { label: 'Pakistan' },
  { label: 'Peru' },
  { label: 'Philippines' },
  { label: 'Poland' },
  { label: 'Russia' },
  { label: 'Saudi Arabia' },
  { label: 'South Africa' },
  { label: 'South Korea' },
  { label: 'Spain' },
  { label: 'Sudan' },
  { label: 'Tanzania' },
  { label: 'Thailand' },
  { label: 'Turkey' },
  { label: 'Uganda' },
  { label: 'Ukraine' },
  { label: 'United Kingdom' },
  { label: 'United States' },
  { label: 'Uruguay' },
  { label: 'Uzbekistan' },
  { label: 'Venezuela' },
  { label: 'Vietnam' },
  { label: 'Yemen' }
];
