import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { Outcome } from 'redux/visualization/types/visualizations';

interface OutcomeButtonProps {
  outcome: Outcome;
  area: string;
  activeOutcomeId: number | string | null;
  handleOutcomeClick: (outcome: Outcome, area: string) => void;
  scrollPosition: number;
  handleScrollDown: () => void;
}

const OutcomeButtonVisualization: React.FC<OutcomeButtonProps> = ({
  outcome,
  area,
  activeOutcomeId,
  handleOutcomeClick,
  scrollPosition,
  handleScrollDown
}) => {
  return (
    <Button
      key={outcome.id}
      variant="outlined"
      sx={{
        position: 'relative',
        marginRight: '1rem',
        flexShrink: 0,
        minWidth: '16.5rem',
        minHeight: '7rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: '"Nunito"',
        fontSize: '1rem',
        color: Colors.black,
        border:
          activeOutcomeId === outcome.id
            ? `2px solid ${Colors.green.main}`
            : `1px solid ${Colors.lightGrey}`,
        background:
          activeOutcomeId === outcome.id
            ? Colors.green.light
            : `linear-gradient(222deg, ${Colors.white} 50.81%, rgba(255, 255, 255, 0) 98.65%)`,
        boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0)',
        '&:hover': {
          border: `2px solid ${Colors.green.main}`,
          background: Colors.green.light,
          boxShadow: '0px 1px 0px 0px rgba(255, 255, 255, 0)'
        },
        '&:active': {
          border: `2px solid ${Colors.green.main}`,
          background: Colors.green.light,
          boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)'
        },
        '&::before, &::after': {
          background: `linear-gradient(to right, ${Colors.shadowWhite}, rgba(255,255,255,0))`,
          display: scrollPosition > 0 ? 'block' : 'none'
        }
      }}
      onClick={() => {
        handleOutcomeClick(outcome, area);
        handleScrollDown();
      }}>
      {outcome.recommendation && (
        <Box
          sx={{
            position: 'absolute',
            top: 12,
            left: 12,
            background:
              activeOutcomeId === outcome.id
                ? Colors.secondary.main
                : Colors.secondary.light,
            borderRadius: '0.5rem',
            zIndex: 2
          }}>
          <Typography
            fontFamily={'"Nunito"'}
            fontSize={'0.75rem'}
            fontWeight={600}
            sx={{
              padding: '0.25rem',
              color:
                activeOutcomeId === outcome.id
                  ? Colors.white
                  : Colors.neutralGrey
            }}>
            Suggested for you
          </Typography>
        </Box>
      )}
      <Typography
        style={{
          textAlign: 'center',
          zIndex: 1
        }}>
        {outcome.outcome}
      </Typography>
    </Button>
  );
};

export default OutcomeButtonVisualization;
