import ProfileQuestions from 'assets/2024_ProfileQuestions.json';
import { ReactComponent as PersonAddIcon } from 'assets/images/UserHeart.svg';
import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Box, Button, Grid, Snackbar, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { getProfile, postProfileUser } from 'redux/user/user.action';
import { userProfileSelector } from 'redux/user/user.selector';
import { setUserProfileModal } from 'redux/user/user.slice';

import { ProfileUserDTO } from 'types/profileUser.interface';
import RoutesEnum from 'types/routes.enum';

import { ProfileArea } from 'utils/ProfileInformation';

import AboutMeProfileSectionsTitle from './AboutMeProfileData/AboutMeProfileSectionsTitle';
import {
  QuestionsMapping,
  transformProfileToMap
} from './AboutMeProfileData/MapSchema';
import QuestionsRenderComponent from './AboutMeProfileData/QuestionsRenderComponent';

export const AboutMeProfile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const originalProfileInfo = useAppSelector(userProfileSelector);
  const profileAnswersMap = transformProfileToMap(originalProfileInfo);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [answers, setAnswers] = useState<{ [key: string]: string | undefined }>(
    profileAnswersMap
  );

  const handleSubmit = async () => {
    await dispatch(postProfileUser(answers as unknown as ProfileUserDTO));
    await dispatch(getProfile());
    setShowSuccessMessage(true);
    setHasChanges(false);
  };

  const handleSelectionChange = (
    areaQuestion: string,
    questionLabel: string,
    answerValue: string
  ) => {
    const key = QuestionsMapping[questionLabel];
    setAnswers((prev) => ({
      ...prev,
      [key]: answerValue
    }));
    setHasChanges(true);
  };

  const isSectionComplete = (profileStage: ProfileArea): boolean => {
    return profileStage.questions.every((question) => {
      const answerKey = QuestionsMapping[question.label];
      const answer = answers[answerKey];
      return answer !== undefined && answer.trim() !== '';
    });
  };

  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          overflow: 'auto'
        }}>
        {/* Scrollable Content Area */}
        <Grid
          item
          sm={8}
          sx={{
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
          }}>
          <Box
            sx={{
              maxHeight: '33rem',
              overflowY: 'auto',
              width: '100%',
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              scrollbarWidth: 'none',
              '-ms-overflow-style': 'none'
            }}>
            <Formik
              initialValues={profileAnswersMap}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}>
              <Form>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}>
                  <Box>
                    <Grid container>
                      {/* Notification Message */}
                      <Grid
                        item
                        xs={12}
                        sx={{
                          margin: '0',
                          display: 'flex',
                          justifyContent: 'center'
                        }}>
                        <Box
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                            backgroundColor: 'rgba(53, 101, 105, 0.2)',
                            width: '100%',
                            padding: '1rem'
                          }}>
                          <PersonAddIcon sx={{ color: '#427E83' }} />
                          <Typography color={'#427E83'} fontSize={'0.9rem'}>
                            Complete your profile to get fully personalized
                            profile
                          </Typography>
                        </Box>
                      </Grid>
                      {ProfileQuestions.map((profileStage, index) => (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sx={{ margin: '0 1rem' }}>
                          <QuestionsRenderComponent
                            element={profileStage}
                            originalAnswers={profileAnswersMap}
                            onChange={handleSelectionChange}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              </Form>
            </Formik>
          </Box>
        </Grid>
        {isLargeScreen && (
          <Grid item sm={4} sx={{ padding: '1rem' }}>
            {ProfileQuestions.map((profileStage, index) => (
              <AboutMeProfileSectionsTitle
                key={index}
                title={profileStage.title}
                index={index}
                completeSection={isSectionComplete(profileStage)}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <Box sx={{ margin: '1rem' }}>
        <Grid
          item
          sm={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem'
          }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              marginRight: '0.5rem',
              fontSize: '0.875rem',
              textTransform: 'none',
              fontFamily: '"Nunito"',
              lineHeight: '1.15rem',
              letterSpacing: '0.1px',
              fontWeight: '500',
              border: 'none'
            }}
            onClick={() => {
              dispatch(setUserProfileModal(false));
              navigate(RoutesEnum.USER_HOME);
            }}>
            Close
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!hasChanges}
            sx={{
              fontSize: '0.875rem',
              textTransform: 'none',
              fontFamily: '"Nunito"',
              lineHeight: '1.15rem',
              letterSpacing: '0.1px',
              fontWeight: '500',
              color: Colors.white
            }}
            onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={() => setShowSuccessMessage(false)}>
        <Alert
          onClose={() => setShowSuccessMessage(false)}
          severity="success"
          sx={{ width: '100%' }}>
          Profile updated successfully
        </Alert>
      </Snackbar>
    </>
  );
};
