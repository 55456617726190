import React from 'react';

import { Box, Modal } from '@mui/material';

import { AudioProfileAffirmation } from '../AudioProfile_Affirmation';

interface AudioModalProps {
  openAudioModal: boolean;
  setOpenAudioModal: (open: boolean) => void;
}

export const AudioModal: React.FC<AudioModalProps> = ({
  openAudioModal,
  setOpenAudioModal
}) => {
  return (
    <Modal
      open={openAudioModal}
      onClose={() => setOpenAudioModal(false)}
      aria-labelledby="user-profile-modal-title"
      aria-describedby="user-profile-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: '80%',
          width: '100%',
          overflowY: 'auto',
          p: 4,
          outline: 'none'
        }}>
        <AudioProfileAffirmation />
      </Box>
    </Modal>
  );
};
