import { ReactComponent as UserHeart } from 'assets/images/UserHeart.svg';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';

import { Colors } from 'design/theme';

import { waitingList } from 'redux/user/user.action';
import { userUiSelector } from 'redux/user/user.selector';
import { clearWaitingListError } from 'redux/user/user.slice';

import { CircularLogoLoader } from './CircularLogoLoader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '55rem',
  background: Colors.white,
  p: 4,
  borderRadius: '10px',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
  maxHeight: '100vh'
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  background: Colors.white,
  boxShadow: 24,
  p: 4,
  borderRadius: '0.5rem',
  padding: '0',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100vw'
};

interface WaitingModalProps {
  open: boolean;
  handleClose: () => void;
}
interface FormValues {
  name: string;
  email: string;
  previousExperience: string;
  goals: string;
  value: string;
}

const initialValues: FormValues = {
  name: '',
  email: '',
  previousExperience: '',
  goals: '',
  value: ''
};

export const WaitingListModal: React.FC<WaitingModalProps> = ({
  open,
  handleClose
}) => {
  const dispatch = useAppDispatch();
  const isLargeScreen = useIsLargeScreen();
  const { loading, waitingListError } = useAppSelector(userUiSelector);
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const invitationSchema = Yup.object({
    name: Yup.string().required('Please enter your name.'),
    email: Yup.string()
      .email("Doesn't look like a valid email.")
      .required('We need your email to send the invite.'),
    previousExperience: Yup.string()
      .oneOf(['yes', 'no', 'Id prefer not to say'], 'Invalid option')
      .required('Please choose an option.'),
    value: Yup.string()
      .oneOf(['$15 per month', '$10 per month', 'Other'], 'Invalid option')
      .required('Please choose an option.'),
    goals: Yup.string().required(
      'Please share your specific goals for personal growth and success.'
    )
  });
  const isTablet = useMediaQuery('(min-width: 850px)');
  const handleInvitation = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    try {
      await dispatch(waitingList(values)).unwrap();
      setShowThankYou(true);
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const handleCloseModel = () => {
    setShowThankYou(false);
    dispatch(clearWaitingListError());
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModel}
      aria-labelledby="waiting-list-modal-title"
      aria-describedby="waiting-list-modal-description"
      sx={{
        borderRadius: '.7rem',
        '&::-webkit-scrollbar': {
          display: 'none',
          scrollbarWidth: 'none',
          width: '8px',
          borderRadius: '6px'
        }
      }}>
      <Box
        sx={{
          ...(isTablet ? style : styleMobile),
          '&::-webkit-scrollbar': {
            display: 'none',
            scrollbarWidth: 'none',
            width: '8px',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            display: 'none',
            backgroundColor: '#888',
            borderRadius: '60px',
            width: '2px'
          }
        }}>
        {loading ? (
          <Box
            sx={{
              alignSelf: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '2rem',
              minHeight: '15rem'
            }}>
            <CircularLogoLoader />
          </Box>
        ) : showThankYou ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              minHeight: isLargeScreen ? '15rem' : '10rem'
            }}>
            <Typography
              fontFamily={'"Exo 2"'}
              variant="h4"
              color={'secondary'}
              sx={{ mb: 2 }}>
              Thank You
            </Typography>
            <Typography fontFamily={'"Exo 2"'} fontSize={'1.25rem'}>
              Your submission has been received.
            </Typography>
          </Box>
        ) : waitingListError ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
              minHeight: '30rem',
              padding: '3rem'
            }}>
            <Typography
              fontFamily='"Exo 2"'
              variant="h4"
              color="secondary"
              sx={{ mb: 2 }}>
              Uh-oh!
            </Typography>
            <Typography fontFamily={'"Exo 2"'} fontSize="1.25rem">
              It seems like we&apos;ve crossed paths before. You&apos;re already
              on the list, eagerly awaiting what&apos;s next. We&apos;re just as
              excited to share the journey with you! Stay tuned for updates.
            </Typography>
          </Box>
        ) : (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={invitationSchema}
              onSubmit={handleInvitation}>
              {({ isSubmitting, isValid, dirty, touched }) => (
                <Form>
                  <Grid
                    container
                    rowGap={2}
                    sx={{
                      maxHeight: '40rem',
                      overflow: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                        borderRadius: '6px'
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#D9D9D9',
                        borderRadius: '6px',
                        width: '8px'
                      }
                    }}>
                    <Grid item xs={12} sx={{ padding: '0.5rem 1rem' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          padding: '0.5rem 0'
                        }}>
                        <Typography
                          fontFamily={'"Nunito"'}
                          fontSize={isLargeScreen ? '1.15rem' : '1rem'}
                          color={Colors.secondary.dark}>
                          iAffirm is an AI generated platform designed to
                          empower individuals like you to unlock your full
                          potential through the power of affirmations,
                          visualizations, and meditations. <br /> <br />
                          Our intention is simple: to provide you with the
                          tools, resources, and support needed to thrive on your
                          journey towards your ultimate you.
                        </Typography>
                        <IconButton onClick={handleClose} size="small">
                          <Close />
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          background: Colors.secondary.light,
                          marginTop: '1rem',
                          minHeight: '2rem',
                          display: 'flex',
                          padding: '0.75rem',
                          paddingLeft: '2rem',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          alignContent: 'center',
                          gap: '0.75rem'
                        }}>
                        <UserHeart />
                        <Typography
                          fontFamily={'"Nunito"'}
                          fontSize={'1rem'}
                          fontWeight={500}
                          sx={{ color: Colors.secondary.main }}>
                          {' '}
                          Complete these four short questions to join the
                          exclusive early access list
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingLeft: '1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1.25rem'}
                        fontWeight={600}
                        sx={{ color: Colors.secondary.dark }}>
                        {' '}
                        Personal info
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ padding: '0 1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1rem'}
                        fontWeight={500}
                        sx={{
                          color: Colors.secondary.dark,
                          marginBottom: '0.25rem'
                        }}>
                        What is your name?
                      </Typography>
                      <Field
                        fontFamily={'"Exo 2"'}
                        as={TextField}
                        label="Name"
                        name="name"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        sx={{ marginTop: '0.75rem' }}
                      />
                    </Grid>

                    <Grid item xs={12} lg={6} sx={{ padding: '0 1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1rem'}
                        fontWeight={500}
                        sx={{
                          color: Colors.secondary.dark,
                          marginBottom: '0.25rem'
                        }}>
                        What is your email address?
                      </Typography>
                      <Field
                        fontFamily={'"Exo 2"'}
                        as={TextField}
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: '0.75rem' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: `1px solid ${Colors.lightGrey}`,
                        margin: '0 0.75rem'
                      }}></Grid>

                    {/** Experience */}
                    <Grid item xs={12} sx={{ paddingLeft: '1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1.25rem'}
                        fontWeight={600}
                        sx={{ color: Colors.secondary.dark }}>
                        {' '}
                        Previous experience
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0 1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1rem'}
                        fontWeight={500}
                        sx={{
                          color: Colors.secondary.dark,
                          marginBottom: '0.25rem'
                        }}>
                        Do you currently use a platform like iAffirm, such as
                        Headspace, Calm, or Aura?
                      </Typography>
                      <Field
                        name="previousExperience"
                        variant="outlined"
                        fullWidth
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ fontFamily: '"Exo 2"', marginTop: '0.75rem' }}
                        as={Select}>
                        <MenuItem disabled value="Choose an option">
                          Choose an option
                        </MenuItem>
                        <MenuItem value="yes">Yes</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                        <MenuItem value="Id prefer not to say">
                          {"I'd prefer not to say"}
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: `1px solid ${Colors.lightGrey}`,
                        margin: '0 0.75rem'
                      }}></Grid>

                    {/** Goals */}
                    <Grid item xs={12} sx={{ paddingLeft: '1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1.25rem'}
                        fontWeight={600}
                        sx={{ color: Colors.secondary.dark }}>
                        {' '}
                        Personal Goals and Aspirations
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0 1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1rem'}
                        fontWeight={500}
                        sx={{
                          color: Colors.secondary.dark,
                          marginBottom: '0.25rem'
                        }}>
                        What are your goals or aspirations for using iAffirm?
                      </Typography>
                      <Field
                        fontFamily={'"Exo 2"'}
                        as={TextField}
                        label="Please share your specific goals for personal growth and success"
                        name="goals"
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: '0.75rem' }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: `1px solid ${Colors.lightGrey}`,
                        margin: '0 0.75rem'
                      }}></Grid>

                    {/** Value */}
                    <Grid item xs={12} sx={{ paddingLeft: '1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1.25rem'}
                        fontWeight={600}
                        sx={{ color: Colors.secondary.dark }}>
                        {' '}
                        Value
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '0 1rem' }}>
                      <Typography
                        fontFamily={'"Nunito"'}
                        fontSize={'1rem'}
                        fontWeight={500}
                        sx={{
                          color: Colors.secondary.dark,
                          marginBottom: '0.25rem'
                        }}>
                        How much are you willing to pay per month for access to
                        iAffirms platform?
                      </Typography>
                      <Field
                        name="value"
                        variant="outlined"
                        fullWidth
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ fontFamily: '"Exo 2"', marginTop: '0.75rem' }}
                        as={Select}>
                        <MenuItem disabled value="Choose an option">
                          Choose an option
                        </MenuItem>
                        <MenuItem value="$10 per month">$10 per month</MenuItem>
                        <MenuItem value="$15 per month">$15 per month</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Field>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: `1px solid ${Colors.lightGrey}`,
                        margin: '0 0.75rem'
                      }}></Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '1rem',
                        padding: '1rem'
                      }}>
                      <Button
                        variant="outlined"
                        onClick={handleClose}
                        color="secondary"
                        disabled={isSubmitting}
                        sx={{ fontFamily: '"Exo 2"' }}>
                        Close
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !Object.keys(touched).length
                        }
                        sx={{ fontFamily: '"Exo 2"' }}>
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Modal>
  );
};
