import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { useMediaQuery } from '@mui/material';

import WaitScreen from './components/WaitScreen';
import Footer from './components/compatibles/Footer';
import FrequentlyAskedQuestions from './components/compatibles/FrequentlyAskedQuestions';
import HowDoesItWork from './components/compatibles/HowDoesItWork';
import OurRecommendedAffirmations from './components/compatibles/OurRecommendedAffirmations';
import Roadmap from './components/compatibles/Roadmap';
import RoadmapMobile from './components/compatibles/RoadmapMobile';
import ThePowerOfAffirmations from './components/compatibles/ThePowerOfAffirmations';
import ElevateHumanityDesktop from './components/desktop/ElevateHumanity';
import HomeHeader from './components/desktop/Header/Header';
import ElevateHumanityMobile from './components/mobile/ElevateHumanityMobile';
import HomeHeaderMobile from './components/mobile/HomeHeaderMobile';

const Home: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const isTablet = useMediaQuery('(min-width: 1000px)');

  return (
    <div style={{ maxWidth: '100vw', overflowX: 'hidden' }}>
      {isLargeScreen ? <HomeHeader /> : <HomeHeaderMobile />}
      {isTablet ? <ElevateHumanityDesktop /> : <ElevateHumanityMobile />}
      <HowDoesItWork />
      <OurRecommendedAffirmations />
      <ThePowerOfAffirmations />
      {isTablet ? <Roadmap /> : <RoadmapMobile />}
      <FrequentlyAskedQuestions />
      <Footer />
      <WaitScreen />
    </div>
  );
};

export default Home;
