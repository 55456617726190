import { Box, Skeleton } from '@mui/material';

import { Colors } from 'design/theme';

interface ListItemSelectProps {
  isLoading?: boolean;
}

const ListItemSelectLoader = ({ isLoading }: ListItemSelectProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '0.25rem',
        border: `1px solid ${Colors.bgGreen}`,
        backgroundColor: 'rgba(53, 101, 105, 0.2)',
        padding: '.5rem',
        mb: '.5rem',
        height: 'min-content',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%'
      }}>
      {isLoading && <Skeleton variant="text" width="100%" />}
    </Box>
  );
};

export default ListItemSelectLoader;
