import { useEffect } from 'react';
import ScrollCarousel from 'scroll-carousel';

import { Box } from '@mui/material';

import { Colors } from 'design/theme';

import TextAnimation from './TextAnimation';

const TextAnimationContainer = ({ barsHeight }: { barsHeight: number }) => {
  useEffect(() => {
    new ScrollCarousel('.my-carousel', {
      autoplay: true,
      autoplaySpeed: 7,
      smartSpeed: true
    });
  }, []);

  return (
    <Box
      sx={{
        minHeight: `${barsHeight * 0.5}px`,
        backgroundColor: Colors.bgGreen,
        width: '100%',
        zIndex: 9,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '13rem'
      }}>
      <Box
        className="my-carousel"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          width: '100%',
          display: 'flex'
        }}>
        <TextAnimation
          firstText="Rewrite your inner narrative, synchronize"
          secondText="with your goals, and witness your evolution."
        />
        <TextAnimation
          firstText="Every thought and action will amplify what's"
          secondText="already possible within you."
        />
        <TextAnimation
          firstText="Start your transformative journey today and"
          secondText="reveal your truest self."
        />
      </Box>
    </Box>
  );
};
export default TextAnimationContainer;
