export enum AudioDurations {
  FIVE_MINUTES = 5,
  EIGHT_MINUTES = 8,
  TEN_MINUTES = 10,
  FIFTEEN_MINUTES = 15,
  SIXTEEN_MINUTES = 16,
  TWENTY_MINUTES = 20,
  TWENTYFOUR_MINUTES = 24,
  THIRTY_MINUTES = 30,
  FORTY_FIVE_MINUTES = 45,
  ONE_HOUR = 60
}

export const audioDurationOptions: { [key in AudioDurations]: string } = {
  [AudioDurations.FIVE_MINUTES]: '5 minutes',
  [AudioDurations.EIGHT_MINUTES]: '8 minutes',
  [AudioDurations.TEN_MINUTES]: '10 minutes',
  [AudioDurations.FIFTEEN_MINUTES]: '15 minutes',
  [AudioDurations.SIXTEEN_MINUTES]: '16 minutes',
  [AudioDurations.TWENTY_MINUTES]: '20 minutes',
  [AudioDurations.TWENTYFOUR_MINUTES]: '24 minutes',
  [AudioDurations.THIRTY_MINUTES]: '30 minutes',
  [AudioDurations.FORTY_FIVE_MINUTES]: '45 minutes',
  [AudioDurations.ONE_HOUR]: '1 hour'
};
