import * as Yup from 'yup';

import { NewVisualization } from '../types/new-visualization.types';

export const newVisualizationFormValidation =
  Yup.object<NewVisualization>().shape({
    quality: Yup.string()
      .min(30, 'Tell us more')
      .required('Please, enter how you would like to visualize yourself'),
    emotion: Yup.string()
      .min(3, 'Please, enter how you would like to feel')
      .required('Please, enter how you would like to feel'),
    situation: Yup.string()
      .min(4, 'Please, enter how you would like to feel')
      .required('Please, enter how you would like to feel')
  });
