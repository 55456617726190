import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import { Box, Button } from '@mui/material';

import { Colors } from 'design/theme';

interface SimpleAudioPlayerProps {
  url: string;
}

const MinimalAudioUrl: React.FC<SimpleAudioPlayerProps> = ({ url }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState<boolean>(false);

  const togglePlayPause = () => {
    if (url && url !== '') {
      setPlaying(!playing);
    } else {
      setPlaying(false);
    }
  };

  useEffect(() => {
    if (url === '') {
      setPlaying(false);
    }
  }, [url]);

  return (
    <div>
      <Button
        variant="outlined"
        sx={{
          border: 'none',
          marginTop: '0.25rem',
          width: '100%',
          justifyContent: 'space-between',
          padding: '0.25rem',
          color: Colors.secondary.main,
          '&:hover': {
            fontWeight: '700',
            backgroundColor: 'transparent',
            border: 'none'
          },
          '&:active': {
            fontWeight: '700'
          },
          display: 'flex',
          '&.Mui-disabled': {
            color: Colors.neutralGrey,
            border: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }
        }}
        onClick={togglePlayPause}
        disabled={!url || url === ''}>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: playing ? 'flex-start' : 'space-between'
          }}>
          {playing ? <span>Stop Sample</span> : <span>Play Sample</span>}
          {playing && (
            <Box
              sx={{
                flexGrow: 1,
                textAlign: 'right',
                color: playing ? Colors.neutralGrey : Colors.secondary.main
              }}>
              Playing...
            </Box>
          )}
        </Box>
      </Button>

      {url && (
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          volume={1}
          controls={true}
          width="0"
          height="0"
          onEnded={() => setPlaying(false)}
        />
      )}
    </div>
  );
};

export default MinimalAudioUrl;
