import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useState } from 'react';

import { Button, Grid, Skeleton, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import BoxSelect from 'modules/new-affirmation/components/BoxSelect';

import { getAttributeByGoals } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import {
  addGoalsMeditation,
  setActualStepMeditation
} from 'redux/meditations/meditation.slice';
import { Goals } from 'redux/meditations/types/meditation.entity';

const GoalMeditation = () => {
  const dispatch = useAppDispatch();
  const [customGoal, setCustomGoal] = useState<string | undefined>(undefined);
  const [activeGoalId, setActiveGoalId] = useState<number | null>(null);
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const [selectedGoal, setSelectedGoal] = useState<any>(null);
  let goalsByOutcome;

  const { actualStep, selectedQuality } =
    useAppSelector(medidationSelector)?.newMeditation;

  const { loading, qualityByOutcome } = useAppSelector(medidationSelector);

  const qualities = qualityByOutcome?.filter(
    (item) => item.id === selectedQuality?.id
  );
  if (qualities) {
    goalsByOutcome = qualities[0]?.goals;
  }
  // eslint-disable-next-line no-lone-blocks
  {
    /*
      const handleUpdateQuality = (goal: string) => {
      const errorMessage = isBannedWord(goal);
      if (errorMessage) {
        setQualityError(errorMessage);
      } else {
        setQualityError('');
        setCustomGoal(goal);
        setShowTextField(false);
      }
    };

    const handleRemoveQuality = () => {
      setCustomGoal(undefined);
      setQualityError('');
      setActiveGoalId(null);
      setShowTextField(false);
      setSelectedGoal(null);
      dispatch(removeQualityMeditation());
    };
    */
  }

  //   const handleEditQuality = () => {
  //     setShowTextField(true);
  //   };

  //   const handleSaveEditedQuality = () => {
  //     handleUpdateQuality(customGoal ? customGoal : '');
  //   };

  const handleBoxSelectClick = (goal: Goals) => {
    setActiveGoalId(goal.id ? goal.id : 0);
    setCustomGoal(undefined);
    setShowTextField(false);
    setSelectedGoal(goal);
  };

  const handleNextClick = () => {
    if (customGoal) {
      dispatch(addGoalsMeditation({ goal: customGoal }));
    } else if (selectedGoal) {
      dispatch(addGoalsMeditation(selectedGoal));
    }
    dispatch(getAttributeByGoals(selectedGoal.id));
    dispatch(setActualStepMeditation(actualStep + 1));
  };

  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.5rem'}
            color={Colors.black}>
            Choose a Goal
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select one Goal from the list or add a custom one.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {loading
          ? Array(4)
              .fill('')
              .map((_, i) => (
                <Grid item xs={6} md={3} key={i}>
                  <Skeleton height="8.75rem" />
                </Grid>
              ))
          : goalsByOutcome &&
            goalsByOutcome.map((goal, i) => (
              <Grid item xs={6} md={4} key={i}>
                <BoxSelect
                  onClick={() => {
                    handleBoxSelectClick(goal);
                    handleScrollDown();
                  }}
                  isSelected={activeGoalId === goal.id}>
                  <Typography sx={{ textAlign: 'center' }}>
                    {goal.goal}
                  </Typography>
                </BoxSelect>
              </Grid>
            ))}
      </Grid>

      {/* {showTextField && (
        <Grid item xs={12} sx={{ marginTop: '1.25rem' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: `1px solid ${Colors.lightGrey}`,
              padding: '8px',
              width: '98%'
            }}>
            <TextField
              fullWidth
              label="Type a quality"
              variant="standard"
              value={customQuality}
              onChange={(e) => {
                const newValue = e.target.value.slice(0, 20);
                setCustomQuality(newValue);
              }}
              size="small"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  maxLength: 20
                }
              }}
              sx={{
                fontFamily: '"Nunito"',
                '& label.Mui-focused': {
                  color: `${Colors.secondary.main}`
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: `${Colors.secondary.main}`
                },
                height: 'min-content',
                color: Colors.lightGrey
              }}
              error={!!qualityError}
              helperText={qualityError}
            />

            <IconButton color="secondary" onClick={handleSaveEditedQuality}>
              <CheckIcon />
            </IconButton>
            <IconButton color="error" onClick={handleRemoveQuality}>
              <Trash />
            </IconButton>
          </Box>
        </Grid>
      )} */}

      {/* {customQuality && !editingQuality && !showTextField && (
        <Grid item xs={12} sx={{ marginTop: '1.25rem' }}>
          <Box
            sx={{
              backgroundColor: Colors.secondary.light,
              marginTop: '0.25rem',
              border: `1px solid ${Colors.secondary.main}`,
              padding: '8px',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '5rem'
            }}>
            <Box sx={{ width: '98%' }}>
              <Typography
                fontFamily='"Nunito"'
                fontSize={'1.25rem'}
                lineHeight={'1.5rem'}
                sx={{ color: Colors.secondary.main, padding: '1rem' }}>
                {customQuality}
              </Typography>
            </Box>
            <IconButton color="primary" onClick={handleEditQuality}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={handleRemoveQuality}>
              <Trash />
            </IconButton>
          </Box>
        </Grid>
      )} */}
      {/**      <Grid item>
        <Button
          sx={{ marginTop: '0.5rem' }}
          onClick={() => {
            setShowTextField(true);
            setSelectedQuality(null);
            setActiveQualityId(null);
          }}
          disabled={true}>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.25rem'}
            fontWeight={500}
            sx={{ color: Colors.bgGreen }}>
            + Add other
          </Typography>
        </Button>
      </Grid> */}

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStepMeditation(actualStep - 1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            disabled={!selectedGoal && !customGoal}
            onClick={handleNextClick}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GoalMeditation;
