import { createAsyncThunk } from '@reduxjs/toolkit';
import _userService from 'services/user';

import loginRequestType from 'types/loginRequest.type';
import { ProfileUserDTO } from 'types/profileUser.interface';
import signupRequestType from 'types/signupRequest.type';
import { User } from 'types/user.entity';

import { PromotionData, UserOnboardingData } from './types/userState.interface';

export const userLogin = createAsyncThunk(
  'user/login',
  async (data: loginRequestType) => {
    return _userService.login(data);
  }
);

export const userSignUp = createAsyncThunk(
  'user/signup',
  async (data: signupRequestType) => {
    return _userService.signup(data);
  }
);
export const inviteUser = createAsyncThunk(
  'user/invite',
  async (data: { inviteeEmail: string; inviteeName: string }) => {
    return _userService.invite(data);
  }
);
export const waitingList = createAsyncThunk(
  'user/waitingList',
  async (
    data: {
      email: string;
      name: string;
      previousExperience: string;
      goals: string;
      value: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await _userService.waitingList(data);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userHome = createAsyncThunk(
  'user/home',
  async (offset: number) => {
    return (await _userService.getUserHome(offset)).data.data;
  }
);
export const getUserInfo = createAsyncThunk('user/getUserInfo', async () => {
  return (await _userService.getUserInfo()).data.data;
});

export const updateUser = createAsyncThunk(
  'user/update',
  async (data: Partial<User>, { rejectWithValue }) => {
    try {
      const response = await _userService.updateUser(data);
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const restorePassword = createAsyncThunk(
  'user/restore-password',
  async (data: { password: string; newPassword: string }) => {
    return (await _userService.updatePassword(data)).data.data;
  }
);

export const recoverPassword = createAsyncThunk(
  'user/restore-password',
  async (data: { password: string; token: string }) => {
    return (await _userService.restorePassword(data)).data.data;
  }
);

export const submitOnboardingData = createAsyncThunk(
  'user/onboarding',
  async (data: UserOnboardingData) => {
    const response = await _userService.addOnboardingData(data);
    return response.data.data;
  }
);

export const getProfile = createAsyncThunk('user/getProfile', async (_) => {
  const response = await _userService.getProfile();
  return response.data.data;
});

export const postProfileUser = createAsyncThunk(
  'user/postProfileUser',
  async (data: ProfileUserDTO) => {
    const response = await _userService.postProfileUser(data);
    return response.data.data;
  }
);

export const applyPromotionCode = createAsyncThunk(
  'user/apply-promotion-code',
  async (data: PromotionData) => {
    const response = await _userService.applyPromotionCode(data);
    return response.data.data;
  }
);
