import { ReactComponent as CenterLeaf } from 'assets/images/home/desktop/leaf_center.svg';
import { ReactComponent as LeftLeaf } from 'assets/images/home/desktop/leaf_left.svg';
import { ReactComponent as RightLeaf } from 'assets/images/home/desktop/leaf_right.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const OurRecommendedAffirmations = () => {
  const isLargeScreen = useIsLargeScreen();
  const isTablet = useMediaQuery('(min-width: 960px)');
  const isSmallLaptop = useMediaQuery('(min-width: 1260px)');
  return (
    <>
      <Box mt="10rem" />
      <Container
        sx={{
          p: { xs: 0, sm: undefined },
          marginTop: '10rem',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative'
        }}>
        <Grid
          container
          spacing={4}
          sx={{
            position: 'relative',
            overflowX: isSmallLaptop ? undefined : 'hidden',
            overflowY: isSmallLaptop ? undefined : 'hidden',
            background: 'linear-gradient(180deg, #356569 0%, #1C3638 100%)',
            p: { xs: '1rem 1rem 3rem 1rem', sm: '1rem 6.5rem 11rem 6.5rem' },
            borderRadius: isLargeScreen ? '0.70rem' : '1rem',
            mb: isLargeScreen ? undefined : '0',
            maxWidth: isTablet ? '80vw' : '100vw',
            marginLeft: isLargeScreen ? 0 : '0'
          }}>
          <Box
            sx={{
              margin: 'auto',
              position: 'absolute',
              top: '-7%',
              left: '-10%',
              zIndex: '1'
            }}>
            <RightLeaf />
          </Box>
          <Box
            sx={{
              margin: 'auto',
              maxWidth: '7%',
              position: 'absolute',
              top: '0%',
              left: '84%',
              zIndex: '0'
            }}>
            <LeftLeaf />
          </Box>
          <Box
            sx={{
              margin: 'auto',
              maxWidth: '10%',
              position: 'absolute',
              top: '75%',
              left: '38%',
              zIndex: '0'
            }}>
            <CenterLeaf />
          </Box>

          <Grid
            item
            xs={12}
            padding={'1rem'}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              position: 'relative',
              zIndex: '1'
            }}>
            <Typography
              color="whitesmoke"
              textAlign="center"
              fontSize={{ xs: '1.75rem', sm: '2.625rem' }}
              fontWeight={700}
              fontFamily={evo2FontFamily}
              sx={{ zIndex: '2' }}>
              Our 10 recommended
              <br />
              affirmations
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              My energy is limitless and fuels my passion
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              My energy is contagious, inspiring others around me
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}
              sx={{ zIndex: '2', position: 'relative' }}>
              I am not afraid to hustle hard and dream big.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}
              sx={{ zIndex: '2', position: 'relative' }}>
              Every setback is a setup for a greater comeback
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              Every day, I find reasons to smile and be joyful
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              Happiness is an inner light, and I let it shine brightly
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              Together, we create memories that last a lifetime
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}
              sx={{ zIndex: '2', position: 'relative' }}>
              With friends and family, every moment becomes an adventure.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}
              sx={{ zIndex: '2', position: 'relative' }}>
              I am anchored in strength and serenity
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingRight: isLargeScreen ? 'undefined' : '2rem',
              zIndex: '1'
            }}>
            <Typography
              color={Colors.secondary.light}
              fontFamily={evo2FontFamily}
              fontSize={{ xs: '0.9rem', md: '1.1rem' }}
              minHeight="1rem"
              textAlign={isLargeScreen ? 'start' : 'center'}>
              I am the embodiment of strength and composure
            </Typography>
          </Grid>
        </Grid>
        {!isLargeScreen && <Box sx={{ margin: 'auto', maxWidth: '10%' }}></Box>}
      </Container>
    </>
  );
};
export default OurRecommendedAffirmations;
