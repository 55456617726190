import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { newUserOnboarding } from 'redux/user/user.selector';
import { setActualStep, setAims } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

import { ProfessionalChalleges } from 'utils/professionalChallenges';

const AimsiAffirm = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actualStep } = useAppSelector(newUserOnboarding);
  const [otherGoal, setOtherGoal] = useState<string>('');
  const [selectedGoal, setSelectedGoal] = useState<string>('');
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);

  const handleGoalSelect = (goal: string) => {
    setSelectedGoal(goal);
    setOtherGoal('');
    setIsOtherSelected(false);
  };

  const handleOtherSelect = () => {
    setSelectedGoal('Other');
    setIsOtherSelected(true);
  };

  const confirmOtherGoal = () => {
    if (otherGoal.trim() !== '') {
      setSelectedGoal(otherGoal.trim());
      setIsOtherSelected(false);
    }
  };

  const buttonStyle = (value: string) => ({
    height: '2.5rem',
    justifyContent: 'flex-start',
    borderRadius: '0.25rem',
    boxShadow: `0px 1px 1px -1px ${Colors.neutralGrey}, 0px 2px 2px -2px ${Colors.neutralGrey}`,
    width: '100%',
    background:
      selectedGoal === value && selectedGoal !== ''
        ? Colors.green.light
        : Colors.white,
    color:
      selectedGoal === value && selectedGoal !== ''
        ? Colors.green.main
        : Colors.green.dark,
    border: `1px solid ${
      selectedGoal === value && selectedGoal !== ''
        ? Colors.green.main
        : Colors.lightGrey
    }`,
    '&:hover': {
      background: Colors.green.light,
      color: Colors.green.dark
    },
    '&:active': {
      boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
      background: Colors.green.light,
      color: Colors.green.dark
    }
  });
  return (
    <Box>
      <Typography
        fontFamily={'"Nunito"'}
        fontSize={'1.5rem'}
        lineHeight={'2rem'}
        fontWeight={500}>
        What do you hope to achieve through affirmations, visualisations and or
        meditation?
      </Typography>
      <Grid container spacing={1} mt={1}>
        {ProfessionalChalleges.map((challenge) => (
          <Grid item xs={12} key={challenge.challenge}>
            <Button
              fullWidth
              sx={buttonStyle(challenge.challenge)}
              onClick={() => handleGoalSelect(challenge.challenge)}>
              <Typography
                fontFamily={'"Nunito"'}
                fontWeight={500}
                fontSize={'1rem'}
                lineHeight={'1.25rem'}>
                {challenge.challenge}
              </Typography>
              {selectedGoal === challenge.challenge && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 16,
                    borderRadius: 9999,
                    backgroundColor: Colors.secondary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.5rem',
                    width: '1.5rem'
                  }}>
                  <CheckIcon sx={{ color: Colors.white, fontSize: '1rem' }} />
                </Box>
              )}
            </Button>
          </Grid>
        ))}
        <Grid item xs={12}>
          {isOtherSelected ? (
            <TextField
              fullWidth
              variant="outlined"
              value={otherGoal}
              onChange={(e) => setOtherGoal(e.target.value)}
              placeholder="Please specify"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={confirmOtherGoal}>
                      <CheckIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '2.5rem',
                  borderRadius: '0.25rem',
                  background: Colors.white,
                  boxShadow: `0px 1px 1px -1px ${Colors.neutralGrey}, 0px 2px 2px -2px ${Colors.neutralGrey}`
                }
              }}
            />
          ) : (
            <Button
              fullWidth
              onClick={handleOtherSelect}
              sx={buttonStyle(otherGoal)}>
              <Typography
                fontFamily={'"Nunito"'}
                fontWeight={500}
                fontSize={'1rem'}
                lineHeight={'1.25rem'}>
                {selectedGoal !== 'Other (please specify)' &&
                selectedGoal !== '' &&
                ProfessionalChalleges.map((g) => g.challenge).indexOf(
                  selectedGoal
                ) === -1
                  ? selectedGoal
                  : 'Other'}
              </Typography>
              {(selectedGoal === 'Other (please specify)' ||
                (ProfessionalChalleges.map((g) => g.challenge).indexOf(
                  selectedGoal
                ) === -1 &&
                  selectedGoal !== '')) && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 16,
                    borderRadius: 9999,
                    backgroundColor: Colors.greenBlue,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '1.5rem',
                    width: '1.5rem'
                  }}>
                  <CheckIcon sx={{ color: Colors.white, fontSize: '1rem' }} />
                </Box>
              )}
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="space-between" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => navigate(RoutesEnum.USER_HOME)}>
            Skip all
          </Button>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  dispatch(setActualStep(actualStep - 1));
                }}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={{ backgroundColor: Colors.bgGreen }}
                onClick={() => {
                  dispatch(setAims(selectedGoal));
                  dispatch(setActualStep(6));
                }}
                disabled={!selectedGoal}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AimsiAffirm;
