import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Product } from 'services/stripe';

import {
  addDefaultPaymentMethod,
  cancelSubscription,
  getStripeProducts,
  stripeSubscription
} from './stripe.action';

interface SubscriptionState {
  subscriptionId: string | null;
  isLoading: boolean;
  error: string | null;
  stripeProducts: Product[] | null;
}

const initialState: SubscriptionState = {
  subscriptionId: null,
  isLoading: false,
  error: null,
  stripeProducts: null
};

const StripeSlice = createSlice({
  name: 'Stripe',
  initialState,
  reducers: {
    resetSubscriptionState: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(stripeSubscription.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(stripeSubscription.fulfilled, (state, action) => {
        state.subscriptionId = action.payload.subscriptionId;
        state.isLoading = false;
      })
      .addCase(stripeSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to subscribe';
      })
      .addCase(addDefaultPaymentMethod.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        addDefaultPaymentMethod.fulfilled,
        (state, action: PayloadAction<{ PaymentConfirmation: string }>) => {
          state.isLoading = false;
        }
      )
      .addCase(addDefaultPaymentMethod.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message || 'Failed to add default payment method';
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.isLoading = false;
        state.subscriptionId = null;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to cancel subscription';
      })
      .addCase(getStripeProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        getStripeProducts.fulfilled,
        (state, action: PayloadAction<Product[]>) => {
          state.stripeProducts = action.payload;
          state.isLoading = false;
        }
      )
      .addCase(getStripeProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch products';
      });
  }
});

export const { resetSubscriptionState } = StripeSlice.actions;

export default StripeSlice.reducer;
