import { ReactComponent as Create } from 'assets/images/Create.svg';
import { ReactComponent as Play } from 'assets/images/Play.svg';
import { ReactComponent as Reflect } from 'assets/images/Reflect.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { newUserOnboarding } from 'redux/user/user.selector';
import { setActualStep } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

const StartDay = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLargeScreen = useIsLargeScreen();
  const { actualStep } = useAppSelector(newUserOnboarding);
  return (
    <Box>
      <Typography
        fontFamily={'"Nunito"'}
        fontSize={'1.5rem'}
        lineHeight={'2rem'}
        fontWeight={500}>
        Start your day with purpose
      </Typography>
      <Box mt={5}>
        <Box
          mt={3}
          sx={{
            display: 'flex',
            flexDirection: isLargeScreen ? 'row' : 'column',
            justifyContent: isLargeScreen ? undefined : 'center',
            alignContent: 'center'
          }}>
          <Box
            sx={{
              display: isLargeScreen ? undefined : 'flex',
              justifyContent: isLargeScreen ? undefined : 'center',
              width: isLargeScreen ? undefined : '100%',
              margin: isLargeScreen ? undefined : '0 auto',
              marginBottom: isLargeScreen ? undefined : '1rem'
            }}>
            <Reflect />
          </Box>
          <Box
            sx={{
              margin: 'auto',
              marginLeft: isLargeScreen ? '1.5rem' : undefined
            }}>
            <Typography
              fontFamily='"Nunito"'
              fontSize={'1.15rem'}
              fontWeight={500}
              lineHeight={'1.5rem'}
              sx={{ textAlign: 'center' }}>
              Reflect on your purpose and choose how iAffirm can help you
            </Typography>
          </Box>
        </Box>
        <Box
          mt={3}
          sx={{
            display: 'flex',
            flexDirection: isLargeScreen ? 'row' : 'column',
            justifyContent: isLargeScreen ? undefined : 'center',
            alignContent: 'center'
          }}>
          <Box
            sx={{
              display: isLargeScreen ? undefined : 'flex',
              justifyContent: isLargeScreen ? undefined : 'center',
              width: isLargeScreen ? undefined : '100%',
              margin: isLargeScreen ? undefined : '0 auto',
              marginBottom: isLargeScreen ? undefined : '1rem'
            }}>
            <Create />
          </Box>
          <Box
            sx={{
              margin: 'auto',
              marginLeft: isLargeScreen ? '1.5rem' : undefined
            }}>
            <Typography
              fontFamily='"Nunito"'
              fontSize={'1.15rem'}
              fontWeight={500}
              lineHeight={'1.5rem'}
              sx={{ textAlign: 'center' }}>
              Create a playlist of sessions for any occasion
            </Typography>
          </Box>
        </Box>
        <Box
          mt={3}
          sx={{
            display: 'flex',
            flexDirection: isLargeScreen ? 'row' : 'column',
            justifyContent: isLargeScreen ? undefined : 'center',
            alignContent: 'center'
          }}>
          <Box
            sx={{
              display: isLargeScreen ? undefined : 'flex',
              justifyContent: isLargeScreen ? undefined : 'center',
              width: isLargeScreen ? undefined : '100%',
              margin: isLargeScreen ? undefined : '0 auto',
              marginBottom: isLargeScreen ? undefined : '1rem'
            }}>
            <Play />
          </Box>
          <Box
            sx={{
              margin: 'auto',
              marginLeft: isLargeScreen ? '1.5rem' : undefined
            }}>
            <Typography
              fontFamily='"Nunito"'
              fontSize={'1.15rem'}
              fontWeight={500}
              lineHeight={'1.5rem'}
              sx={{ textAlign: 'center' }}>
              Play your sessions wherever you go.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        columnSpacing={2}
        py={4}
        sx={{ marginTop: '10%' }}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => navigate(RoutesEnum.USER_HOME)}>
            Skip all
          </Button>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  dispatch(setActualStep(actualStep - 1));
                }}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={{ backgroundColor: Colors.bgGreen }}
                onClick={() => {
                  dispatch(setActualStep(actualStep + 1));
                }}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StartDay;
