import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { getDefaultAudioProfiles } from 'redux/audio-profiles/audio-profiles.action';
import { audioProfilesSelector } from 'redux/audio-profiles/audio-profiles.selector';
import { newUserOnboarding } from 'redux/user/user.selector';
import { setActualStep, setAudioProfile } from 'redux/user/user.slice';

import RoutesEnum from 'types/routes.enum';

import { AudioSelector } from '../AudioSelector';

const AudioInitialPreference = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actualStep } = useAppSelector(newUserOnboarding);
  const { defaultAudioProfiles } = useAppSelector(audioProfilesSelector);
  const [selectedAudioProfile, setSelectedAudioProfile] = useState<string>('');

  const handleAudioProfileSelected = (profileName: string) => {
    setSelectedAudioProfile(profileName);
  };

  useEffect(() => {
    dispatch(getDefaultAudioProfiles());
  }, [dispatch]);

  return (
    <Box>
      <Typography
        fontFamily={'"Nunito"'}
        fontSize={'1.5rem'}
        lineHeight={'2rem'}
        fontWeight={500}>
        Select your preferred voice and background music for your sessions.
      </Typography>
      <Box mt={5}>
        <AudioSelector
          defaultAudioProfiles={defaultAudioProfiles}
          onAudioProfileSelected={handleAudioProfileSelected}
        />
      </Box>
      <Grid container justifyContent="space-between" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => navigate(RoutesEnum.USER_HOME)}>
            Skip all
          </Button>
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  dispatch(setActualStep(actualStep - 1));
                }}>
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={{ backgroundColor: Colors.bgGreen }}
                onClick={() => {
                  dispatch(setAudioProfile(selectedAudioProfile));
                  dispatch(setActualStep(actualStep + 1));
                }}
                disabled={!selectedAudioProfile}>
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AudioInitialPreference;
