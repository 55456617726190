import useIsLargeScreen from 'hooks/useIsLargeScreen';
import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Grid, MobileStepper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Colors, evo2FontFamily } from 'design/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export type ContentType = {
  title: string;
  description: string;
  image: string;
};

interface ICarrouselProps {
  content: ContentType[];
}

const CarrouselHowWorks = ({ content }: ICarrouselProps) => {
  const isLargeScreen = useIsLargeScreen();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = content.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <>
      <AutoPlaySwipeableViews
        style={{
          boxShadow: '-7px -75px 86px -53px rgba(207,224,226,1)'
        }}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        interval={7000}
        enableMouseEvents>
        {content.map((step, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection={isLargeScreen ? 'row' : 'column-reverse'}
            bgcolor={Colors.white}
            borderRadius="0.25rem"
            sx={{
              width: isLargeScreen ? '100%' : 'min-content',
              margin: 'auto',
              boxShadow: '-7px -75px 86px -53px rgba(207,224,226,1)'
            }}>
            <Grid
              container
              width={'min-content'}
              spacing={{ sm: 2, xs: 0 }}
              bgcolor={Colors.white}
              justifyContent={isLargeScreen ? 'space-between' : 'flex-end'}
              alignItems="center"
              borderRadius="0.25rem"
              sx={{
                flexDirection: {
                  xs: 'column-reverse',
                  sm: 'row',
                  boxShadow: '-7px -75px 86px -53px rgba(207,224,226,1)',

                  alignItems: isLargeScreen ? 'flex-start' : 'center',
                  justifyContent: isLargeScreen ? 'flex-start' : 'center',
                  display: 'flex'
                },
                padding: { xs: '0', sm: '4rem 3rem' },
                height: isLargeScreen ? 'auto' : '30rem'
              }}>
              <Grid
                container
                gap={'2rem'}
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: isLargeScreen ? 'row' : 'column-reverse'
                }}>
                <Grid
                  item
                  md={5}
                  pl={{ xs: 0, sm: 2 }}
                  p={{ xs: '0 1rem', sm: 0 }}>
                  <Typography
                    color={Colors.secondary.main}
                    fontSize="2rem"
                    lineHeight="2rem"
                    fontWeight={500}
                    sx={{ display: { xs: 'none', sm: 'block' } }}>
                    {String(activeStep + 1).padStart(2, '0')}
                  </Typography>
                  <Typography
                    color={Colors.bgGreen}
                    fontSize={isLargeScreen ? '1.5rem' : '1rem'}
                    fontWeight={600}
                    fontFamily={'Exo 2'}
                    my="1rem"
                    sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                    {step.title}
                  </Typography>
                  <Typography
                    color={Colors.green.dark}
                    fontWeight={600}
                    fontFamily={'Exo 2'}
                    minHeight={!isLargeScreen ? '7rem' : undefined}
                    sx={{
                      fontSize: isLargeScreen ? '1.1rem' : '0.9rem',
                      textAlign: { xs: 'center', sm: 'start' },
                      paddingBottom: { xs: '2rem', sm: 0 }
                    }}>
                    {step.description}
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  display="flex"
                  alignItems="center"
                  justifyContent={'center'}>
                  <Box
                    component="img"
                    sx={{
                      borderRadius: '0.8rem',
                      minHeight: isLargeScreen ? '22rem' : '14rem',
                      height: '100%',
                      maxHeight: isLargeScreen ? '22rem' : '14rem',
                      display: 'block',
                      overflow: 'hidden',
                      width: isLargeScreen ? '45rem' : '20rem',
                      maxWidth: isLargeScreen ? '45rem' : '20rem',
                      objectFit: isLargeScreen ? 'contain' : 'cover'
                    }}
                    loading="eager"
                    src={step.image}
                    alt={step.title}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      {isLargeScreen ? (
        <>
          <Box mt="3.75rem" />
          <Grid container columnSpacing={2}>
            {content.map((step, index) => (
              <Grid
                item
                key={index}
                xs={12 / content.length}
                sx={{ cursor: 'pointer' }}
                onClick={() => setActiveStep(index)}>
                <Typography
                  sx={{
                    borderTop: `.5rem solid ${
                      index <= activeStep
                        ? Colors.black
                        : Colors.secondary.light
                    }`,
                    transition: 'all 200ms ease-in-out',
                    padding: '1rem .5rem'
                  }}
                  color={index <= activeStep ? Colors.black : Colors.neutral}
                  fontFamily={evo2FontFamily}
                  fontWeight={500}>
                  {step.title.replace(/:/g, '')}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <>
          <Box mt="2.25rem" />
          <MobileStepper
            LinearProgressProps={{
              sx: {
                width: '100%',
                borderRadius: '0.25rem',
                color: Colors.black
              }
            }}
            variant="progress"
            sx={{ backgroundColor: 'transparent' }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<></>}
            backButton={<></>}
          />
        </>
      )}
    </>
  );
};

export default CarrouselHowWorks;
