import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import React, { useEffect, useState } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import {
  getCredentialsStatus,
  uploadPDF
} from 'redux/affiliation/affiliations.action';
import { affiliationSelector } from 'redux/affiliation/affiliations.selector';
import { setAffiliateModal } from 'redux/user/user.slice';

import { ApplicationIntroProps } from './ApplicationIntro';
import { Conditions } from './Conditions';

export const CredentialsUpload: React.FC<ApplicationIntroProps> = ({
  setActiveStep
}) => {
  const dispatch = useAppDispatch();
  const { credentialsStatus } = useAppSelector(affiliationSelector);
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [dataPolicyChecked, setDataPolicyChecked] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedFile2, setSelectedFile2] = useState<File | null>(null);
  const [uploadSuccess1, setUploadSuccess1] = useState<boolean>(false);
  const [uploadSuccess2, setUploadSuccess2] = useState<boolean>(false);
  const [uploadPDF1, setUploadPDF1] = useState<boolean>(false);
  const [uploadPDF2, setUploadPDF2] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCredentialsStatus());
  }, [dispatch]);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFile: React.Dispatch<React.SetStateAction<File | null>>,
    setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setUploadSuccess(false);
    }
  };

  const handleSubmitID = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedFile) return;

    setUploadPDF1(true);
    const response = await dispatch(
      uploadPDF({ file: selectedFile, credentialCode: 'AID1' })
    );
    setUploadPDF1(false);

    if (response) {
      setUploadSuccess1(true);
    }
  };

  const handleSubmitSecondID = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedFile2) return;

    setUploadPDF2(true);
    const response = await dispatch(
      uploadPDF({ file: selectedFile2, credentialCode: 'AID2' })
    );
    setUploadPDF2(false);
    if (response) {
      setUploadSuccess2(true);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      rowGap={2}
      padding={'1rem'}
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmitID}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12} sm={12} md={8}>
              <TextField
                label="ID"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <Typography fontSize={'1rem'} sx={{ width: '100%' }}>
                      Upload a national ID
                    </Typography>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              <FormControl>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(event) =>
                    handleFileChange(event, setSelectedFile, setUploadSuccess1)
                  }
                  style={{ display: 'none', width: '100%' }}
                  id="file-input"
                  disabled={credentialsStatus?.firstCredential}
                />
                <label htmlFor="file-input">
                  <Button
                    fullWidth
                    variant="contained"
                    component="span"
                    disabled={credentialsStatus?.firstCredential}>
                    Browse
                  </Button>
                </label>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              {uploadSuccess1 || credentialsStatus?.firstCredential ? (
                <IconButton color="success">
                  <CheckCircleIcon />
                </IconButton>
              ) : uploadPDF1 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={credentialsStatus?.firstCredential}>
                  Upload
                </Button>
              )}
            </Grid>
          </Grid>
          {selectedFile && (
            <Typography variant="body2" align="center">
              {selectedFile.name}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box component="form" onSubmit={handleSubmitSecondID}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center">
            <Grid item xs={12} sm={12} md={8}>
              <TextField
                label="ID"
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <Typography fontSize={'1rem'} sx={{ width: '100%' }}>
                      Upload a national ID
                    </Typography>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              <FormControl>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(event) =>
                    handleFileChange(event, setSelectedFile2, setUploadSuccess2)
                  }
                  style={{ display: 'none' }}
                  id="file-input-2"
                  disabled={credentialsStatus?.secondCredential}
                />
                <label htmlFor="file-input-2">
                  <Button
                    fullWidth
                    variant="contained"
                    component="span"
                    disabled={credentialsStatus?.secondCredential}>
                    Browse
                  </Button>
                </label>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={2} textAlign="center">
              {uploadSuccess2 || credentialsStatus?.secondCredential ? (
                <IconButton color="success">
                  <CheckCircleIcon />
                </IconButton>
              ) : uploadPDF2 ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={credentialsStatus?.secondCredential}>
                  Upload
                </Button>
              )}
            </Grid>
          </Grid>
          {selectedFile2 && (
            <Typography variant="body2" align="center">
              {selectedFile2.name}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Conditions
          termsChecked={termsChecked}
          dataPolicyChecked={dataPolicyChecked}
          onTermsChange={setTermsChecked}
          onDataPolicyChange={setDataPolicyChecked}
        />
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        py={2}
        sx={{ mt: '3rem', padding: '1rem' }}>
        <Grid item>
          <Button
            color="secondary"
            variant="text"
            onClick={() => {
              setActiveStep(0);
              dispatch(setAffiliateModal(false));
            }}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={
              !termsChecked ||
              !dataPolicyChecked ||
              !(uploadSuccess1 || credentialsStatus?.firstCredential) ||
              !(uploadSuccess2 || credentialsStatus?.secondCredential)
            }
            onClick={() => {
              setActiveStep(6);
            }}
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained">
            Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
