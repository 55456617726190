import { IApiResponse } from 'types/apiResponse.interface';

import { api, baseURL } from './instance';

const _uploaderService = {
  fileUrl: () => {
    const data = {
      fileName: 'female-v5-visualization-openAI.mp3',
      bucketType: 'audio'
    };
    return api.post<IApiResponse<string>>(`${baseURL}/uploader/file-url`, data);
  }
};
export default _uploaderService;
