import { Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Visibility } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';

import { CircularLogoLoader } from 'components/CircularLogoLoader';

import { Colors } from 'design/theme';

import { recoverPassword, restorePassword } from 'redux/user/user.action';
import { userSelector } from 'redux/user/user.selector';

import RoutesEnum from 'types/routes.enum';

const RestorePassword = ({ changePassword }: { changePassword?: boolean }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { ui } = useSelector(userSelector);
  const [showActualPassword, setShowActualPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    actualPassword: changePassword
      ? Yup.string()
          .required('Password required')
          .min(4, 'Minimum 4 characters required')
      : Yup.string(),
    password: Yup.string()
      .required('Password required')
      .min(4, 'Minimum 4 characters required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
      .required('Confirm Password is required')
  });

  const handleSubmit = async (values: {
    password: string;
    actualPassword: string;
  }) => {
    if (changePassword) {
      const restorePasswordAction = await dispatch(
        restorePassword({
          password: values.actualPassword,
          newPassword: values.password
        })
      );
      if (restorePasswordAction.meta.requestStatus === 'fulfilled') {
        navigate(RoutesEnum.USER_HOME);
      }
      return;
    } else {
      const token = params.get('token');
      if (!token) return;

      const recoverPasswordAction = await dispatch(
        recoverPassword({
          password: values.password,
          token
        })
      );
      if (recoverPasswordAction.meta.requestStatus === 'fulfilled') {
        navigate(RoutesEnum.LOGIN);
      }
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        width: '35.375rem',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '100%',
          margin: 'auto',
          padding: '3.6875rem',
          borderRadius: '1.5rem'
        }}>
        <Formik
          initialValues={{
            actualPassword: '',
            password: '',
            confirmPassword: ''
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}>
          {({ errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Box
                sx={{
                  height: '85vh',
                  maxHeight: '46.9375rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                <Box>
                  <Typography
                    fontFamily='"Nunito"'
                    fontSize="2rem"
                    fontWeight={500}
                    mb={'3rem'}
                    color={Colors.brown}>
                    {changePassword
                      ? 'Change your password'
                      : 'Restore password'}
                  </Typography>
                  <Box mb="1rem" mt="0.75rem">
                    {changePassword && (
                      <TextField
                        color="secondary"
                        required
                        fullWidth
                        placeholder="Password *"
                        name="actualPassword"
                        type={!showActualPassword ? 'password' : 'text'}
                        id="actualPassword"
                        autoComplete="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.actualPassword &&
                          Boolean(errors.actualPassword)
                        }
                        helperText={
                          touched.actualPassword && errors.actualPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onMouseEnter={() => setShowActualPassword(true)}
                              onMouseLeave={() => setShowActualPassword(false)}>
                              <Visibility />
                            </IconButton>
                          )
                        }}
                      />
                    )}
                  </Box>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      color="secondary"
                      required
                      fullWidth
                      placeholder="New Password *"
                      name="password"
                      type={!showPassword ? 'password' : 'text'}
                      id="password"
                      autoComplete="current-password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onMouseEnter={() => setShowPassword(true)}
                            onMouseLeave={() => setShowPassword(false)}>
                            <Visibility />
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Box mb="1rem" mt="0.75rem">
                    <TextField
                      color="secondary"
                      required
                      fullWidth
                      placeholder="Confirm Password *"
                      name="confirmPassword"
                      type={!showPasswordConfirmation ? 'password' : 'text'}
                      id="confirm-password"
                      autoComplete="new-password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onMouseEnter={() =>
                              setShowPasswordConfirmation(true)
                            }
                            onMouseLeave={() =>
                              setShowPasswordConfirmation(false)
                            }>
                            <Visibility />
                          </IconButton>
                        )
                      }}
                    />
                  </Box>
                  <Box minHeight={'0.625rem'} mt={'0.125rem'}>
                    {ui.loginError ||
                      (ui.restorePasswordError && (
                        <Typography
                          color="error"
                          variant="body1"
                          fontWeight={500}
                          fontSize={'0.875rem'}
                          lineHeight={'0.625rem'}>
                          {ui.loginError ?? ui.restorePasswordError}
                        </Typography>
                      ))}
                  </Box>
                  <Box>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      disabled={ui.loading}
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontSize: '1rem',
                        minHeight: '3.25rem',
                        textTransform: 'none',
                        fontFamily: '"Nunito"',
                        lineHeight: '1.25rem',
                        letterSpacing: '0.05rem',
                        fontWeight: '500',
                        color: Colors.white
                      }}>
                      Reset password
                    </Button>
                    {ui.loading && (
                      <Box
                        display="flex"
                        alignItems="center"
                        gap=".5rem"
                        justifyContent="center">
                        <CircularLogoLoader />
                        <Typography
                          fontWeight={500}
                          color={Colors.secondary.dark}>
                          You will be redirected to login
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default RestorePassword;
