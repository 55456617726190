import { useAppSelector } from 'hooks/useAppSelector';

import { Box, Grid } from '@mui/material';

import { Colors } from 'design/theme';

import { medidationSelector } from 'redux/meditations/meditation.selector';

const StepsView = () => {
  const { actualStep, lastStep } =
    useAppSelector(medidationSelector)?.newMeditation;

  return (
    <Grid container columnSpacing={{ xs: 1, md: 4 }} mb="3rem">
      {Array(lastStep - 1)
        .fill('')
        .map((_, i) => (
          <Grid item key={i} xs={12 / (lastStep - 1)}>
            <Box
              sx={{
                width: '100%',
                height: '.5rem',
                backgroundColor:
                  i + 1 <= actualStep ? Colors.greenBlue : Colors.neutral,
                borderRadius: '6.25rem'
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};
export default StepsView;
