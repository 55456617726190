import { ReactComponent as Star } from 'assets/images/home/desktop/star.svg';

import { Box, Typography } from '@mui/material';

import { Colors } from 'design/theme';

const TextAnimation = ({
  firstText,
  secondText
}: {
  firstText: string;
  secondText: string;
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      mr: '3rem'
    }}>
    <Box>
      <Typography
        color={Colors.white}
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '1.3rem',
          fontWeight: '200'
        }}>
        {firstText}
      </Typography>
      <Typography
        color={Colors.white}
        sx={{
          whiteSpace: 'nowrap',
          fontSize: '1.3rem',
          fontWeight: '200'
        }}>
        {secondText}
      </Typography>
    </Box>
    <Star />
  </Box>
);

export default TextAnimation;
