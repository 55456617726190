import { ReactComponent as EditIcon } from 'assets/images/Edit.svg';
import { ReactComponent as Trash } from 'assets/images/Trash.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';

import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import BoxSelect from 'modules/new-affirmation/components/BoxSelect';

import { QualityBrief } from 'redux/visualization/types/visualizations';
import { getOutcomeQualitiesVisualization } from 'redux/visualization/visualization.action';
import { visualizationSelector } from 'redux/visualization/visualization.selector';
import {
  addQualityVisualization,
  removeQualityVisualization,
  setActualStepVisualization
} from 'redux/visualization/visualization.slice';

import { isBannedWord } from 'utils/bannedWords';

const QualityVisualization = () => {
  const dispatch = useAppDispatch();
  const [customQuality, setCustomQuality] = useState<string | undefined>(
    undefined
  );
  const [activeQualityId, setActiveQualityId] = useState<number | null>(null);
  const [editingQuality, setEditingQuality] = useState<boolean>(false);
  const [qualityError, setQualityError] = useState<string>('');
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const [selectedQuality, setSelectedQuality] = useState<any>(null);

  const { actualStep, selectedOutcome } = useAppSelector(
    visualizationSelector
  ).newVisualization;

  const { visualizationQualities, loading } = useAppSelector(
    visualizationSelector
  );

  const handleUpdateQuality = (quality: string) => {
    const errorMessage = isBannedWord(quality);
    if (errorMessage) {
      setQualityError(errorMessage);
    } else {
      setQualityError('');
      setCustomQuality(quality);
      setEditingQuality(false);
      setShowTextField(false);
    }
  };

  const handleRemoveQuality = () => {
    setCustomQuality(undefined);
    setQualityError('');
    setEditingQuality(false);
    setActiveQualityId(null);
    setShowTextField(false);
    setSelectedQuality(null);
    dispatch(removeQualityVisualization());
  };

  const handleEditQuality = () => {
    setEditingQuality(true);
    setShowTextField(true);
  };

  const handleSaveEditedQuality = () => {
    handleUpdateQuality(customQuality ? customQuality : '');
  };

  useEffect(() => {
    if (selectedOutcome && selectedOutcome.id) {
      dispatch(getOutcomeQualitiesVisualization(selectedOutcome.id));
    }
  }, [dispatch, selectedOutcome]);

  const handleBoxSelectClick = (quality: QualityBrief) => {
    setActiveQualityId(quality.id ? quality.id : 0);
    setCustomQuality(undefined);
    setEditingQuality(false);
    setShowTextField(false);
    setSelectedQuality(quality);
  };

  const handleNextClick = () => {
    if (customQuality) {
      dispatch(addQualityVisualization({ quality: customQuality }));
    } else if (selectedQuality) {
      dispatch(addQualityVisualization(selectedQuality));
    }
    dispatch(setActualStepVisualization(actualStep + 1));
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        mb="1.5rem"
        alignItems="center">
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.5rem'}
            color={Colors.black}>
            I want to visualize myself ...
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'0.875rem'}
            fontWeight={500}
            color={Colors.brown.light}>
            Select one quality from the list or add a custom one.
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {loading
          ? Array(4)
              .fill('')
              .map((_, i) => (
                <Grid item xs={6} md={3} key={i}>
                  <Skeleton height="8.75rem" />
                </Grid>
              ))
          : visualizationQualities &&
            visualizationQualities.map((quality, i) => (
              <Grid item xs={6} md={4} key={i}>
                <BoxSelect
                  onClick={() => {
                    handleBoxSelectClick(quality);
                    handleScrollDown();
                  }}
                  isSelected={activeQualityId === quality.id}>
                  <Typography sx={{ textAlign: 'center' }}>
                    {quality.quality}
                  </Typography>
                </BoxSelect>
              </Grid>
            ))}
      </Grid>

      {showTextField && (
        <Grid item xs={12} sx={{ marginTop: '1.25rem' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: `1px solid ${Colors.lightGrey}`,
              padding: '8px',
              width: '98%'
            }}>
            <TextField
              fullWidth
              label="Type a quality"
              variant="standard"
              value={customQuality}
              onChange={(e) => {
                const newValue = e.target.value.slice(0, 20);
                setCustomQuality(newValue);
              }}
              size="small"
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  maxLength: 20
                }
              }}
              sx={{
                fontFamily: '"Nunito"',
                '& label.Mui-focused': {
                  color: `${Colors.secondary.main}`
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: `${Colors.secondary.main}`
                },
                height: 'min-content',
                color: Colors.lightGrey
              }}
              error={!!qualityError}
              helperText={qualityError}
            />

            <IconButton color="secondary" onClick={handleSaveEditedQuality}>
              <CheckIcon />
            </IconButton>
            <IconButton color="error" onClick={handleRemoveQuality}>
              <Trash />
            </IconButton>
          </Box>
        </Grid>
      )}

      {customQuality && !editingQuality && !showTextField && (
        <Grid item xs={12} sx={{ marginTop: '1.25rem' }}>
          <Box
            sx={{
              backgroundColor: Colors.secondary.light,
              marginTop: '0.25rem',
              border: `1px solid ${Colors.secondary.main}`,
              padding: '8px',
              borderRadius: '4px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              height: '5rem'
            }}>
            <Box sx={{ width: '98%' }}>
              <Typography
                fontFamily='"Nunito"'
                fontSize={'1.25rem'}
                lineHeight={'1.5rem'}
                sx={{ color: Colors.secondary.main, padding: '1rem' }}>
                {customQuality}
              </Typography>
            </Box>
            <IconButton color="primary" onClick={handleEditQuality}>
              <EditIcon />
            </IconButton>
            <IconButton color="error" onClick={handleRemoveQuality}>
              <Trash />
            </IconButton>
          </Box>
        </Grid>
      )}
      {/**      <Grid item>
        <Button
          sx={{ marginTop: '0.5rem' }}
          onClick={() => {
            setShowTextField(true);
            setSelectedQuality(null);
            setActiveQualityId(null);
          }}
          disabled={true}>
          <Typography
            fontFamily='"Nunito"'
            fontSize={'1.25rem'}
            fontWeight={500}
            sx={{ color: Colors.bgGreen }}>
            + Add other
          </Typography>
        </Button>
      </Grid> */}

      <Grid container justifyContent="flex-end" columnSpacing={2} py={4}>
        <Grid item>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              dispatch(setActualStepVisualization(actualStep - 1));
            }}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            disabled={!selectedQuality && !customQuality}
            onClick={handleNextClick}>
            Next
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default QualityVisualization;
