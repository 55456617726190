export interface CreateAffiliationDto {
  phoneNumber: string;
  website?: string;
  contentType: string;
  audience: string;
  recentPost: string;
  socials: SocialsDTO[];
}

export interface SocialsDTO {
  social: SocialsEnum;
  url: string;
}

export enum SocialsEnum {
  FB = 'Facebook',
  INSTA = 'Instagram',
  TIKTOK = 'TikTok',
  X = 'X',
  YT = 'Youtube',
  OTHER = 'Other'
}

export interface RequestedAffiliation {
  id: string;
  statusReview: boolean;
  userId: string;
}
export interface PdfData {
  agreement: AgreementsEnum;
  sign: string;
}

export enum AgreementsEnum {
  NDA = 'Non Disclosure Agreement',
  AA = 'Affiliate Agreement'
}
