import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { AreaSelected } from 'modules/new-affirmation/types/initialState';

import {
  createMeditation,
  generateMeditation,
  generatePDFMeditation,
  generateSpeechMeditation,
  getAllMeditationOutcomes,
  getAttributeByGoals,
  getQualitiesByOutcome
} from './meditation.action';
import {
  Attribute,
  Goals,
  MeditationOutcome,
  MeditationState,
  Quality
} from './types/meditation.entity';

const initialState: MeditationState = {
  createdMeditation: null,
  userMeditation: {},
  loading: false,
  speechLoading: false,
  isSpeechProcessing: false,
  processingHome: true,
  newMeditation: {
    actualStep: 1,
    lastStep: 6,
    home: false,
    customFlow: false
  }
};

const medidationSlice = createSlice({
  name: 'meditation',
  initialState,
  reducers: {
    cleanMeditation: () => initialState,
    setLastStepMeditation: (state) => {
      state.newMeditation.lastStep = 6;
    },
    setActualStepMeditation: (state, action: PayloadAction<number>) => {
      if (
        action.payload <= state.newMeditation.lastStep &&
        action.payload >= 1
      ) {
        state.newMeditation.actualStep = action.payload;
      }
    },
    setCustomFlowMeditation: (state, action: PayloadAction<boolean>) => {
      state.newMeditation.customFlow = action.payload;
    },
    // setCustomMeditationOutcome: (state, action: PayloadAction<string>) => {
    //   state.customMeditation.outcome = action.payload;
    // },
    // setCustomMeditationGoal: (state, action: PayloadAction<string>) => {
    //   state.customMeditation.goal = action.payload;
    // },
    // setCustomMeditationQuality: (state, action: PayloadAction<string>) => {
    //   state.customMeditation.quality = action.payload;
    // },
    // setCustomMeditationAttribute: (state, action: PayloadAction<string>) => {
    //   state.customMeditation.attribute = action.payload;
    // },
    cleanMeditationWantWorkOn: (state) => {
      state.newMeditation.selectedAttribute = undefined;
      state.newMeditation.selectedGoal = undefined;
      state.newMeditation.selectedOutcome = undefined;
      state.newMeditation.selectedQuality = undefined;
      state.newMeditation.lastStep = initialState.newMeditation.lastStep;
    },
    setAreaSelectedMeditation: (
      state,
      action: PayloadAction<AreaSelected | undefined>
    ) => {
      state.newMeditation.areaSelected = action.payload;
    },

    removeQualityMeditation: (state) => {
      state.newMeditation.selectedQuality = undefined;
    },
    addQualityMeditation: (state, action: PayloadAction<Quality>) => {
      state.newMeditation.selectedQuality = action.payload;
    },
    addGoalsMeditation: (state, action: PayloadAction<Goals>) => {
      state.newMeditation.selectedGoal = action.payload;
    },
    addAttributeMeditation: (state, action: PayloadAction<Attribute>) => {
      state.newMeditation.selectedAttribute = action.payload;
    },
    addOutcomeMeditation: (state, action: PayloadAction<MeditationOutcome>) => {
      state.newMeditation.selectedOutcome = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMeditationOutcomes.fulfilled, (state, action) => {
        state.loading = false;
        state.meditationOutcomes = action.payload.data;
      })
      .addCase(getAllMeditationOutcomes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMeditationOutcomes.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getQualitiesByOutcome.fulfilled, (state, action) => {
        state.loading = false;
        state.qualityByOutcome = action.payload.data;
      })
      .addCase(getQualitiesByOutcome.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQualitiesByOutcome.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAttributeByGoals.fulfilled, (state, action) => {
        state.loading = false;
        state.attributeByGoals = action.payload.data;
      })
      .addCase(getAttributeByGoals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttributeByGoals.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createMeditation.fulfilled, (state, action) => {
        state.loading = false;
        state.createdMeditation = action.payload.data;
      })
      .addCase(createMeditation.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMeditation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(generateMeditation.fulfilled, (state, action) => {
        state.loading = false;
        state.userMeditation.editMeditation = action.payload.data;
      })
      .addCase(generateMeditation.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateMeditation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(generatePDFMeditation.fulfilled, (state, action) => {
        state.userMeditation.userMeditation = action.payload.data;
        state.loading = false;
      })
      .addCase(generatePDFMeditation.pending, (state) => {
        state.loading = true;
      })
      .addCase(generatePDFMeditation.rejected, (state) => {
        state.loading = false;
      })
      .addCase(generateSpeechMeditation.fulfilled, (state, action) => {
        state.speech = action.payload.MeditationURL;
        state.speechLoading = false;
        const urlRegex = /^https?:\/\//;
        state.isSpeechProcessing = !urlRegex.test(action.payload.MeditationURL);
        state.processingHome = false;
      })
      .addCase(generateSpeechMeditation.pending, (state) => {
        state.speechLoading = true;
        state.isSpeechProcessing = true;
      })
      .addCase(generateSpeechMeditation.rejected, (state) => {
        state.speechLoading = false;
        state.speech = undefined;
        state.isSpeechProcessing = false;
      });
  }
});

export const {
  cleanMeditation,
  setActualStepMeditation,
  setLastStepMeditation,
  cleanMeditationWantWorkOn,
  setAreaSelectedMeditation,
  setCustomFlowMeditation,
  removeQualityMeditation,
  addQualityMeditation,
  addGoalsMeditation,
  addAttributeMeditation,
  addOutcomeMeditation
} = medidationSlice.actions;

export default medidationSlice.reducer;
