import Faces from 'assets/images/home/ElevateHumanity/Faces.png';
import Mission from 'assets/images/home/ElevateHumanity/Mission.png';
import Limitless from 'assets/images/home/ElevateHumanity/Potential.png';
import React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const ElevateHumanityDesktop = () => {
  return (
    <Box sx={{ backgroundColor: 'whitesmoke', py: '8.4375rem' }}>
      <Container sx={{ gap: '2rem' }}>
        <Typography
          color="secondary"
          fontFamily={evo2FontFamily}
          fontWeight={500}
          fontStyle="italic"
          textAlign="center">
          At the heart of our platform{' '}
        </Typography>
        <Typography
          color={Colors.bgGreen}
          fontFamily={evo2FontFamily}
          fontWeight={700}
          textAlign="center"
          fontSize="2.625rem"
          maxWidth="42.1875rem"
          m="auto"
          lineHeight="120%">
          Elevate humanity through the power of personal growth.
        </Typography>
        <div
          style={{
            backgroundColor: 'white',
            padding: '2.5rem 2rem',
            marginTop: '3rem',
            boxShadow: '-7px -75px 86px -53px rgba(207,224,226,1)'
          }}>
          <Grid container alignItems="center">
            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <img
                src={Limitless}
                alt="Limitless"
                style={{ maxHeight: '22rem' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <img src={Mission} alt="Mission" style={{ maxHeight: '22rem' }} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              display="flex"
              justifyContent="center"
              alignItems="center">
              <img
                src={Faces}
                alt="Sphere"
                style={{
                  maxHeight: '22rem',
                  filter: 'dropShadow(1px 7px 30px #000000)'
                }}
              />
            </Grid>
          </Grid>
          <Grid container marginTop={'1.25rem'}>
            <Grid
              item
              xs={12}
              sm={4}
              justifyContent="center"
              alignItems="center">
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                width="65%"
                mx="auto"
                fontWeight={600}
                lineHeight="normal"
                textAlign={'center'}
                alignSelf="center">
                We believe in the limitless potential within every person, often
                untapped by ingrained beliefs.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                width="65%"
                mx="auto"
                fontWeight={600}
                textAlign={'center'}
                lineHeight="normal">
                Our goal is clear - to provide a transformative tool that
                reshapes these beliefs, nurturing positive change.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} maxWidth={'22rem'}>
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                width="65%"
                mx="auto"
                fontWeight={600}
                textAlign={'center'}
                lineHeight="normal">
                Our mission is to ignite profound personal growth, ultimately
                benefiting humanity as a whole.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </Box>
  );
};
export default ElevateHumanityDesktop;
