import { FormikProvider, useFormik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAppSelector } from 'hooks/useAppSelector';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import TabsContext from 'modules/new-visualization/components/TabsContext';
import useTabsContext from 'modules/new-visualization/hooks/useTabsContext';

import { createMeditation } from 'redux/meditations/meditation.action';
import { medidationSelector } from 'redux/meditations/meditation.selector';
import {
  CreateMeditation,
  NewMeditationFormik
} from 'redux/meditations/types/meditation.entity';

import RoutesEnum from 'types/routes.enum';

import OutcomeStep from './CustomSteps/1_CustomOutcome';
import QualityStep from './CustomSteps/2_CustomQuality';
import GoalStep from './CustomSteps/3_CustomGoal';
import AttributeStep from './CustomSteps/4_CustomAttribute';
import { newMeditationFormValidation as validationSchema } from './validations/newMeditationForm.validation';

export enum NewMeditationTabs {
  OUTCOME,
  QUALITY,
  GOAL,
  ATTRIBUTE
}

const NewMeditationForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hasNavigated = useRef(false);
  const { createdMeditation } = useAppSelector(medidationSelector);
  const { setTabs, currentTab } = useTabsContext(TabsContext);

  useEffect(() => {
    setTabs([
      { id: NewMeditationTabs.OUTCOME, status: true },
      { id: NewMeditationTabs.QUALITY, status: false },
      { id: NewMeditationTabs.GOAL, status: false },
      { id: NewMeditationTabs.ATTRIBUTE, status: false }
    ]);
  }, [setTabs]);

  const form = useFormik<NewMeditationFormik>({
    initialValues: {
      outcome: '',
      quality: '',
      goal: '',
      attribute: ''
    },
    onSubmit: async (values) => {
      const transformedValues: CreateMeditation = {
        outcome: {
          outcome: values.outcome
        },
        quality: {
          quality: values.quality
        },
        goal: {
          goal: values.goal
        },
        attribute: {
          attribute: values.attribute
        }
      };
      await dispatch(createMeditation(transformedValues));
    },
    validationSchema
  });

  useEffect(() => {
    if (createdMeditation && !hasNavigated.current) {
      hasNavigated.current = true;
      navigate(`${RoutesEnum.MEDITATION_VIEW}${createdMeditation.id}`);
    }
  }, [createdMeditation, navigate]);

  return (
    <FormikProvider value={form}>
      <Box sx={{ marginTop: 5 }}>
        {currentTab?.id === NewMeditationTabs.OUTCOME && <OutcomeStep />}
        {currentTab?.id === NewMeditationTabs.QUALITY && <QualityStep />}
        {currentTab?.id === NewMeditationTabs.GOAL && <GoalStep />}
        {currentTab?.id === NewMeditationTabs.ATTRIBUTE && <AttributeStep />}
      </Box>
    </FormikProvider>
  );
};

export default NewMeditationForm;
