import Faces from 'assets/images/home/ElevateHumanity/Faces.png';
import Mission from 'assets/images/home/ElevateHumanity/Mission.png';
import Limitless from 'assets/images/home/ElevateHumanity/Potential.png';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Container, MobileStepper, Typography } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ElevateHumanityMobile = () => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 3;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box mt="-0.375rem">
      <Box sx={{ backgroundColor: 'whitesmoke', py: '8.4375rem' }}>
        <Container>
          <Typography
            color="secondary"
            fontFamily={evo2FontFamily}
            fontWeight={500}
            fontStyle="italic"
            textAlign="center"
            fontSize={'0.8rem'}>
            At the heart of our platform lies a singular vision
          </Typography>
          <Typography
            color={Colors.bgGreen}
            fontFamily={evo2FontFamily}
            fontWeight={700}
            textAlign="center"
            fontSize="1.7rem"
            maxWidth="42.1875rem"
            m="auto"
            lineHeight="120%"
            mb="2rem">
            Elevate humanity through the power of personal growth.
          </Typography>
          <AutoPlaySwipeableViews
            axis={'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={5000}
            enableMouseEvents>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
              width="auto">
              <img
                src={Limitless}
                alt="Limitless"
                style={{ maxHeight: '22rem', marginBottom: '1.25rem' }}
              />
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                fontWeight={600}
                width="70%"
                mx="auto"
                lineHeight="normal"
                sx={{ fontSize: '0.8rem', textAlign: 'center' }}
                mt="0.5">
                We believe in the limitless potential within every person, often
                untapped by ingrained beliefs.
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column">
              <img
                src={Mission}
                alt="Mission"
                style={{ maxHeight: '22rem', marginBottom: '1.25rem' }}
              />
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                fontWeight={600}
                width="70%"
                mx="auto"
                lineHeight="normal"
                sx={{ fontSize: '0.8rem', textAlign: 'center' }}
                mt="0.5">
                Our mission is clear - to provide a transformative tool that
                reshapes these beliefs, nurturing positive change.
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column">
              <img
                src={Faces}
                alt="Sphere"
                style={{ maxHeight: '22rem', marginBottom: '1.25rem' }}
              />
              <Typography
                color={Colors.green.dark}
                fontFamily={evo2FontFamily}
                fontWeight={600}
                width="70%"
                mx="auto"
                lineHeight="normal"
                sx={{ fontSize: '0.8rem', textAlign: 'center' }}
                mt="0.5">
                Our mission is to ignite profound personal growth, ultimately
                benefiting humanity as a whole.
              </Typography>
            </Box>
          </AutoPlaySwipeableViews>
          <Box mt="2.25rem" />
          <MobileStepper
            LinearProgressProps={{
              color: 'secondary',
              sx: { width: '70%', borderRadius: '0.25rem', mx: 'auto' }
            }}
            variant="progress"
            sx={{ backgroundColor: 'transparent' }}
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<></>}
            backButton={<></>}
          />
        </Container>
      </Box>
    </Box>
  );
};
export default ElevateHumanityMobile;
