import { useFormikContext } from 'formik';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, TextField, Tooltip, Typography } from '@mui/material';

import useTabsContext from '../../../hooks/useTabsContext';
import { NewVisualization } from '../../../types/new-visualization.types';
import StepsMenu from '../../StepsMenu';
import TabsContext from '../../TabsContext';

const qualityTooltipText =
  'Add the feelings that you want to experience, such as “I want to visualize myself closing a deal with an important client... while feeling confident”';

const FeelStep = () => {
  const { goNextTab } = useTabsContext(TabsContext);

  const { handleChange, handleBlur, values, errors, touched, setTouched } =
    useFormikContext<NewVisualization>();

  const handleNextStep = async () => {
    const errorsX = await setTouched({ emotion: true });

    const isFeelValid = values.emotion.trim().length > 0;

    if (errorsX && !('emotion' in errorsX) && isFeelValid) goNextTab();
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <Typography sx={{ fontSize: 24, marginBottom: 4, lineHeight: '3rem' }}>
          Whilist feeling ...
        </Typography>
        <Tooltip title={qualityTooltipText} placement="top-start">
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              userSelect: 'none'
            }}>
            <ErrorOutlineIcon color="secondary" />

            <Typography color="secondary">Show an example</Typography>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ width: '100%', marginBottom: 20 }}>
        <TextField
          placeholder="| Type how you want to emotion in this situation"
          name="emotion"
          variant="standard"
          value={values.emotion}
          id={values.emotion}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.emotion && Boolean(errors.emotion)}
          helperText={touched.emotion && errors.emotion}
          fullWidth
          multiline
          color="secondary"
          inputProps={{
            style: { fontSize: 36, lineHeight: 1, marginBottom: 40 },
            maxLength: 30
          }}
        />

        <Typography sx={{ marginTop: 3 }}>
          {30 - values.emotion.length} characters remaining (Minimum 5
          characters)
        </Typography>
      </Box>

      <StepsMenu nextAction={handleNextStep} />
    </Box>
  );
};

export default FeelStep;
