import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Skeleton, Typography } from '@mui/material';

import { Colors } from 'design/theme';

interface ListItemSelectProps {
  label: string;
  index: number;
  isSelected?: boolean;
  isLoading?: boolean;
  onEdit: () => void;
  onSelect: () => void;
}

const ListItemSelect = ({
  label,
  index,
  isSelected,
  isLoading,
  onEdit,
  onSelect
}: ListItemSelectProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '0.25rem',
        border: `1px solid ${Colors.bgGreen}`,
        backgroundColor: isSelected ? 'rgba(53, 101, 105, 0.2)' : 'transparent',
        padding: '.5rem',
        mb: '.5rem',
        height: 'min-content',
        alignItems: 'center',
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={onSelect}>
      {isLoading ? (
        <Skeleton variant="text" width="100%" />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography
            color={Colors.secondary.main}
            fontWeight={500}
            sx={{ flexGrow: 1, overflow: 'hidden' }}>
            {label}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
              size="small"
              sx={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
              <EditIcon sx={{ color: Colors.secondary.main }} />
            </IconButton>
            <Typography
              sx={{
                backgroundColor: Colors.white,
                padding: '0 0.5rem',
                borderRadius: 9999,
                color: Colors.brown.light,
                height: 'min-content'
              }}>
              {index}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ListItemSelect;
