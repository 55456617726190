import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { setAffiliateModal } from 'redux/user/user.slice';

export interface ApplicationIntroProps {
  setActiveStep: (step: number) => void;
}

export const ApplicationIntro: React.FC<ApplicationIntroProps> = ({
  setActiveStep
}) => {
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        padding: '1rem 2rem',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            border: `2px solid ${Colors.neutralGrey}`,
            borderRadius: '0.25rem'
          }}>
          <Box sx={{ margin: '1rem' }}>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.75rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Welcome to the iAffirm Affiliate Program
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                'The iAffirm Affiliate Program offers you the opportunity to partner with a leading brand and earn commissions by promoting our products and services.'
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                "As an affiliate, you'll gain access to exclusive resources, marketing tools, and a supportive community to help you succeed."
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'1.5rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              {
                "Whether you're a social media influencer, blogger, or content creator, our program is designed to reward your efforts and help you monetize your online presence."
              }
            </Typography>
            <Typography
              fontFamily="'Nunito'"
              fontSize={'1rem'}
              lineHeight={'2rem'}
              fontWeight={400}
              sx={{ margin: '0.5rem 0' }}>
              Join us today and start turning your influence into income!
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        columnSpacing={2}
        py={2}
        sx={{ mt: '2rem' }}>
        <Grid item>
          <Button
            color="secondary"
            variant="text"
            onClick={() => {
              setActiveStep(0);
              dispatch(setAffiliateModal(false));
            }}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            type="submit"
            sx={{ backgroundColor: Colors.bgGreen }}
            variant="contained"
            onClick={() => setActiveStep(1)}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
