import { createAsyncThunk } from '@reduxjs/toolkit';
import _meditationService from 'services/meditation';

import { SpeechParameters } from 'redux/affirmation/types/affirmation.entity';

import {
  CreateMeditation,
  GetMeditation,
  GetPDFMeditation
} from './types/meditation.entity';

export const createMeditation = createAsyncThunk(
  'meditation/',
  async (data: CreateMeditation) => {
    return await _meditationService.createMeditation(data);
  }
);
export const getAllMeditationOutcomes = createAsyncThunk(
  'meditation/outcomes',
  async () => {
    return _meditationService.meditationOutcomes();
  }
);
export const getQualitiesByOutcome = createAsyncThunk(
  'meditation/qualities',
  async (id: number) => {
    return _meditationService.qualitiesByOutcome(id);
  }
);
export const getAttributeByGoals = createAsyncThunk(
  'meditation/attributes',
  async (id: number) => {
    return _meditationService.getGoalAttributes(id);
  }
);
export const generateMeditation = createAsyncThunk(
  'meditation/generate-meditation',
  async (data: GetMeditation) => {
    return _meditationService.generateMeditation(data);
  }
);
export const generatePDFMeditation = createAsyncThunk(
  'meditation/generate-pdf-meditation',
  async (data: GetPDFMeditation) => {
    return _meditationService.generatePDFMeditation(data);
  }
);

export const deleteMeditation = createAsyncThunk(
  'meditation/delete',
  async (id: string) => {
    return _meditationService.deleteMeditation(id);
  }
);

export const generateSpeechMeditation = createAsyncThunk(
  'meditation/generateSpeechMeditation',
  async (data: SpeechParameters) => {
    return await _meditationService.generateSpeechMeditation(data);
  }
);
