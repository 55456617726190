import bannedWordsData from 'assets/2024_BannedWords_GPT.json';

export const isBannedWord = (input: string) => {
  const words = input.trim().split(/\s+/);

  const bannedWordFound = words.some((word) =>
    bannedWordsData.BannedWords.some(
      (bannedWord) =>
        bannedWord.Word.toLowerCase() === word.toLowerCase() ||
        input.toLowerCase().includes(bannedWord.Word.toLowerCase())
    )
  );

  return bannedWordFound ? 'Try with another word.' : '';
};
