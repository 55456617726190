import { createSlice } from '@reduxjs/toolkit';

import { getUserCredits } from './subscription.action';
import { ISubscriptionState } from './types/subscription.interface';

const initialState: ISubscriptionState = {
  credits: null,
  subscriptionType: null,
  isLoading: false,
  error: undefined
};

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserCredits.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserCredits.fulfilled, (state, action) => {
        state.credits = action.payload.credits;
        state.subscriptionType = action.payload.subscriptionType;
        state.isLoading = false;
      })
      .addCase(getUserCredits.rejected, (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      });
  }
});

export default subscriptionSlice.reducer;
