import { FieldArray, Form, Formik } from 'formik';
import { useAppDispatch } from 'hooks/useAppDispatch';
import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { affiliateValidationSchema } from 'modules/new-visualization/validations/affiliate.validation';

import { requestAffiliationProcess } from 'redux/affiliation/affiliations.action';
import { SocialsDTO } from 'redux/affiliation/types/affiliations.entity';
import { setAffiliateModal } from 'redux/user/user.slice';

import { identifySocialNetwork } from 'utils/identifySocialNetwork';

import { ApplicationIntroProps } from './ApplicationIntro';

export const AffiliateForm: React.FC<ApplicationIntroProps> = ({
  setActiveStep
}) => {
  const dispatch = useAppDispatch();
  const SubmitAffiliation = async (values: any) => {
    const preparedData = {
      phoneNumber: values.phoneCode + values.phoneNumber,
      website: values.website,
      contentType: values.contentType,
      audience: values.contentDemographics,
      recentPost: values.recentContent,
      socials: values.socials.map((social: SocialsDTO) => ({
        social: identifySocialNetwork(social.url),
        url: social.url
      }))
    };
    const actionResult = await dispatch(
      requestAffiliationProcess(preparedData)
    );
    if (requestAffiliationProcess.fulfilled.match(actionResult)) {
      setActiveStep(2);
    }
  };
  return (
    <Box
      sx={{
        padding: '0.5rem 0.5rem',
        justifyContent: 'center',
        alignContent: 'center'
      }}>
      <Formik
        initialValues={{
          phoneCode: '',
          phoneNumber: '',
          website: '',
          socials: [{ url: '' }],
          contentType: '',
          contentDemographics: '',
          recentContent: ''
        }}
        validationSchema={affiliateValidationSchema}
        onSubmit={(values) => SubmitAffiliation(values)}>
        {({ values, handleChange, errors, touched, isValid, dirty }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ margin: '1rem' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={2}>
                      <TextField
                        fullWidth
                        id="phoneCode"
                        name="phoneCode"
                        placeholder="+00"
                        label="Tel*"
                        value={values.phoneCode}
                        onChange={handleChange}
                        error={touched.phoneCode && !!errors.phoneCode}
                        helperText={touched.phoneCode && errors.phoneCode}
                      />
                    </Grid>
                    <Grid item xs={8} md={5}>
                      <TextField
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="1234567890"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        error={touched.phoneNumber && !!errors.phoneNumber}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        label="Phone Number*"
                      />
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <TextField
                        fullWidth
                        id="website"
                        name="website"
                        placeholder="www.myaddress.com"
                        value={values.website}
                        onChange={handleChange}
                        error={touched.website && !!errors.website}
                        helperText={touched.website && errors.website}
                        label="Website"
                      />
                    </Grid>
                    <FieldArray name="socials">
                      {({ push, remove }) => (
                        <Grid item xs={12}>
                          {values.socials.map((social, index) => (
                            <Grid
                              container
                              spacing={2}
                              key={index}
                              alignItems="center"
                              sx={{ marginBottom: '0.5rem' }}>
                              <Grid item xs={index > 0 ? 10.75 : 12}>
                                <TextField
                                  fullWidth
                                  id={`socials.${index}.url`}
                                  name={`socials.${index}.url`}
                                  placeholder="Social URL (i.e., www.facebook.com/myaccount)"
                                  value={social.url}
                                  onChange={handleChange}
                                  error={
                                    touched.socials?.[index]?.url &&
                                    !!errors.socials
                                  }
                                  helperText={touched.socials?.[index]?.url}
                                  label={'Socials'}
                                />
                              </Grid>
                              {index > 0 && (
                                <Grid item xs={1}>
                                  <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          ))}
                          <Button
                            onClick={() => push({ url: '' })}
                            sx={{ marginTop: '0.5rem' }}>
                            <Typography
                              fontFamily='"Nunito"'
                              fontSize={'1rem'}
                              fontWeight={500}
                              sx={{ color: Colors.bgGreen }}>
                              + Add Social
                            </Typography>
                          </Button>
                        </Grid>
                      )}
                    </FieldArray>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="contentType"
                        name="contentType"
                        placeholder="Let us know what type of content you create"
                        value={values.contentType}
                        onChange={handleChange}
                        error={touched.contentType && !!errors.contentType}
                        helperText={touched.contentType && errors.contentType}
                        label="Content Overview*"
                      />
                      {!!errors.contentType && (
                        <Typography
                          mt={'0.250rem'}
                          color="error"
                          variant="body1"
                          fontFamily='"Nunito"'
                          fontWeight={500}
                          fontSize={'0.75rem'}
                          lineHeight={'0.625rem'}>
                          {errors.contentType}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="contentDemographics"
                        name="contentDemographics"
                        placeholder="Let us know who your primary audience demographics are"
                        value={values.contentDemographics}
                        onChange={handleChange}
                        error={
                          touched.contentDemographics &&
                          !!errors.contentDemographics
                        }
                        helperText={
                          touched.contentDemographics &&
                          errors.contentDemographics
                        }
                        label="Content Demographics*"
                      />
                      {!!errors.contentDemographics && (
                        <Typography
                          mt={'0.250rem'}
                          color="error"
                          variant="body1"
                          fontWeight={500}
                          fontSize={'0.75rem'}
                          lineHeight={'0.625rem'}>
                          {errors.contentDemographics}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="recentContent"
                        name="recentContent"
                        placeholder="Link to your recent posts"
                        value={values.recentContent}
                        onChange={handleChange}
                        error={touched.recentContent && !!errors.recentContent}
                        helperText={
                          touched.recentContent && errors.recentContent
                        }
                        label="Recent Content*"
                      />
                      {!!errors.recentContent && (
                        <Typography
                          mt={'0.250rem'}
                          color="error"
                          variant="body1"
                          fontWeight={500}
                          fontSize={'0.75rem'}
                          lineHeight={'0.625rem'}>
                          {errors.recentContent}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              columnSpacing={2}
              py={2}
              sx={{ mt: '3rem', padding: '1rem' }}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="text"
                  onClick={() => {
                    setActiveStep(0);
                    dispatch(setAffiliateModal(false));
                  }}>
                  Cancel
                </Button>
              </Grid>
              {/**        <Grid item>
                <Button
                  type="submit"
                  sx={{ backgroundColor: Colors.bgGreen }}
                  variant="contained">
                  Save
                </Button>
              </Grid> */}

              <Grid item>
                <Button
                  type="submit"
                  sx={{ backgroundColor: Colors.bgGreen }}
                  variant="contained"
                  disabled={!isValid || !dirty}>
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
