import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  createAudioProfile,
  deleteUserAudioProfile,
  getAdminAudioProfiles,
  getDefaultAudioProfiles,
  getUserAudioProfiles,
  saveDefaultAudioProfileForUser,
  testAudio
} from './audio-profiles.action';
import { IAudioProfilesState } from './types/audio-profileState.interface';
import {
  AudioLibraryDefault,
  CreatedAudioProfile,
  DefaultProfileDTO,
  GetDeafaultUrlResponse,
  UserAudioLibrary
} from './types/audio-profiles.entity';

const initialState: IAudioProfilesState = {
  createdAudioProfile: null,
  userAudioProfiles: [],
  testAudioUrl: '',
  audioDuration: 8,
  loading: false,
  audioProfileError: '',
  defaultAudioProfiles: []
};

const audioProfilesSlice = createSlice({
  name: 'audioProfiles',
  initialState,
  reducers: {
    resetAudioProfilesState: (state) => {
      state.createdAudioProfile = null;
      state.defaultAudioProfiles = [];
      state.userAudioProfiles = [];
      state.loading = false;
    },
    setAudioDuration: (state, action: PayloadAction<number>) => {
      state.audioDuration = action.payload;
    },
    resetAudioDuration: (state, action: PayloadAction<number>) => {
      state.audioDuration = 5;
    },
    clearAudioProfileError: (state) => {
      state.audioProfileError = '';
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAudioProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        createAudioProfile.fulfilled,
        (state, action: PayloadAction<CreatedAudioProfile>) => {
          state.createdAudioProfile = action.payload;
          state.loading = false;
        }
      )
      .addCase(createAudioProfile.rejected, (state, action) => {
        state.audioProfileError =
          (action.payload as string) ||
          'An unexpected error occurred while saving the audio profile.';
        state.loading = false;
      })
      .addCase(getDefaultAudioProfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getDefaultAudioProfiles.fulfilled,
        (state, action: PayloadAction<AudioLibraryDefault[]>) => {
          state.defaultAudioProfiles = action.payload;
          state.loading = false;
        }
      )
      .addCase(getDefaultAudioProfiles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUserAudioProfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getUserAudioProfiles.fulfilled,
        (state, action: PayloadAction<Partial<UserAudioLibrary>[]>) => {
          state.userAudioProfiles = action.payload;
          state.loading = false;
        }
      )
      .addCase(getUserAudioProfiles.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserAudioProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserAudioProfile.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUserAudioProfile.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(testAudio.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        testAudio.fulfilled,
        (state, action: PayloadAction<GetDeafaultUrlResponse>) => {
          state.testAudioUrl = action.payload.url;
          state.loading = false;
        }
      )
      .addCase(testAudio.rejected, (state) => {
        state.loading = false;
      })
      .addCase(saveDefaultAudioProfileForUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        saveDefaultAudioProfileForUser.fulfilled,
        (state, action: PayloadAction<DefaultProfileDTO>) => {
          state.loading = false;
        }
      )
      .addCase(saveDefaultAudioProfileForUser.rejected, (state, action) => {
        state.audioProfileError =
          (action.payload as string) ||
          'An unexpected error occurred while saving the audio profile.';
        state.loading = false;
      })
      .addCase(getAdminAudioProfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAdminAudioProfiles.fulfilled,
        (state, action: PayloadAction<AudioLibraryDefault[]>) => {
          state.adminAudioProfiles = action.payload;
        }
      )
      .addCase(getAdminAudioProfiles.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const {
  resetAudioProfilesState,
  setAudioDuration,
  resetAudioDuration,
  clearAudioProfileError
} = audioProfilesSlice.actions;

export default audioProfilesSlice.reducer;
