import { SocialsEnum } from 'redux/affiliation/types/affiliations.entity';

export const identifySocialNetwork = (url: string) => {
  if (url.includes('facebook.com')) return SocialsEnum['FB'];
  if (url.includes('instagram.com')) return SocialsEnum['INSTA'];
  if (url.includes('tiktok.com')) return SocialsEnum['TIKTOK'];
  if (url.includes('twitter.com')) return SocialsEnum['X'];
  if (url.includes('youtube.com')) return SocialsEnum['YT'];
  return 'OTHER';
};
