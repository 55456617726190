import { useState } from 'react';

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { ProfileArea } from 'utils/ProfileInformation';

import { QuestionsMapping } from './MapSchema';

interface QuestionRenderProps {
  element: ProfileArea;
  originalAnswers: { [key: string]: string | undefined };
  onChange: (
    areaQuestion: string,
    questionLabel: string,
    answerValue: string
  ) => void;
}
interface ISelectedAnswers {
  [key: string]: string;
}

export const QuestionsRenderComponent = ({
  element,
  originalAnswers,
  onChange
}: QuestionRenderProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<ISelectedAnswers>(
    element.questions.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.label]:
          originalAnswers[QuestionsMapping[curr.label]] || curr.initialValue
      }),
      {}
    )
  );

  const handleSelectChange =
    (questionLabel: string) => (event: SelectChangeEvent<string>) => {
      const newValue = event.target.value;
      setSelectedAnswers((prev) => ({ ...prev, [questionLabel]: newValue }));
      onChange(element.title, questionLabel, newValue);
    };

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Typography
          variant="h1"
          fontSize={'1.2rem'}
          fontFamily={'"Nunito"'}
          fontWeight={600}
          lineHeight={'1.75rem'}
          padding={'1rem 0'}
          sx={{ color: Colors.secondary.dark }}>
          {element.title}
        </Typography>
        {element.questions.map((question, questionIndex) => (
          <Box key={questionIndex} sx={{ marginTop: '0.25rem' }}>
            <Typography
              variant="h3"
              fontSize={'0.9rem'}
              fontFamily={'"Nunito"'}
              fontWeight={500}
              sx={{ color: Colors.secondary.dark }}>
              {question.question}
            </Typography>
            <FormControl fullWidth style={{ margin: '0.75rem 0' }}>
              <InputLabel id={`select-label-${questionIndex}`}>
                {question.label}
              </InputLabel>
              <Select
                labelId={`select-label-${questionIndex}`}
                id={`select-${questionIndex}`}
                value={selectedAnswers[question.label]}
                onChange={handleSelectChange(question.label)}
                displayEmpty
                inputProps={{ 'aria-label': question.label }}
                label={question.label}>
                {question.answers.map((answer, answerIndex) => (
                  <MenuItem key={answerIndex} value={answer.answer}>
                    {answer.answer}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default QuestionsRenderComponent;
