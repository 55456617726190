import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import { Box, Divider } from '@mui/material';

import { Colors } from 'design/theme';

interface PDFViewerProps {
  pdfURL: string;
}

interface DocumentLoadSuccess {
  numPages: number;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewerMobile: React.FC<PDFViewerProps> = ({ pdfURL }) => {
  const isLargeScreen = useIsLargeScreen();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const file = useMemo(() => ({ url: pdfURL }), [pdfURL]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => {
    setNumPages(numPages);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        boxShadow:
          '0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: Colors.white,
        p: 2,
        '& canvas': {
          maxWidth: '100%',
          width: isLargeScreen ? '80vw' : '100vw', // Use 80% of view width on large screens, 100% on smaller screens
          height: 'auto',
          borderRadius: 2
        },
        '& .page-container': {
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 2
        },
        '& .page-container:last-child': {
          marginBottom: 0
        }
      }}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}>
        {Array.from(new Array(numPages || 0), (el, index) => (
          <React.Fragment key={`fragment_${index + 1}`}>
            <Box className="page-container">
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={windowWidth * (isLargeScreen ? 0.8 : 0.95)} // Adjust page width based on the screen size
              />
            </Box>
            {index + 1 !== numPages && <Divider />}
          </React.Fragment>
        ))}
      </Document>
    </Box>
  );
};

export default PDFViewerMobile;
