import * as Yup from 'yup';

import { NewMeditationFormik } from 'redux/meditations/types/meditation.entity';

export const newMeditationFormValidation =
  Yup.object<NewMeditationFormik>().shape({
    outcome: Yup.string()
      .min(30, 'Tell us more')
      .required('Please, enter a Outcome'),
    quality: Yup.string()
      .min(3, 'Minimum is 3 characters')
      .required('Please, enter a Quality'),
    goal: Yup.string()
      .min(4, 'Minimum is 4 characters')
      .required('Please, enter a Goal'),
    attibute: Yup.string()
      .min(10, 'Minimun is 10 characters')
      .required('Please, enter an Attribute')
  });
