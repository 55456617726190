import { createAsyncThunk } from '@reduxjs/toolkit';
import affiliationsService from 'services/affiliations';

import { CreateAffiliationDto, PdfData } from './types/affiliations.entity';

export const requestAffiliationProcess = createAsyncThunk(
  'affiliations/requestAffiliation',
  async (createAffiliationDto: CreateAffiliationDto) => {
    const response = await affiliationsService.requestAffiliation(
      createAffiliationDto
    );
    return response.data.data;
  }
);
export const getAffiliationStatus = createAsyncThunk(
  'affiliations/affiliationStatus',
  async () => {
    const response = await affiliationsService.affiliationStatus();
    return response.data.data;
  }
);

export const uploadPDF = createAsyncThunk(
  'affiliations/uploadPDF',
  async ({ file, credentialCode }: { file: File; credentialCode: string }) => {
    const response = await affiliationsService.uploadPDF(file, credentialCode);
    return response.data.data;
  }
);

export const getCredentialsStatus = createAsyncThunk(
  'affiliations/getCredentialsStatus',
  async () => {
    const response = await affiliationsService.getCredentialsStatus();
    return response.data.data;
  }
);

export const signAgreementsStatus = createAsyncThunk(
  'affiliations/signAgreementsStatus',
  async (pdfData: PdfData) => {
    const response = await affiliationsService.signAgreementsStatus(pdfData);
    return response.data.data;
  }
);
