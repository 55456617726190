import { Context, useContext } from 'react';

import { TabsUIContextProps } from '../components/TabsContext';

const useTabsContext = (
  context: Context<TabsUIContextProps | null>
): NonNullable<TabsUIContextProps> => {
  const value = useContext(context);

  if (!value)
    throw new Error('Can not use TabsUIContext outside context provider');

  return value;
};

export default useTabsContext;
