import { ReactComponent as Calendar } from 'assets/images/Calendar.svg';
import { ReactComponent as Target } from 'assets/images/Target.svg';
import dayjs from 'dayjs';
import { useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';

import { Colors } from 'design/theme';

import { AudioModal } from 'modules/new-affirmation/components/MobileElements/AudioModal';

import { truncateTitle } from 'utils/tittleTruncate';

interface IAffirmationHeaderProps {
  title?: string;
  developmentArea?: string;
  createdOn: Date;
  pdfUrl?: string;
}

const AffirmationHeaderMobile = ({
  title,
  developmentArea,
  createdOn,
  pdfUrl
}: IAffirmationHeaderProps) => {
  const [audioModal, setAudioModal] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box borderRadius=".5rem" bgcolor={Colors.white}>
        <Container>
          <Box
            padding="1.5rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            gap="1rem">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-around',
                alignContent: 'center'
              }}>
              <Typography
                fontSize="1.25rem"
                fontWeight={500}
                sx={{ width: '90%' }}>
                {title && truncateTitle(title, 50)}
              </Typography>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  maxHeight: '2rem',
                  minWidth: '2.5rem',
                  border: `1px solid ${Colors.secondary.main}`,
                  borderRadius: '0.5rem'
                }}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-haspopup="true"
                  onClick={handleClick}>
                  <MoreHorizIcon sx={{ color: Colors.secondary.main }} />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      setAudioModal(true);
                    }}>
                    <Typography
                      fontFamily='"Nunito"'
                      fontSize={'1rem'}
                      letterSpacing={'0.025rem'}
                      sx={{
                        padding: '0.3rem',
                        color: Colors.secondary.dark
                      }}>
                      Generate audio
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    sx={{
                      minWidth: '10rem'
                    }}>
                    <Box>
                      {!!pdfUrl && (
                        <a href={pdfUrl} download>
                          <IconButton component="span">
                            <Typography
                              fontFamily='"Nunito"'
                              fontSize={'1rem'}
                              letterSpacing={'0.025rem'}
                              sx={{ color: Colors.secondary.dark }}>
                              Export as pdf
                            </Typography>
                          </IconButton>
                        </a>
                      )}
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Box
                display="flex"
                mt=".75rem"
                alignItems="center"
                columnGap=".5rem">
                <Calendar />
                <Typography
                  fontWeight={500}
                  color="#576361"
                  alignSelf="center"
                  mr=".5rem"
                  lineHeight="normal">
                  {dayjs(createdOn).format('MMM DD')}
                </Typography>

                <Target />
                <Typography
                  fontWeight={500}
                  color="#576361"
                  alignSelf="center"
                  lineHeight="normal">
                  {developmentArea}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <AudioModal
        openAudioModal={audioModal}
        setOpenAudioModal={setAudioModal}
      />
    </>
  );
};
export default AffirmationHeaderMobile;
