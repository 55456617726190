import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { WaitingListModal } from 'components/WaitingListModal';

import { Colors, evo2FontFamily } from 'design/theme';

import RoutesEnum from 'types/routes.enum';

import TextAnimationContainer from './TextAnimationContainer';

const StyledDiv = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  gap: '2rem',
  width: '100%',
  padding: '0 0 4rem 4rem '
};
const HeaderContent = () => {
  const navigate = useNavigate();
  const barsRef = useRef<HTMLImageElement>(null);
  const [barsHeight, setBarsHeight] = React.useState(0);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setBarsHeight(entry.target.clientHeight);
      }
    });

    if (barsRef.current) {
      resizeObserver.observe(barsRef.current);
    }

    return () => {
      if (barsRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(barsRef.current);
      }
    };
  }, []);

  return (
    <>
      <Container
        sx={{
          padding: 0,
          margin: 0,
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flexStart',
          justifyContent: 'flex-start',
          paddingTop: '6rem',
          height: '100vh',
          minHeight: '30rem',
          gap: '3rem',
          position: 'relative',
          '@media (min-width: 1214px)': {
            justifyContent: 'flex-end'
          }
        }}>
        <div style={StyledDiv}>
          <Typography
            color={Colors.white}
            fontFamily={evo2FontFamily}
            fontSize={'1.6rem'}
            fontWeight={200}>
            Tap into the potential of your subconscious mind <br /> with iAffirm
          </Typography>
          <Box maxWidth="40.25rem">
            <Typography
              color={Colors.white}
              lineHeight={0.9}
              sx={{ textShadow: '1px 3px 3px rgba(0, 0, 0, 0.33)' }}
              fontFamily={evo2FontFamily}
              fontSize={'5.5rem'}
              fontWeight={700}>
              Awaken Your Inner You
            </Typography>
          </Box>
          <Grid
            container
            spacing={10}
            sx={{
              justifyContent: 'space-between',
              width: '90vw'
            }}>
            <Grid item xl={6}>
              <Typography
                color={Colors.white}
                textAlign="left"
                fontFamily={evo2FontFamily}
                fontSize={'1.6rem'}
                fontWeight={200}
                maxWidth="42.25rem">
                Let AI curate personalised affirmations, visualisations,
                <br /> and guided meditations just for you.
              </Typography>
            </Grid>
            <Grid
              item
              xl={6}
              sx={{
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                display: 'flex'
              }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  navigate(RoutesEnum.LOGIN);
                }}
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: '300',
                  backgroundColor: Colors.bgGreen,
                  maxWidth: '20rem'
                }}>
                Unlock your free, morning affirmation boost
              </Button>
              <WaitingListModal open={isModalOpen} handleClose={toggleModal} />
            </Grid>
          </Grid>
        </div>
      </Container>

      <TextAnimationContainer barsHeight={barsHeight} />
    </>
  );
};
export default HeaderContent;
