enum Roles {
  UNKNOW = 'unknow',
  FREE = 'free',
  SUBSCRIPTOR = 'subscriptor',
  INVITEE = 'INVITEE'
}

export enum UserSubscription {
  FREEMIUM = 'FREEMIUM',
  NORMAL = 'NORMAL',
  PREMIUM = 'PREMIUM',
  INVITEE = 'INVITEE'
}

export default Roles;
