import Arnold from 'assets/images/home/ThePowerOfAffirmations/Arnold.png';
import Cameron from 'assets/images/home/ThePowerOfAffirmations/Cameron.png';
import Hugh from 'assets/images/home/ThePowerOfAffirmations/Hugh.png';
import Jennifer from 'assets/images/home/ThePowerOfAffirmations/Jennifer.png';
import Steve from 'assets/images/home/ThePowerOfAffirmations/Steve.png';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, Typography } from '@mui/material';

import Carrousel, { ContentType } from 'components/Carrousel';

import { Colors, evo2FontFamily } from 'design/theme';

const content: ContentType[] = [
  {
    title: 'Hugh Jackman:',
    description:
      "Hugh Jackman, an Australian actor and producer, rose to international fame for his portrayal of Wolverine in the X-Men film series.He's won numerous awards, including a Golden Globe and a Tony Award, show casing his versatility on stage and screen. Jackman's talent, charisma, and dedication have solidified his status as a Hollywood icon.",
    image: Hugh,
    phrase:
      'I meditated before I hosted the Oscars, I meditate before I go onstage, I meditate in the morning, at lunchtime, when Im on a film set. I became a different person once I began to meditate. Completely different'
  },
  {
    title: 'Jennifer Aniston:',
    description:
      "Jennifer Aniston, an American actress and producer, gained global recognition for her role as Rachel Green on the sitcom Friends winning a Primetime Emmy Award and a Golden Globe. She's starred in successful films, earned critical acclaim, and received additional accolades for her contributions to entertainment and philanthropy. Aniston remains a beloved cultural figure",
    image: Jennifer,
    phrase:
      '[Meditation] is like a kickstart for your day, it centres you, your stress levels go down, and you find yourself interacting in the world in a much easier and calmer way.'
  },
  {
    title: 'Steve Jobs:',
    description:
      "Steve Jobs, co-founder of Apple Inc., revolutionized the technology industry with groundbreaking innovations like the iPhone, iPad, and Macintosh computer. His visionary leadership reshaped consumer electronics, leading to unprecedented success for Apple. Jobs' legacy extends beyond technology, inspiring creativity and entrepreneurship worldwide, cementing his status as a cultural icon.",
    image: Steve,
    phrase:
      'If you just sit and observe, you will see how restless your mind is. If you try to calm it, it only makes it worse, but over time it does calm, and when it does, there s room to hear more subtle things—that s when your intuition starts to blossom and you start to see things more clearly. Your mind just slows down, and you see a tremendous expanse in the moment. You see so much more than you could see before.'
  },
  {
    title: 'Cameron Diaz:',
    description:
      "Cameron Diaz, an American actress, producer, and author, gained fame with iconic roles in films like There's Something About Mary and Charlie's Angels. She's won multiple awards, including a Golden Globe nomination. Diaz's versatile talent, combined with her entrepreneurial ventures and philanthropic efforts, solidifies her impact on Hollywood and beyond.",
    image: Cameron,
    phrase:
      'Its so simple to learn, so simple to practice. And the restoration that comes to you, the benefit across your life its changed everything.'
  },
  {
    title: 'Arnold Schwarzenegger:',
    description:
      'Arnold Schwarzenegger, an Austrian-American actor, bodybuilder, and politician, became a global icon through his bodybuilding career, winning numerous titles including Mr. Olympia. Transitioning to Hollywood, he starred in blockbuster films like Terminator and Predator. Later, Schwarzenegger served as Governor of California, leaving a lasting impact on politics and entertainment.',
    image: Arnold,
    phrase:
      'The mind always fails first, not the body. The secret is to make your mind work for you, not against you'
  }
];

const ThePowerOfAffirmations = () => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box position="relative">
      <Box pt="15.0625rem" />
      <Container
        sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Typography
          textAlign="center"
          fontFamily={evo2FontFamily}
          fontStyle="italic"
          fontWeight={500}
          color={Colors.secondary.main}
          mb="1.25rem">
          Inspiring success stories
        </Typography>
        <Typography
          textAlign="center"
          fontFamily={evo2FontFamily}
          fontWeight={700}
          color={Colors.bgGreen}
          fontSize={isLargeScreen ? '2.625rem' : '2rem'}
          mb="1.625rem">
          The Power of Affirmations
        </Typography>
        <Typography
          textAlign="center"
          fontFamily={evo2FontFamily}
          fontWeight={500}
          color={Colors.green.dark}
          fontSize={isLargeScreen ? '1.25rem' : '0.9rem'}
          sx={{
            opacity: '0.8',
            maxWidth: isLargeScreen ? '55%' : 'undefined',
            lineHeight: '1.4'
          }}
          pb="1rem">
          Positive inner dialogue through affirmations and visualization, have
          played a significant role in the success stories of many famous
          individuals, including Hugh Jackman, Jennifer Aniston, Steve Jobs,
          Cameron Diaz, and Arnold Schwarzenegger.
        </Typography>
        <Typography
          textAlign="center"
          fontFamily={evo2FontFamily}
          fontWeight={500}
          color={Colors.green.dark}
          fontSize={isLargeScreen ? '1.25rem' : '0.9rem'}
          sx={{
            opacity: '0.8',
            maxWidth: isLargeScreen ? '55%' : 'undefined',
            lineHeight: '1.4'
          }}
          pb="4.875rem">
          While we might not know all the details of their daily routines, we
          can understand the powerful effects of affirmations by looking at the
          impressive achievements they&apos;ve made in their fields.
        </Typography>

        <Box
          zIndex={20}
          position="relative"
          sx={{
            width: { xs: '100%', sm: '100%', margin: '0' }
          }}>
          <Carrousel content={content} />
        </Box>
      </Container>
    </Box>
  );
};
export default ThePowerOfAffirmations;
