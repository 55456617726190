import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AdminAffirmation,
  AdminAffirmationsSelected,
  Top5AffirmationsDto
} from 'services/affirmation-templates';

import {
  fetchAffirmationsInTemplate,
  fetchPublishedAffirmations,
  getPublishedAffirmationById,
  getTrendingAffirmations
} from './templates.action';
import { ITemplateState } from './types/template.entity';

const initialState: ITemplateState = {
  template: [],
  selectedAffirmations: [],
  loading: false,
  error: null
};

const templateSlice = createSlice({
  name: 'affirmations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublishedAffirmations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchPublishedAffirmations.fulfilled,
        (state, action: PayloadAction<AdminAffirmation[]>) => {
          state.template = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchPublishedAffirmations.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch published affirmations';
      })
      .addCase(fetchAffirmationsInTemplate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAffirmationsInTemplate.fulfilled,
        (state, action: PayloadAction<AdminAffirmationsSelected[]>) => {
          state.selectedAffirmations = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchAffirmationsInTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch affirmations in template';
      })
      .addCase(getTrendingAffirmations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getTrendingAffirmations.fulfilled,
        (state, action: PayloadAction<Top5AffirmationsDto>) => {
          state.trendingAffirmations = action.payload;
          state.loading = false;
        }
      )
      .addCase(getTrendingAffirmations.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch trending affirmations';
      })
      .addCase(getPublishedAffirmationById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getPublishedAffirmationById.fulfilled,
        (state, action: PayloadAction<AdminAffirmation>) => {
          state.selectedTemplateTrending = action.payload;
          state.loading = false;
        }
      )
      .addCase(getPublishedAffirmationById.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || 'Failed to fetch published affirmation by ID';
      });
  }
});

export default templateSlice.reducer;
