import { ReactComponent as LeafFooter } from 'assets/images/home/HowDoesItWork/Bottom.svg';
import { ReactComponent as Discord } from 'assets/images/home/footer/discord.svg';
import { ReactComponent as Facebook } from 'assets/images/home/footer/facebook.svg';
import { ReactComponent as Instagram } from 'assets/images/home/footer/instagram.svg';
import { ReactComponent as Tiktok } from 'assets/images/home/footer/tiktok.svg';
import { ReactComponent as X } from 'assets/images/home/footer/x.svg';
import { ReactComponent as Youtube } from 'assets/images/home/footer/youtube.svg';
import { ReactComponent as LogoWhite } from 'assets/images/home/logo-white.svg';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { Box, Container, IconButton, Typography } from '@mui/material';

import { Colors, evo2FontFamily } from 'design/theme';

const Footer = () => {
  const isLargeScreen = useIsLargeScreen();

  return (
    <>
      {!isLargeScreen && (
        <Box sx={{ margin: '-12% auto', maxWidth: '10%' }}>
          <LeafFooter />
        </Box>
      )}
      <Box
        sx={{
          backgroundColor: Colors.bgGreen
        }}>
        <Container sx={{ padding: '4.875rem 0' }}>
          <Box
            maxHeight="6.5625rem"
            display="flex"
            sx={{ justifyContent: { xs: 'center', sm: 'space-between' } }}
            alignItems="center">
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              <LogoWhite />
            </Box>
            <Box>
              <Typography
                color={Colors.secondary.light}
                fontFamily={evo2FontFamily}
                fontWeight={500}
                sx={{ textAlign: { xs: 'center', sm: 'start' } }}>
                {"Let's keep the conversation"}
              </Typography>
              <Box display="flex" pl={0} mt="1.5rem" gap="0">
                <a
                  href="https://www.instagram.com/iaffirm_ai/?next=%2Fiaffirm_ai%2F"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <Instagram />
                  </IconButton>
                </a>
                <a
                  href="https://www.tiktok.com/@iaffirm.ai"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <Tiktok />
                  </IconButton>
                </a>
                <a
                  href="https://discord.com/channels/1194349122732437525/1194370137504096377"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <Discord />
                  </IconButton>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCnh0Q2h1aWrWsbx-RSg-ABQ"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <Youtube />
                  </IconButton>
                </a>
                <a
                  href="https://twitter.com/iAffirm_ai"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <X />
                  </IconButton>
                </a>
                <a
                  href="https://www.facebook.com/people/iAffirm/61557370271155/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <IconButton>
                    <Facebook />
                  </IconButton>
                </a>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: 'black',
          padding: '2rem 0'
        }}>
        {/* Temporal date subject to the terms and conditions */}
        {/* <Container sx={{ display: 'flex', gap: '2rem' }}>
          <Typography
            color="whitesmoke"
            fontFamily={evo2FontFamily}
            fontSize="0.875rem"
            fontWeight={500}>
            {dayjs().format('YYYY')} iAffirm
          </Typography>
          <Typography
            color="whitesmoke"
            fontFamily={evo2FontFamily}
            fontSize="0.875rem"
            fontWeight={500}
            sx={{ textDecoration: 'underline' }}>
            <Link sx={{ color: 'white', cursor: 'pointer' }}>
              Terms of Service
            </Link>
          </Typography>
          <Typography
            color="whitesmoke"
            fontFamily={evo2FontFamily}
            fontSize="0.875rem"
            fontWeight={500}
            sx={{ textDecoration: 'underline' }}>
            <Link sx={{ color: 'white', cursor: 'pointer' }}>
              Privacy Policy
            </Link>
          </Typography>
        </Container> */}
      </Box>
    </>
  );
};
export default Footer;
