import { AreaSelected } from 'modules/new-affirmation/types/initialState';

export interface CreatedVisualization {
  id: string;
  outcome?: string;
  quality?: string;
  emotion?: string;
  situation?: string;
}

export class CreateVisualization {
  outcome?: OutcomeBrief;
  quality?: QualityBrief;
  emotion?: EmotionsBrief;
  situation?: SituationsBrief;
}

export interface UserVisualization {
  contextTitle: string;
  visualizationID: string;
  visualizationURL: string;
}
export type Outcome = {
  id?: number;
  outcome: string;
  recommendation?: boolean;
  qualities?: Quality[];
};

export type Quality = {
  id?: number;
  quality: string;
  emotion: Emotion;
  outcome: Outcome;
};

export type Emotion = {
  id?: number;
  emotion: string;
  situation: Situation[];
  quality: Quality;
};

export type Situation = {
  id?: number;
  situation: string;
  emotion: Emotion;
};

export interface VisualizationState {
  createdVisualization: CreatedVisualization | null;
  userVisualization: CreateVisualizationPDF;
  visualizationOutomes?: Outcome[];
  visualizationQualities?: QualityBrief[];
  visualizationSituations?: SituationsBrief[];
  loading: boolean;
  speech?: string;
  speechLoading: boolean;
  isSpeechProcessing: boolean;
  newVisualization: newAVisualizationUIState;
}
export const customOutcomes: CustomOutcomeMapVisualization = {
  Interpersonal: {
    id: -1,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Personal: {
    id: -2,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  },
  Professional: {
    id: -3,
    outcome: 'Set your own Outcome',
    recommendation: false,
    qualities: []
  }
};
export interface CustomOutcomeMapVisualization {
  [key: string]: {
    id: number;
    outcome: string;
    recommendation: boolean;
    qualities: Quality[];
  };
}

export type newAVisualizationUIState = {
  actualStep: number;
  lastStep: number;
  home: boolean;
  customFlow: boolean;
  selectedOutcome?: Outcome;
  selectedQuality?: QualityBrief;
  selectedEmotion?: EmotionsBrief;
  selectedSituation?: SituationsBrief;
  areaSelected?: AreaSelected;
  customVisualization?: CustomVisualizationFlow;
};

export type CustomVisualizationFlow = {
  outcome: string;
  quality: string;
  emotion: string;
  situation: string;
};
export type OutcomeBrief = {
  id?: number;
  outcome: string;
  quality?: QualityBrief[];
};
export interface QualityBrief {
  id?: number;
  quality: string;
  emotion?: EmotionsBrief[];
}

export interface EmotionsBrief {
  id?: number;
  emotion: string;
}

export interface SituationsBrief {
  id?: number;
  situation: string;
}

export interface EditMeditation {
  contextTitle: string;
  message: string;
}

export interface UserVisualizationCreated {
  visualizationId: string;
  contextTitle: string;
  visualizationURL: string;
}

export interface CreateVisualizationPDF {
  editMeditation?: EditMeditation;
  userVisualization?: UserVisualizationCreated;
}
