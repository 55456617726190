import { createAsyncThunk } from '@reduxjs/toolkit';
import visualizationService from 'services/visualization';

import { SpeechParameters } from 'redux/affirmation/types/affirmation.entity';

import {
  GetVisualization,
  getPDFVisualization
} from './types/getVisualization.interface';
import {
  CreateVisualization,
  CreatedVisualization,
  EditMeditation,
  Outcome,
  QualityBrief,
  SituationsBrief,
  UserVisualization
} from './types/visualizations';

export const createVisualization = createAsyncThunk<
  CreatedVisualization,
  CreateVisualization
>('visualization/', async (data: CreateVisualization) => {
  return (await visualizationService.create(data)).data.data;
});

export const generateVisualization = createAsyncThunk<
  EditMeditation,
  GetVisualization
>('visualization/generate-visualization', async (data: GetVisualization) => {
  return (await visualizationService.generate(data)).data.data;
});

export const generatePDFVisualization = createAsyncThunk(
  'visualization/generate-visualization-pdf',
  async (data: getPDFVisualization) => {
    return (await visualizationService.generatePDF(data)).data.data;
  }
);

export const speechVisualization = createAsyncThunk<
  UserVisualization,
  SpeechParameters
>(
  'visualization/speech-visualization',
  async (speechParameters: SpeechParameters) => {
    return (await visualizationService.speech(speechParameters)).data.data;
  }
);

export const getOutcomesVisualization = createAsyncThunk<Outcome[]>(
  'visualization/get-outcomes',
  async () => {
    return (await visualizationService.getOutcomes()).data.data;
  }
);

export const getOutcomeQualitiesVisualization = createAsyncThunk<
  QualityBrief[],
  number
>('visualization/get-outcome-qualities', async (id: number) => {
  return (await visualizationService.getOutcomeQualities(id)).data.data;
});

export const getEmotionSituationsVisualization = createAsyncThunk<
  SituationsBrief[],
  number
>('visualization/get-emotion-situations', async (id: number) => {
  return (await visualizationService.getEmotionSituations(id)).data.data;
});

export const deleteVisualization = createAsyncThunk(
  'visualization/delete',
  async (id: string) => {
    return await visualizationService.deleteVisualization(id);
  }
);
