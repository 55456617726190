import dayjs from 'dayjs';
import useIsLargeScreen from 'hooks/useIsLargeScreen';

import { CalendarToday } from '@mui/icons-material';
import { Box, Container, IconButton, Link, Typography } from '@mui/material';

import { Colors } from 'design/theme';

import { areaEmojis } from 'modules/new-affirmation/components/Steps/2_improvementArea';

import { truncateTitle } from 'utils/tittleTruncate';

import { ReactComponent as Download } from '../assets/images/Download.svg';
import { ReactComponent as Target } from '../assets/images/Target.svg';

interface IAffirmationHeaderProps {
  title?: string;
  developmentArea?: string;
  createdOn: Date;
  pdfUrl?: string;
}

const AffirmationHeader = ({
  title,
  developmentArea,
  createdOn,
  pdfUrl
}: IAffirmationHeaderProps) => {
  const isLargeScreen = useIsLargeScreen();
  return (
    <Box borderRadius=".5rem" bgcolor={Colors.white}>
      <Container>
        <Box
          padding="1.5rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap="1rem">
          <Box
            sx={{
              display: isLargeScreen ? undefined : 'flex',
              flexDirection: isLargeScreen ? undefined : 'column'
            }}>
            <Typography fontSize="1.25rem" fontWeight={500}>
              {title && truncateTitle(title, 80)}
            </Typography>
            <Box
              display="flex"
              mt=".75rem"
              alignItems="center"
              columnGap=".5rem">
              <CalendarToday fontSize="small" color="secondary" />
              <Typography
                fontWeight={500}
                color="#576361"
                alignSelf="center"
                mr=".5rem"
                lineHeight="normal">
                {dayjs(createdOn).format('MMM DD')}
              </Typography>
              {developmentArea &&
              areaEmojis[developmentArea as keyof typeof areaEmojis] ? (
                <Box
                  display="flex"
                  alignItems="center"
                  ml="1.5rem"
                  columnGap=".5rem">
                  <Typography fontSize={'1.25rem'} lineHeight="normal">
                    {`${
                      areaEmojis[developmentArea as keyof typeof areaEmojis]
                    }`}
                  </Typography>
                  <Typography
                    fontWeight={500}
                    color="#576361"
                    alignSelf="center"
                    lineHeight="normal">
                    {developmentArea}
                  </Typography>
                </Box>
              ) : (
                <>
                  <Target />
                  <Typography
                    fontWeight={500}
                    color="#576361"
                    alignSelf="center"
                    lineHeight="normal">
                    {developmentArea}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ margin: { xs: 'auto', md: 0 } }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              border={`1px solid ${Colors.secondary.main}`}
              borderRadius={'0.25rem'}>
              {!!pdfUrl && (
                <Link href={pdfUrl}>
                  <IconButton color="secondary">
                    <Download />
                  </IconButton>
                </Link>
              )}
              <Box></Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
export default AffirmationHeader;
